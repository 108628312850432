import { useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import axios from "axios";
import toast from "react-hot-toast";

import { Card, Tab, TabsBar } from "components";
import {
  SymbolDetail,
  CreditWithdraw,
  ConfirmCancelDepositWithdraw,
  InventoryTable,
  InventoryHistoryTable,
  IInventoryHandles,
  IInventoryHistoryHandles,
} from "features/AccountManagement";
import { CreditTransactionWithdraw, Transaction } from "models/transaction";
import { usePin } from "providers";
import {
  cancelTransaction,
  transactionRetire,
  transactionWithdraw,
} from "api/transaction";
import { apiErrorToast, captureErrorSentry, mediaQuery } from "utils";
import { APIError } from "models/generic";

const InventoryContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  ${mediaQuery("tablet")} {
    padding-bottom: 0;
  }
`;

const Content = styled(Card)`
  flex: 1;
  height: fit-content;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  ${mediaQuery("tablet")} {
    display: none;
  }
`;

const TabsStyle = styled(TabsBar)`
  li.is-active {
    background-color: ${(props) => props.theme.black3};
  }
`;

const TabHeader = styled.h2`
  color: inherit;
  padding: 0 16px;
`;

const Inventory = () => {
  const { showPin } = usePin();
  const [showSymbolDetail, setShowSymbolDetail] = useState("");
  const [showCancelRequest, setShowCancelRequest] =
    useState<Transaction | null>(null);
  const [showWithdrawModal, setShowWithdrawModal] = useState({
    symbol: "",
    retire: false,
  });
  const inventoryTableRef = useRef<IInventoryHandles>(null);
  const inventoryHistoryTableRef = useRef<IInventoryHistoryHandles>(null);

  const onClickWithdraw = (values: CreditTransactionWithdraw) => {
    const { retire } = showWithdrawModal;
    const displayText = !retire ? "withdrawal" : "retirement";
    showPin({
      show: true,
      async onConfirm(pin) {
        const toastId = toast.loading(
          `Processing ${!retire ? "withdraw" : "retire"}...`
        );
        let res;
        try {
          if (!retire) {
            res = await transactionWithdraw({ ...values, pin });
          } else {
            res = await transactionRetire({ ...values, pin });
          }
          if (res && res.data.success) {
            toast.success(`Created ${displayText} request.`, {
              id: toastId,
            });
            inventoryTableRef.current?.refresh();
          } else {
            throw new Error(`Failed to create ${displayText} request.`);
          }
        } catch (err) {
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error, toastId);
            captureErrorSentry(error, err, {
              message: `${displayText} error`,
            });
          } else {
            toast.error(`Failed to create ${displayText} request.`, {
              id: toastId,
            });
          }
        } finally {
          showPin({ show: false });
          setShowWithdrawModal({ symbol: "", retire: false });
        }
      },
    });
  };

  const cancelRequest = () => {
    showPin({
      show: true,
      onClose() {
        showPin({ show: false });
      },
      async onConfirm(pin) {
        const toastId = toast.loading("Cancelling request...");
        try {
          const res = await cancelTransaction({
            id: showCancelRequest?.id ?? 0,
            pin,
          });
          if (res.data.success) {
            toast.success("Cancelled request.", {
              id: toastId,
            });
            inventoryHistoryTableRef.current?.refresh();
          } else {
            throw new Error("Failed to cancel request.");
          }
        } catch (err) {
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error, toastId);
          } else {
            toast.error("Failed to cancel request.", {
              id: toastId,
            });
          }
        } finally {
          showPin({ show: false });
          setShowCancelRequest(null);
        }
      },
    });
  };
  return (
    <InventoryContainer>
      <SymbolDetail
        showSymbol={showSymbolDetail}
        onCancel={() => setShowSymbolDetail("")}
        showBlockNumber
      />
      <ConfirmCancelDepositWithdraw
        show={!!showCancelRequest}
        history={showCancelRequest ?? undefined}
        onClose={() => setShowCancelRequest(null)}
        onConfirm={cancelRequest}
      />
      <CreditWithdraw
        show={!!showWithdrawModal.symbol}
        symbol={showWithdrawModal.symbol}
        onCancel={() => setShowWithdrawModal({ symbol: "", retire: false })}
        onWithdrawClick={onClickWithdraw}
        disabledSymbolSelect
        retire={showWithdrawModal.retire}
      />
      <Content
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <Title>Inventory</Title>
        <TabsStyle mode="horizontal" loading={false}>
          <Tab key="inventory" label={<TabHeader>Inventory</TabHeader>}>
            <InventoryTable
              ref={inventoryTableRef}
              // setShowSymbolDetail={setShowSymbolDetail}
              setShowWithdrawModal={(symbol: string, retire: boolean) =>
                setShowWithdrawModal({ symbol, retire })
              }
            />
          </Tab>
          <Tab key="history" label={<TabHeader>History</TabHeader>}>
            <InventoryHistoryTable
              ref={inventoryHistoryTableRef}
              setShowCancelRequest={setShowCancelRequest}
            />
          </Tab>
        </TabsStyle>
      </Content>
    </InventoryContainer>
  );
};

export default Inventory;
