import { useCallback } from "react";
import styled from "@emotion/styled";
import { Button, Label, OutlineSelect, TextField } from "components";
import { useRegisterContext } from "providers/RegisterProvider";
import { CompanyInfoForm, companyInfoFormSchema } from "models/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import useSWRImmutable from "swr/immutable";

import { PaymentMethodRes } from "models/paymentMethod";
import {
  digitsOnlyChange,
  Form,
  RegisterComponentGrid,
  Title,
} from "../components";

const BankContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`;

export const CompanyInfo = () => {
  const {
    state: { page, companyInfo },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CompanyInfoForm>({
    resolver: yupResolver(companyInfoFormSchema),
    shouldUnregister: true,
    defaultValues: companyInfo,
  });

  const { data: paymentMethodRes } = useSWRImmutable<PaymentMethodRes>(
    "/payment-method/list"
  );

  const onSubmitCompanyInfo = useCallback(
    async (values: CompanyInfoForm) => {
      dispatch({
        type: "SET_COMPANY_INFO",
        payload: values,
      });
      dispatch({
        type: "SET_PAGE",
        payload: page + 1,
      });
    },
    [dispatch, page]
  );

  return (
    <RegisterComponentGrid>
      <Title>Register</Title>
      <Form
        id="companyInfo"
        onSubmit={handleSubmit(onSubmitCompanyInfo)}
        autoComplete="off"
      >
        <TextField
          label="Company Name (ชื่อบริษัท)"
          placeholder="e.g., Company Co., Ltd."
          type="text"
          errorWarn={!!errors.name}
          errorMessage={errors.name?.message}
          {...register("name")}
        />
        <TextField
          label="Juristic Number (เลขทะเบียนนิติบุคคล)"
          placeholder="e.g., 0123456789123"
          type="text"
          errorWarn={!!errors.juristic_number}
          errorMessage={errors.juristic_number?.message}
          {...register("juristic_number")}
        />
        <TextField
          label="Name of Contact Person (ชื่อผู้ประสานงาน)"
          placeholder="In case of an attorney (กรณีมอบอำนาจ)"
          type="text"
          errorWarn={!!errors.name_of_contact_person}
          errorMessage={errors.name_of_contact_person?.message}
          {...register("name_of_contact_person")}
        />
        <Label style={{ marginBottom: "8px" }}>
          Bank Account Number (เลขบัญชีธนาคาร)
        </Label>
        <BankContainer>
          <OutlineSelect
            required
            errorWarn={!!errors.bank_code}
            errorMessage={errors.bank_code?.message}
            style={{ minWidth: "120px", width: "120px", color: "black" }}
            selectStyle={{ height: "42px", border: "none" }}
            {...register("bank_code")}
          >
            <option value="" disabled>
              BANK
            </option>
            {paymentMethodRes?.data?.payment_methods.map((bank) => (
              <option key={bank.code} value={bank.code}>
                {bank.code}
              </option>
            ))}
          </OutlineSelect>
          <Controller
            control={control}
            name="bank_account_number"
            render={({ field: { onChange, ...rest } }) => (
              <TextField
                required
                placeholder="Account Number"
                errorWarn={!!errors.bank_account_number}
                errorMessage={errors.bank_account_number?.message}
                {...rest}
                onChange={(e) => digitsOnlyChange(e, onChange)}
              />
            )}
          />
        </BankContainer>
      </Form>
      <Button block form="companyInfo" type="submit">
        {/* {loading ? (
                    <Spinner
                      style={{ display: "block" }}
                      animation="border"
                      variant="light"
                    />
                  ) : ( */}
        Next
        {/* )} */}
      </Button>
    </RegisterComponentGrid>
  );
};
