import {
  useEffect,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { isEmpty } from "lodash-es";

import { signUp } from "api/users";
import { APIError } from "models/generic";
import { ConfirmModal } from "components";
import * as errorCodes from "models/apiErrorCodes";
import {
  apiErrorToast,
  formatMobileNumberPrefix,
  storageAvailable,
} from "utils";
import * as types from "./types";
import * as data from "./state";

export interface RegisterContextType {
  state: types.State;
  dispatch: (value: types.Actions) => void;
  signUpUser: (state: types.State, recaptchaToken: string) => Promise<void>;
}

const checkEmptyObject = (obj: Record<string, string | null | FileList>) =>
  Object.entries(obj).some(([key, value]) => {
    if (isEmpty(value)) return true;
    return false;
  });

const pageCheckForCompany = (state: types.State, action: types.SetPage) => {
  const pageCount = state.pages.length;

  if (action.payload >= pageCount) {
    return pageCount - 1;
  }
  return action.payload >= 0 ? action.payload : 0;
};

const goLatest = (state: types.State, action: types.SetPage) => {
  // page 1
  if (!state.tnc) {
    return 0;
  }
  // page 2
  if (checkEmptyObject(state.profile)) {
    // todo check for all info
    return Math.min(action.payload, 1);
  }
  if (!state.pin) {
    return Math.min(action.payload, 1);
  }
  return pageCheckForCompany(state, action);
};

const reducer = (state: types.State, action: types.Actions): types.State => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        error: null, // reset error
        isLoading: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case "SET_PAGE":
      if (state.isDirty) {
        return {
          ...state,
          showConfirm: true,
          clickedPage: action.payload,
        };
      }
      return {
        ...state,
        page: goLatest(state, action),
        furthestPage: goLatest(state, {
          type: "SET_PAGE",
          payload: state.pages.length - 1,
        }),
      };
    case "SET_TNC":
      return { ...state, tnc: action.payload };
    case "SET_PROFILE":
      return { ...state, profile: action.payload };
    case "SET_BROKER_ID":
      // todo : if broker_id changes,check if reset all kyc pages is necessary
      return { ...state, brokerId: action.payload };
    case "SET_PIN":
      return { ...state, pin: action.payload };
    case "SET_COMPANY_INFO":
      return { ...state, companyInfo: action.payload };
    case "SET_ATTACHMENTS":
      return { ...state, attachments: action.payload, pages: state.pages };

    case "SET_STATE_FROM_LOCAL_STORAGE":
      return { ...state, ...action.payload };
    case "SET_REGISTERED":
      return { ...state, registered: action.payload };
    case "SET_DIRTY":
      return { ...state, isDirty: action.payload };
    case "SET_SHOW_CONFIRM":
      return { ...state, showConfirm: action.payload };
    default:
      return state;
  }
};

export const RegisterContext = createContext<RegisterContextType>({
  state: data.initState,
  dispatch: () => {},
  signUpUser: () => Promise.resolve(),
});

export const useRegisterContext = () => useContext(RegisterContext);

export const RegisterProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, data.initState);
  const [showConfirm, setShowConfirm] = useState<types.State | undefined>();

  // on state change, store in local storage for persistence, but only if not loading and no error
  // also don't store if page is 0, because that means user has not accepted tnc
  useEffect(() => {
    if (!storageAvailable("localStorage")) {
      return;
    }
    if (state.registered) {
      localStorage.removeItem("registerState");
    } else if (!state.isLoading && !state.error && state.page !== 0) {
      // new object without profile.password and pin
      const storeObj = {
        ...state,
        isLoading: false,
        isDirty: false,
        showConfirm: false,
        error: null,
        profile: {
          ...state.profile,
          password: "",
          confirm_password: "",
        },
        pin: "",
      };
      localStorage.setItem("registerState", JSON.stringify(storeObj));
    }
  }, [state]);

  // on mount, load from local storage
  // useLayoutEffect so that it runs after all DOM mutations, after page is rendered
  useLayoutEffect(() => {
    if (!storageAvailable("localStorage")) {
      return;
    }
    const registerState = localStorage.getItem("registerState");
    if (registerState) {
      dispatch({ type: "LOADING", payload: true });
      const parsedState = JSON.parse(registerState);
      // check if state is valid
      if (parsedState) {
        setShowConfirm({
          ...parsedState,
          page: 1, // set page to 1(Profile) to force user to input password and verify email again
        });
      } else {
        dispatch({ type: "LOADING", payload: false });
      }
    }
  }, []);

  const signUpUser = useCallback(
    // use state + new value when calling this function
    async (state: types.State, recaptchaToken: string) => {
      try {
        const { profile, companyInfo, brokerId } = state;

        await signUp({
          ...profile,
          // e-kyc
          account_ownership: "COMPANY",
          name: companyInfo.name,
          juristic_number: companyInfo.juristic_number,
          name_of_contact_person: companyInfo.name_of_contact_person,
          // bank account
          bank_code: companyInfo.bank_code,
          bank_branch: "-",
          bank_account_number: companyInfo.bank_account_number,
          bank_account_type: "SAVING",
          bank_account_name: companyInfo.name,

          mobile_number: formatMobileNumberPrefix(profile.mobile_number),

          files: [...state.attachments],
          pin: state.pin,
          recaptcha_token: recaptchaToken,
          broker_id: brokerId,
        });
        dispatch({ type: "SET_REGISTERED", payload: true });
        // clear local storage
        localStorage.removeItem("registerState");
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          dispatch({ type: "ERROR", payload: error });
          apiErrorToast(error);
        } else {
          dispatch({
            type: "ERROR",
            payload: {
              code: errorCodes.UnknownError,
              message: "Unknown error",
              data: undefined,
            },
          });
        }
      }
    },
    []
  );

  const memoedValue = useMemo(
    () => ({
      state,
      dispatch,
      signUpUser,
    }),
    [state, signUpUser]
  );

  return (
    <RegisterContext.Provider value={memoedValue}>
      <ConfirmModal
        show={!!showConfirm}
        title="Continue registration"
        desc="You have an incomplete registration. Do you want to continue?"
        onConfirm={() => {
          dispatch({
            type: "SET_STATE_FROM_LOCAL_STORAGE",
            payload: showConfirm as types.State,
          });
          setShowConfirm(undefined);
        }}
        onClose={() => {
          setShowConfirm(undefined); // close modal
          dispatch({ type: "LOADING", payload: false }); // stop loading
          localStorage.removeItem("registerState"); // remove from local storage
        }}
      />
      <ConfirmModal
        show={state.showConfirm}
        title="You have unsaved changes"
        desc="Do you want to continue?"
        textConfirm="Leave"
        onConfirm={() => {
          dispatch({ type: "SET_DIRTY", payload: false });
          dispatch({ type: "SET_PAGE", payload: state.clickedPage });
          dispatch({ type: "SET_SHOW_CONFIRM", payload: false });
        }}
        onClose={() => {
          dispatch({ type: "SET_SHOW_CONFIRM", payload: false });
        }}
      />
      {children}
    </RegisterContext.Provider>
  );
};

export const useDirtyWatcher = (dirty: boolean) => {
  const { state, dispatch } = useRegisterContext();

  useEffect(() => {
    if (dirty) {
      dispatch({ type: "SET_DIRTY", payload: true });
    }
  }, [dirty, dispatch]);

  const onSubmitted = useCallback(() => {
    dispatch({ type: "SET_DIRTY", payload: false });
  }, [dispatch]);

  return { dirty: state.isDirty, onSubmitted };
};
