import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";

import {
  Card,
  OutlineSelect,
  SelectDateRange,
  Button,
  Modal,
  TabsBar,
  Tab,
} from "components";
import { ReactComponent as Download } from "assets/CarbonCredit-SVG/Download.svg";
import { ReactComponent as Calendar } from "assets/CarbonCredit-SVG/Calendar.svg";
import { APIError } from "models/generic";
import { useUserSocket } from "providers";
import { MyOrderData, MyOrderRes } from "models/WebsocketClients";
import { config } from "config";
import { mediaQuery } from "utils";

import { TradeHistory, ActiveOrder, InactiveOrder } from "../components";

const { path } = config;

interface IDropdownSort {
  onDownloadClick?: () => void;
  onCalendarClick?: () => void;
  onSelect?: (val: string) => void;
}

const CardView = styled(Card)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.baseBackgroundColor};
  padding: 20px 0;
  ${mediaQuery("mobile")} {
    padding: 20px;
  }
`;

const StyledTabsBar = styled(TabsBar)`
  height: 100%;
  overflow: hidden;
  .tab-ul-container {
    justify-content: space-between;
    .tab-ul {
      width: fit-content;
      .tab {
        padding-bottom: 8px;
      }
    }
  }
  .tab-content-container {
    overflow: auto;
  }
  li.is-active {
    color: ${(props) => props.theme.textColor};
  }
`;

const TabHeader = styled.h2`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 16px;
`;

const TopRight = styled.div`
  /* position: absolute;
  right: 21px; */
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  padding: 0 8px;
  svg {
    display: block;
    stroke: ${(props) => props.theme.darkgray};
    color: ${(props) => props.theme.darkgray};
    cursor: pointer;
  }
`;

const StyledModal = styled(Modal)`
  .content-container {
    max-width: 400px;
    text-align: center;
  }
`;

const ModalTitle = styled.h2``;

const ModalDesc = styled.div`
  margin: 16px 0 8px;
`;

const ModalFooter = styled.div`
  display: flex;
`;

const DropdownSort = ({
  onDownloadClick,
  onCalendarClick,
  onSelect,
}: IDropdownSort) => {
  const [select, setSelect] = useState("Sort by Time");

  const changeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e) {
      setSelect(e.target.value);
      onSelect && onSelect(e.target.value);
    }
  };

  return (
    <TopRight>
      <Icon>
        <Download onClick={onDownloadClick} />
      </Icon>
      <Icon>
        <Calendar onClick={onCalendarClick} />
      </Icon>
      <OutlineSelect
        onChange={changeSelect}
        id="dropdown-menu-align-right"
        title={select}
        disabled
        style={{
          width: "200px",
          margin: 0,
          paddingBottom: 0,
        }}
      >
        <option value="all">All History</option>
        <option value="1d">1 Day</option>
        <option value="3d">3 Days</option>
        <option value="1w">1 Week</option>
        <option value="1m">1 Month</option>
      </OutlineSelect>
    </TopRight>
  );
};

const History = ({ onCancel }: { onCancel: (order: MyOrderData) => void }) => {
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  const [activeTab, setactiveTab] = useState("ActiveOrder");
  const [showDateSelect, setShowDateSelect] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const {
    state: { myOrder, authenticated },
  } = useUserSocket();

  const onExportClick = () => {
    setShowExportModal(false);
  };

  // todo: change to map of order id to order
  // to avoid having to loop through all orders
  const [orders, setOrders] = useState<MyOrderData[]>([]);

  const sortedOrders = useMemo(() => {
    // create a copy of orders
    const sortedOrders = [...orders];
    sortedOrders.sort((a, b) => +b.id - +a.id);
    return sortedOrders;
  }, [orders]);

  const handleData = useCallback(
    (data: MyOrderRes) => {
      setOrders((prev) => {
        let newOrders = [...prev];
        // data.orders = new orders
        if (data.orders && data.orders.length > 0) {
          // check if order is already in orders
          // for active orders, we only want active and partially filled orders (except market orders)
          if (activeTab === "ActiveOrder") {
            data.orders.forEach((order) => {
              const index = newOrders.findIndex((o) => o.id === order.id);
              if (
                order.status === "active" ||
                (order.status === "partially_filled" && order.type !== "market")
              ) {
                // new order
                if (index === -1) {
                  newOrders = [...newOrders, order];
                } else {
                  // update order
                  newOrders[index] = order;
                }
              } else if (index !== -1) {
                // other status, remove order if it exists
                newOrders.splice(index, 1);
              }
            });
          } else {
            // for inactive orders, we want all orders except active and partially filled (except market orders)
            data.orders.forEach((order) => {
              const index = newOrders.findIndex((o) => o.id === order.id);
              if (
                order.status === "active" ||
                (order.status === "partially_filled" && order.type !== "market")
              ) {
                // remove order if it exists
                if (index !== -1) {
                  newOrders.splice(index, 1);
                }
              } else if (index === -1) {
                newOrders = [...newOrders, order];
              } else {
                newOrders[index] = order;
              }
            });
          }
        }

        // data.order_changes = order changes
        if (data.order_changes && data.order_changes.length > 0) {
          data.order_changes.forEach((change) => {
            // if amount = 0, remove from orders (cancelled)
            if (parseFloat(change.amount) === 0) {
              newOrders = newOrders.filter((order) => order.id !== change.id);
            } else {
              // if amount > 0, update orders (+= filled)
              const idx = newOrders.findIndex(
                (order) => order.id === change.id
              );
              if (idx > -1) {
                const newFilled =
                  parseFloat(newOrders[idx].filled) + parseFloat(change.filled);
                const amountFloat = parseFloat(newOrders[idx].amount);
                // if newFilled = amount, remove from orders
                if (newFilled === amountFloat) {
                  newOrders.splice(idx, 1);
                } else {
                  const newTotal =
                    parseFloat(newOrders[idx].total) + parseFloat(change.total);
                  const newAvg =
                    (parseFloat(newOrders[idx].avg_price) *
                      parseFloat(newOrders[idx].filled) +
                      parseFloat(change.avg_price) *
                        parseFloat(change.filled)) /
                    newFilled;

                  // else update
                  const newOrder = {
                    ...newOrders[idx],
                    filled: newFilled.toString(),
                    total: newTotal.toString(),
                    avg_price: newAvg.toString(),
                  };
                  newOrders[idx] = newOrder;
                }
              }
            }
          });
        }
        return newOrders;
      });
    },
    [activeTab]
  );

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in market history: ", error);
    },
    [navigate]
  );

  useEffect(() => {
    if (
      myOrder &&
      authenticated &&
      !subscribed &&
      (activeTab === "ActiveOrder" || activeTab === "InactiveOrder")
    ) {
      setSubscribed(true);
      setOrders([]);
      myOrder.subscribe(activeTab === "ActiveOrder" ? "active" : "inactive");
      myOrder.onData = handleData;
      myOrder.onError = handleError;
    }
    return () => {
      if (myOrder && subscribed) {
        myOrder.unsubscribe(
          activeTab === "ActiveOrder" ? "active" : "inactive"
        );
        setSubscribed(false);
      }
    };
  }, [activeTab, authenticated, handleData, handleError, myOrder, subscribed]);

  useEffect(() => {
    if (!authenticated) {
      // clear orders
      setOrders([]);
    }
  }, [authenticated]);

  return (
    <CardView>
      <SelectDateRange
        show={showDateSelect}
        onOk={(startDate, endDate) => setShowDateSelect(false)}
        onCancel={() => setShowDateSelect(false)}
      />
      <StyledModal
        show={showExportModal}
        onBackgroundClick={() => setShowExportModal(false)}
      >
        <ModalTitle>Export document</ModalTitle>
        <ModalDesc>
          A document will export as a file (.csv) and download automatically
          after a confirmation
        </ModalDesc>
        <ModalFooter>
          <Button
            style={{ marginRight: "8px", marginBottom: 0 }}
            variant="secondary"
            block
            onClick={() => setShowExportModal(false)}
          >
            Cancel
          </Button>
          <Button style={{ marginBottom: 0 }} block onClick={onExportClick}>
            Export
          </Button>
        </ModalFooter>
      </StyledModal>
      <StyledTabsBar
        mode="horizontal"
        onSelect={(k: string | null) => setactiveTab(k || "")}
        // TODO: Integrate export CSV
        // afterUl={
        //   activeTab === "TradeHistory" && (
        //     <DropdownSort
        //       onCalendarClick={() => setShowDateSelect(true)}
        //       onDownloadClick={() => setShowExportModal(true)}
        //     />
        //   )
        // }
      >
        <Tab
          layoutId="history"
          key="ActiveOrder"
          label={<TabHeader>Active Order</TabHeader>}
        >
          <ActiveOrder
            orders={activeTab === "ActiveOrder" ? sortedOrders : []}
            onCancel={onCancel}
          />
        </Tab>
        <Tab
          layoutId="history"
          key="InactiveOrder"
          label={<TabHeader>Inactive Order</TabHeader>}
        >
          <InactiveOrder
            orders={activeTab === "InactiveOrder" ? sortedOrders : []}
          />
        </Tab>
        <Tab
          layoutId="history"
          key="TradeHistory"
          label={<TabHeader>Trade History</TabHeader>}
        >
          <TradeHistory />
        </Tab>
      </StyledTabsBar>
    </CardView>
  );
};

export default History;
