import { ChangeEvent, ReactNode, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled/macro";
import * as Yup from "yup";
import numeral from "numeral";
import { isEmpty, round } from "lodash-es";
import { motion } from "framer-motion";

// import wallet from "assets/CarbonCredit-SVG/Wallet.svg";
import { Button, IconButton, TextField } from "components";
import { Order, OrderSide, OrderType } from "models/market";
import { config } from "config";
import { priceNumber } from "utils";
import { ReactComponent as ArrowDown } from "assets/CarbonCredit-SVG/ShowMenu.svg";
import { ConfirmSwitchPayment } from "../ConfirmSwitchPayment";

const {
  vat,
  buyerFee,
  sellerFee,
  whTax,
  minTradePrice,
  feeRoundingReserveRate,
} = config;

export interface FormValues {
  amount: string;
  price: string;
  total: string;
}

const DivTitle = styled.div`
  text-align: left;
  margin-bottom: 18px;
  color: ${(props) => props.theme.secondaryColor};
  display: flex;
  height: 20px;
  font-size: 14px;
`;

const AvailableText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: #848e9c;
`;

const WalletDiv = styled.div`
  margin-left: auto;
  font-size: 0.75rem;
  font-weight: 400;
  color: #eaecef;
`;
const InputField = styled(TextField)`
  margin: 0px 0px 12px 0px;
  outline: none;
  margin-bottom: 8px;
  padding-right: 40px;
`;

const Form = styled.form``;

const ErrorCardContainer = styled.div<{ variant: "down" | "up" }>`
  position: absolute;
  top: ${(props) => (props.variant === "up" ? "-40px" : "35px")};
  animation: fadeIn linear 0.2s;
  -webkit-animation: fadeIn linear 0.2s;
  -moz-animation: fadeIn linear 0.2s;
  -o-animation: fadeIn linear 0.2s;
  -ms-animation: fadeIn linear 0.2s;
  .error-content {
    display: flex;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    height: 36px;
    background-color: blue;
    background-color: #1f2c57;
    div {
      width: max-content;
      margin: auto 18px;
    }
  }
  .arrow {
    margin-left: 10px;
    font-size: 0px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    &.arrow-down {
      border-top: 5px solid #1f2c57;
    }
    &.arrow-up {
      border-bottom: 5px solid #1f2c57;
    }
  }
`;

const OrderSummary = styled(motion.div)`
  position: absolute;
  z-index: ${(props) => props.theme.defaultZIndex};
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${(props) => props.theme.inputPadding};
  border-radius: ${(props) => props.theme.inputBorderRadius};
  border-color: ${(props) => props.theme.inputDisableBackground};
  background-color: ${(props) => props.theme.inputDisableBackground};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  padding-bottom: 48px;
`;

const SummaryHeader = styled.h3`
  grid-column: span 2;
  margin-bottom: 8px;
`;

const SummaryDescription = styled.div`
  grid-column: span 2;
  color: ${(props) => props.theme.darkgray200};
  font-size: 0.625rem;
  margin: 8px 0;
  text-align: center;
`;

const SummaryLabel = styled.div``;

const SummaryValue = styled.div`
  text-align: right;
`;

const TotalSummaryValue = styled(SummaryValue)`
  white-space: nowrap;
`;

const TotalBox = styled.div`
  // replicate the input field
  height: 48px;
  width: 100%;
  border-radius: ${(props) => props.theme.inputBorderRadius};
  border: 1px solid ${(props) => props.theme.gray1};
  background-color: ${(props) => props.theme.black};
  padding: ${(props) => props.theme.inputPadding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1rem;
  font-size: 0.875rem;
`;

const Total = styled.div<{ disabled: boolean }>`
  position: relative;
  z-index: ${(props) => +props.theme.defaultZIndex + 1};
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  color: ${(props) =>
    props.disabled ? props.theme.textColorDisabled : props.theme.textColor};
`;

const IconButtonStyled = styled(IconButton)`
  margin: 0;
`;

const ArrowDownIcon = styled(ArrowDown)<{
  collapsed: boolean;
  disabled: boolean;
}>`
  transform: rotate(${(props) => (props.collapsed ? 0 : 180)}deg);
  transition: transform 0.3s;
  path {
    fill: ${(props) =>
      props.disabled ? props.theme.textColorDisabled : props.theme.textColor};
  }
`;

const ErrorCard = ({
  children,
  variant = "up",
}: {
  children: ReactNode;
  variant?: "down" | "up";
}) => (
  <ErrorCardContainer variant={variant}>
    {variant === "down" && <div className="arrow arrow-up" />}
    <div className="error-content">
      <div>{children}</div>
    </div>
    {variant === "up" && <div className="arrow arrow-down" />}
  </ErrorCardContainer>
);

type Props = {
  side?: OrderSide;
  onSubmit: (val: Order) => void;
  money: number;
  baseCurrency: string;
  quoteCurrency: string;
  disabled: boolean;
  type?: OrderType;
};

const digitRegex = /^[0-9]*$/;

export const TxForm = ({
  onSubmit,
  money,
  side = "buy",
  baseCurrency,
  quoteCurrency,
  disabled = false,
  type = "limit",
}: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [payment, setPayment] = useState("fiat");
  const [collapsed, setCollapsed] = useState(true);
  const currency = side === "buy" ? quoteCurrency : baseCurrency;

  const withinLimit = (value: number) =>
    side === "sell" ? money >= (value ?? 0) : true;

  const withinMarketLimit = (value: number) => money >= (value ?? 0);

  const validationSchema =
    type === "limit"
      ? Yup.object().shape({
          price: Yup.number()
            .required("Please input price")
            .typeError("Please input number only")
            .min(minTradePrice, `Minimum price is ${minTradePrice}`),
          amount: Yup.number()
            .required("Please input amount")
            .typeError("Please input number only")
            .test(
              "Value within limit",
              "Not enough credit",
              withinLimit as any
            ),
          total: Yup.string().test("Valid Value", "Not enough money.", (val) =>
            side === "buy"
              ? (numeral(val ?? "0").value() as number) <= money
              : true
          ),
        })
      : Yup.object().shape({
          amount: Yup.number()
            .required("Please input amount")
            .typeError("Please input number only")
            .test(
              "Value within limit",
              "Not enough credit",
              withinMarketLimit as any
            ),
        });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: { amount: "", price: "", total: "" },
  });

  watch((data) => {
    const { amount, price, total } = data;
    if (amount && price) {
      const newVal = (parseFloat(amount) * parseFloat(price)).toString();
      if (total !== newVal) {
        setValue("total", newVal);
        trigger("total");
      }
    } else if ((amount === "" || price === "") && total !== "") {
      setValue("total", "");
    }
  });

  const [amount, total] = watch(["amount", "total"]) as [string, string];

  const [subTotalVal, vatVal, feeVal, totalVal] = useMemo(() => {
    if (total) {
      // see https://docs.google.com/presentation/d/1UxVD0mLafTI64sETQZDepo3z0NZcS9hsT-SKVv_2XMY/edit#slide=id.g1864ffca3e1_1_80
      const subTotalVal = parseFloat(total);
      const vatVal = round(subTotalVal * vat, 2);
      const fee = round(
        subTotalVal * (side === "buy" ? buyerFee : sellerFee),
        2
      );
      const feeVat = round(fee * vat, 2);
      const whtVal = round(fee * whTax, 2);
      const feeRoundingReserve = parseInt(amount, 10) * feeRoundingReserveRate;
      const feeTotal = fee + feeVat - whtVal;
      const feeVal = side === "buy" ? feeTotal + feeRoundingReserve : -feeTotal;
      const totalVal = subTotalVal + vatVal + feeVal;
      return [subTotalVal, vatVal, feeVal, totalVal];
    }
    return [0, 0, 0, 0];
  }, [amount, side, total]);

  const isError = !isEmpty(errors);

  const digitsOnlyChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (val: any) => void
  ) => {
    const { value } = e.target;
    return digitRegex.test(value) ? onChange(e.target.value) : null;
  };

  const handleFormSubmit = ({ price = "", amount = "", total = "" }) => {
    onSubmit({
      side,
      rate: +price,
      amount: +amount,
      total: parseFloat(total),
    });
    setCollapsed(true);
    reset({
      price: "",
      amount: "",
      total: "",
    });
  };

  return (
    <>
      <ConfirmSwitchPayment
        type={showConfirmModal as "fiat" | "credit"}
        show={!!showConfirmModal}
        onCancel={() => setShowConfirmModal("")}
        onConfirm={() => {
          setPayment(showConfirmModal);
          setShowConfirmModal("");
        }}
      />
      <Form
        onSubmit={handleSubmit(handleFormSubmit)}
        autoComplete="off"
        id={`txform-${side}`}
        className={`txform-${side}`}
      >
        <DivTitle>
          <AvailableText>Available</AvailableText>
          <WalletDiv
            id={`${currency}-balance`}
            className={`balance ${currency}-balance ${side}-balance`}
          >
            {priceNumber(money, {
              min: currency === "credit" ? 0 : 2,
              max: currency === "credit" ? 0 : 2,
            })}{" "}
            {currency}
          </WalletDiv>
        </DivTitle>
        {type === "limit" && (
          <div style={{ position: "relative", display: "inline" }}>
            <Controller
              control={control}
              name="price"
              render={({ field: { onChange, ...rest } }) => (
                <InputField
                  placeholder="Price"
                  {...rest}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    digitsOnlyChange(e, onChange);
                  }}
                  containerStyle={{
                    paddingBottom: "1rem",
                  }}
                  maxLength={9}
                  errorWarn={!!errors.price}
                  disabled={disabled}
                  suffix={
                    <div
                      style={{
                        fontSize: "14px",
                        color: "white",
                      }}
                    >
                      {quoteCurrency}
                    </div>
                  }
                />
              )}
            />
            {errors.price?.type === "min" ? (
              <ErrorCard>{errors.price?.message}</ErrorCard>
            ) : (
              ""
            )}
          </div>
        )}
        <div style={{ position: "relative", display: "inline" }}>
          <Controller
            control={control}
            name="amount"
            render={({ field: { onChange, ...rest } }) => (
              <TextField
                placeholder="Amount"
                {...rest}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  digitsOnlyChange(e, onChange);
                }}
                containerStyle={{
                  paddingBottom: "1rem",
                }}
                maxLength={6}
                errorWarn={!!errors.amount}
                disabled={disabled}
                suffix={
                  <div
                    style={{
                      fontSize: "14px",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {baseCurrency}
                  </div>
                }
              />
            )}
          />
          {errors.amount?.message === "Not enough credit" ? (
            <ErrorCard>{errors.amount?.message}</ErrorCard>
          ) : (
            ""
          )}
        </div>
        {type === "limit" && (
          <div style={{ position: "relative", display: "inline" }}>
            <TotalBox>
              <OrderSummary
                initial={false}
                animate={
                  !collapsed
                    ? {
                        opacity: 1,
                        display: "grid",
                      }
                    : {
                        opacity: 0,
                        transitionEnd: {
                          display: "none",
                        },
                      }
                }
                transition={{
                  duration: 0.3,
                }}
              >
                <SummaryHeader>Order Summary</SummaryHeader>
                <SummaryLabel>SubTotal:</SummaryLabel>
                <SummaryValue>
                  {priceNumber(subTotalVal)} {quoteCurrency}
                </SummaryValue>
                <SummaryLabel>VAT:</SummaryLabel>
                <SummaryValue>
                  {priceNumber(vatVal)} {quoteCurrency}
                </SummaryValue>
                <SummaryLabel>FEE:</SummaryLabel>
                <SummaryValue>
                  {priceNumber(feeVal)} {quoteCurrency}
                </SummaryValue>
                <SummaryDescription>
                  (VAT and Fee are approximately calculated)
                </SummaryDescription>
              </OrderSummary>
              <Total disabled={!total}>
                <SummaryLabel>Total:</SummaryLabel>
                <TotalSummaryValue>
                  {priceNumber(totalVal)} {quoteCurrency}
                  <IconButtonStyled
                    type="button"
                    icon={
                      <ArrowDownIcon collapsed={collapsed} disabled={!total} />
                    }
                    disabled={!total}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                </TotalSummaryValue>
              </Total>
            </TotalBox>

            {errors.total ? (
              <ErrorCard variant="down">{errors.total?.message}</ErrorCard>
            ) : (
              ""
            )}
          </div>
        )}
        <Button
          variant={side === "buy" ? "success" : "danger"}
          block
          type="submit"
          disabled={isError || disabled}
        >
          {side === "buy" ? "Buy" : "Sell"}
        </Button>
      </Form>
    </>
  );
};
