import { useLayoutEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { motion } from "framer-motion";
import useSWR from "swr";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, mixed } from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";

import { UserPaymentMethodRes } from "models/paymentMethod";
import { Modal, Button, TextField, UploadInput } from "components";
import { setDefaultUserPayment } from "api/paymentMethod";
import { usePin } from "providers";
import { uploadFile } from "api/file";
import { transactionDeposit } from "api/transaction";
import { APIError } from "models/generic";
import { twoDecimalChange, captureErrorSentry } from "utils";
import * as errorCodes from "models/apiErrorCodes";

import { BankSelect } from "../BankSelect";
import { DepositWithdrawHeader } from "../components";

interface IMoneyDeposit {
  show: boolean;
  onCancel: () => void;
  onDeposit: (amount: number) => void;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 400px;
    max-width: 500px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 24px;
`;

const Desc = styled.h3`
  margin-bottom: 16px;
  font-weight: 800;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 12px;
`;

const Key = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.darkgray200};
`;

const Value = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.textColor};
`;

const Container = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
`;

const Footer = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  border: 1px solid ${(props) => props.theme.white};
`;

const schema = object().shape({
  amount: number()
    .required("Amount is a required field.")
    .notOneOf([0], "Amount is a required field.")
    .typeError("Please enter number only")
    .min(0, "Negative amount is not allowed.")
    .max(1000000000, "Maximum amount: 1,000 million per transaction"),
  file: mixed().test(
    "required",
    "Please Upload Slip or Receipt",
    (file: FileList) => file && (file.item(0)?.size ?? 0) > 0
  ),
});

type EscoBank = "scb" | "ktb" | "exim";

const escoBank = (code: EscoBank) => {
  switch (code) {
    case "scb":
      return {
        bank_name: "Siam Commercial Bank",
        bank_branch: "0247 สาขา เซ็นทรัลเวิลด์",
        account_number: "247-259671-0",
      };
    case "ktb":
      return {
        bank_name: "Krungthai Bank",
        bank_branch: "691 สาขา ห้างสรรพสินค้าเซ็นทรัลเวิลด์",
        account_number: "017-0-28868-4",
      };
    case "exim":
      return {
        bank_name: "Export-Import Bank",
        bank_branch: "สำนักงานใหญ่",
        account_number: "001-1-05223-6",
      };
    default:
      return {
        account_number: "247-259671-0",
        bank_branch: "0247 สาขา เซ็นทรัลเวิลด์",
      };
  }
};

const MoneyDeposit = ({ show, onCancel, onDeposit }: IMoneyDeposit) => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm<{ amount: string; file: any }>({
    resolver: yupResolver(schema),
  });

  const { showPin, closePin, setPinError } = usePin();

  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [depositAmount, fileUploaded] = watch(["amount", "file"]);
  const fileName = fileUploaded?.item(0)?.name;

  const { data } = useSWR<UserPaymentMethodRes>("/payment-method/my");

  useLayoutEffect(() => {
    setStep(1);
  }, [show]);

  const accounts = useMemo(() => {
    if (!data) return [];
    return data?.data.user_payment_methods;
  }, [data]);

  const getSelectedAccount = useMemo(
    () =>
      accounts.find((acc) => {
        if (selectedAccount === "") {
          return acc.is_default;
        }
        return acc.account_number === selectedAccount;
      }),
    [accounts, selectedAccount]
  );

  const brokerAccount = useMemo(() => {
    if (!getSelectedAccount) return;
    return escoBank("scb");
  }, [getSelectedAccount]);

  const handleSelectBank = (id: string, shouldSetDefault: boolean) => {
    setSelectedAccount(id);
    if (shouldSetDefault) {
      const userId = accounts.find((acc) => acc.account_number === id)?.id ?? 0;
      setDefaultUserPayment(userId);
    }
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  const onConfirmPin = async (pin: string) => {
    try {
      const getFile = fileUploaded?.item(0);

      const blob = new Blob([getFile as Blob]);
      const contentType = getFile?.type;
      const formdata = new FormData();

      formdata.append("file_name", getFile?.name || "");
      formdata.append("upload_file", blob);
      formdata.append("content_type", contentType || "");

      const response = await uploadFile(formdata);

      await transactionDeposit({
        user_payment_method_id: getSelectedAccount?.id || 0,
        amount: depositAmount,
        pin,
        proof: response.data.file_name,
      });
      closePin();
      onDeposit(parseFloat(depositAmount));
      reset();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.InvalidPin) {
          setPinError({
            isError: true,
            errorMessage: "Incorrect PIN. Try again.",
          });
        } else {
          toast.error("Something went wrong. Please try again later.");
          captureErrorSentry(error, err, {
            message: "Deposit Money error",
          });
        }
      }
    }
  };
  const onSubmitForm = async () => {
    showPin({
      onConfirm: (pin) => onConfirmPin(pin),
      show: true,
    });
  };

  const renderModalContent = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            key="deposit-step-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {getSelectedAccount !== undefined ? (
              <Container>
                <Title>Money Deposit</Title>
                <Desc>
                  Please transfer your money to the following bank account
                  details.
                </Desc>
                <Grid>
                  <Key>Bank</Key>
                  <Value style={{ textTransform: "capitalize" }}>
                    {brokerAccount?.bank_name}
                  </Value>
                  <Key>Bank Branch</Key>
                  <Value>{brokerAccount?.bank_branch}</Value>
                  <Key>Bank Account Name</Key>
                  <Value>บริษัท เอสโคโพลิส จำกัด</Value>
                  <Key>Bank Account Number</Key>
                  <Value>{brokerAccount?.account_number}</Value>
                </Grid>
              </Container>
            ) : (
              <Container>
                <Title>Money Deposit</Title>
                <Value style={{ textAlign: "center" }}>
                  You don&apos;t have any bank account yet.
                  <br />
                  Please contact your broker to add your bank account.
                </Value>
              </Container>
            )}
            <Footer>
              {getSelectedAccount !== undefined ? (
                <>
                  <Button
                    style={{ marginRight: "8px", marginBottom: 0 }}
                    variant="secondary"
                    block
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginBottom: 0 }}
                    block
                    onClick={() => setStep(2)}
                    disabled={getSelectedAccount === undefined}
                  >
                    I have transferred money
                  </Button>
                </>
              ) : (
                <Button
                  style={{ marginRight: "8px", marginBottom: 0 }}
                  variant="primary"
                  block
                  onClick={onCancel}
                >
                  Close
                </Button>
              )}
            </Footer>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="deposit-step-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <form id="deposit" onSubmit={handleSubmit(onSubmitForm)}>
              <DepositWithdrawHeader
                title="Money Deposit Details"
                onBackClick={() => setStep(1)}
              />
              <BankSelect
                selectedAccount={getSelectedAccount}
                accounts={accounts}
                onSelect={handleSelectBank}
                addAble
                label="Deposit with"
              />
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <StyledTextField
                    label="Deposit Amount"
                    placeholder="0.00"
                    errorWarn={!!errors.amount}
                    errorMessage={errors.amount?.message}
                    disabled={getSelectedAccount === undefined}
                    onChange={(e) => twoDecimalChange(e, onChange)}
                    {...rest}
                  />
                )}
              />
              <UploadInput
                id="file"
                type="file"
                label="Money Transfer Slip or Receipt"
                errorWarn={!!errors.file}
                errorMessage={errors.file?.message}
                placeholder={fileName || "Upload file"}
                inputStyle={{
                  color: fileName && theme.textColor,
                }}
                style={{ borderColor: "white" }}
                disabled={getSelectedAccount === undefined}
                accept=".jpg, .jpeg, .png, .pdf"
                {...register("file")}
              />
            </form>
            <Footer>
              <Button
                style={{ marginRight: "8px", marginBottom: 0 }}
                variant="secondary"
                block
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="deposit"
                style={{ marginBottom: 0 }}
                block
                disabled={getSelectedAccount === undefined}
              >
                Deposit
              </Button>
            </Footer>
          </motion.div>
        );
      default:
        break;
    }
  };

  return (
    <StyledModal show={show} onBackgroundClick={handleCancel}>
      {renderModalContent()}
    </StyledModal>
  );
};

export default MoneyDeposit;
