import { colorMix, setAlpha } from "utils";
import * as colors from "./colors";

export const Theme = {
  // colors
  ...colors,
  primaryColor: colors.blue,
  secondaryColor: colors.white,
  darkgray: colors.darkgray200,
  gray: colors.darkgray100,
  lightgray: colors.darkgray50,
  errorColor: colors.red6,
  successColor: colors.green,
  warningColor: colors.orange,

  // baseStyle
  borderRadiusBase: "8px",
  boxShadowBase: "none",
  dropShadowBase: "",
  contentMaxWidth: "1366px",
  loginMaxWidth: "540px",
  cardBackgroundColor: colors.black3,

  // background
  baseBackgroundColor: colors.black,
  loginBackgroundColor: colors.white2,
  componentBackgroundColor: colors.gray1,

  // text
  fontFamily: "Nunito Sans, Kanit , Arial, Helvetica, sans-serif",
  fontSizeBase: "16px",
  lineHeightBase: "1.375",
  textColor: colors.white,
  textColorSecondary: colors.black,
  textColorDisabled: colors.gray3,
  textColorLight: colors.white,

  // header
  headingColor: colors.white,
  headingLineHeight: "1.375",
  h1Size: "2rem",
  h1Weight: "900",
  h2Size: "1.375rem",
  h2Weight: "900",
  h3Size: "1rem",
  h3Weight: "800",
  h4Size: "1rem",
  h4Weight: "600",
  h5Size: ".83rem", // default html
  h5Weight: "bold", // default html
  h6Size: ".67rem", // default html
  h6Weight: "bold", // default html

  // buttons
  buttonFontWeight: "700",
  buttonPaddingSmall: "10px",
  buttonPaddingMedium: "12px",
  buttonPaddingLarge: "14px",
  buttonBorderRadius: "6px",
  buttonFontSize: "0.875rem",
  // primary buttons
  primaryButtonHover: colors.blueMix7,
  primaryButtonActive: colors.blue,
  primaryButtonDisable: "#252525",
  // secondary buttons
  secondaryButtonHover: colors.white3,
  secondaryButtonActive: colors.gray1,
  secondaryButtonDisableBorder: colors.darkgray100,
  // link buttons
  linkButtonActive: colors.lightblue500,
  // success buttons
  successButton: colors.green,
  successButtonHover: colors.green2,
  successButtonActive: colors.darkgreen,
  successButtonDisabled: colorMix(colors.green, 8),
  // danger buttons
  dangerButton: colors.red,
  dangerButtonHover: colors.red3,
  dangerButtonActive: colors.crimson,
  dangerButtonDisabled: colorMix(colors.red3, 8),
  // warning buttons
  warningButton: colors.orange,
  warningButtonHover: colors.orange2,
  warningButtonActive: colors.darkorange,
  warningButtonDisabled: colors.orange3,

  // input
  inputBaseHeight: "42px",
  inputFontSize: "0.875rem",
  inputPadding: "16px",
  inputBorderRadius: "8px",
  inputBackgroundColor: colors.gray1,
  inputDisableBackground: setAlpha(colors.black, 0.5),
  inputBorderColor: colors.gray1,
  inputPlaceholderColor: colors.white4,
  inputDisableBorderColor: setAlpha(colors.black, 0.5),

  // select
  selectBorderColor: colors.white,
  selectDisabledBorderColor: setAlpha(colors.black, 0.5),

  // login
  boxShadowLogin: "0 0 14px 8px rgba(217, 225, 233, 0.24)",

  // breakpoints
  breakpointMobile: "576px",
  breakpointTablet: "768px",
  breakpointDesktop: "992px",
  breakpointLargeDesktop: "1200px",

  // z-index
  defaultZIndex: "1",
  sidebarZIndex: "5",
  navbarZIndex: "10",
  dropdownZIndex: "50",
  modalZIndex: "100",

  // modal
  modalBackgroundColor: colors.gray1,

  // dropdown
  dropdownItemHover: colors.white4,

  // sidebar
  sidebarBackgroundColor: colors.black2,

  // navbar
  navbarBackgroundColor: colors.black2,
};
