import { useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import useSWR from "swr";
import { number, object } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePin, useUserSocket } from "providers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import { TextField, Modal, Button } from "components";
import { captureErrorSentry, priceNumber, twoDecimalChange } from "utils";
import { UserPaymentMethodRes } from "models/paymentMethod";
import { setDefaultUserPayment } from "api/paymentMethod";
import { transactionWithdraw } from "api/transaction";
import { ReactComponent as Info } from "assets/CarbonCredit-SVG/Info.svg";
import * as errorCodes from "models/apiErrorCodes";
import { APIError } from "models/generic";

import { BankSelect } from "../BankSelect";

interface IMoneyWithdraw {
  show: boolean;
  onWithdraw: (amount: number) => void;
  onCancel: () => void;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 450px;
    max-width: 550px;
  }
`;

const Footer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 12px;
  margin: 24px 0;
`;

const Key = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${(props) => props.theme.textColor};
`;

const GreyKey = styled(Key)`
  color: ${(props) => props.theme.darkgray200};
`;

const Value = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  text-align: right;
  color: ${(props) => props.theme.textColor};
`;

const InfoIcon = styled(Info)`
  path {
    fill: ${(props) => props.theme.darkgray200};
  }
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.12));
  .tooltip-inner {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.darkgray200};
  }
  .tooltip-arrow {
    &::before {
      border-top-color: ${(props) => props.theme.componentBackgroundColor};
    }
  }
  &.show {
    opacity: 1;
  }
  z-index: ${(props) => +props.theme.modalZIndex + 1};
`;

const StyledTextField = styled(TextField)`
  border: 1px solid ${(props) => props.theme.white};
`;

const MoneyWithdraw = ({ show, onWithdraw, onCancel }: IMoneyWithdraw) => {
  const {
    state: { wallets },
  } = useUserSocket();

  const { thbWallet } = useMemo(() => {
    if (wallets) {
      const thbWallet = wallets?.find(({ symbol }) => symbol === "THB");
      return { thbWallet };
    }
    return { thbWallet: undefined };
  }, [wallets]);

  const schema = object().shape({
    amount: number()
      .required("Amount is a required field.")
      .notOneOf([0], "Amount is a required field.")
      .typeError("Please enter number only")
      .min(0, "Negative amount is not allowed.")
      .test("Valid Value", "Not enough money.", (val) =>
        thbWallet ? (+(val ?? "0") as number) <= thbWallet.cash_balance : false
      )
      .max(1000000000, "Maximum amount: 1,000 million per transaction"),
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm<{ amount: string }>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const { showPin, closePin, setPinError } = usePin();
  const [selectedAccount, setSelectedAccount] = useState("");
  const withdrawAmount = watch("amount");

  const { data } = useSWR<UserPaymentMethodRes>("/payment-method/my");

  const accounts = useMemo(() => {
    if (!data) return [];
    return data?.data.user_payment_methods;
  }, [data]);

  const getSelectedAccount = useMemo(
    () =>
      accounts.find((acc) => {
        if (selectedAccount === "") {
          return acc.is_default;
        }
        return acc.account_number === selectedAccount;
      }),
    [accounts, selectedAccount]
  );

  const handleSelectBank = (id: string, shouldSetDefault: boolean) => {
    setSelectedAccount(id);
    if (shouldSetDefault) {
      const userId = accounts.find((acc) => acc.account_number === id)?.id ?? 0;
      setDefaultUserPayment(userId);
    }
  };

  const onConfirmPin = async (pin: string) => {
    try {
      await transactionWithdraw({
        currency_symbol: "THB",
        user_payment_method_id: getSelectedAccount?.id || 0,
        amount: withdrawAmount,
        pin,
      });
      onWithdraw(parseFloat(withdrawAmount));
      closePin();
      reset();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.InvalidPin) {
          setPinError({
            isError: true,
            errorMessage: "Incorrect PIN. Try again.",
          });
        } else {
          toast.error("Something went wrong. Please try again later.");
          captureErrorSentry(error, err, {
            message: "Withdraw Money error",
          });
        }
      }
    }
  };
  const onSubmitForm = async () => {
    showPin({
      onConfirm: (pin) => onConfirmPin(pin),
      show: true,
    });
  };

  return (
    <StyledModal show={show} onBackgroundClick={onCancel}>
      <Container>
        <Title>Money Withdrawal</Title>
        {getSelectedAccount !== undefined ? (
          <form id="withdraw" onSubmit={handleSubmit(onSubmitForm)}>
            <BankSelect
              selectedAccount={getSelectedAccount}
              accounts={accounts}
              onSelect={handleSelectBank}
              addAble
              label="Withdraw to"
            />
            <Grid>
              <Key>Available to withdraw</Key>
              <Value data-test-id="available-to-withdraw__text">
                {priceNumber(thbWallet?.cash_balance ?? 0)} THB
              </Value>
              <GreyKey>
                Total Available{" "}
                <OverlayTrigger
                  overlay={
                    <StyledTooltip id="tooltip">
                      Settlement happens 2 trading days after the trade is
                      executed (T+2). For example, if your sell order executes
                      on Monday, you&apos;d have your cash available to withdraw
                      by Wednesday.
                    </StyledTooltip>
                  }
                >
                  <InfoIcon />
                </OverlayTrigger>
              </GreyKey>
              <Value>{priceNumber(thbWallet?.amount ?? 0)} THB</Value>
            </Grid>
            <Controller
              name="amount"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <StyledTextField
                  label="Withdrawal amount"
                  placeholder="0.00"
                  errorWarn={!!errors.amount}
                  errorMessage={errors.amount?.message}
                  disabled={getSelectedAccount === undefined}
                  onChange={(e) => twoDecimalChange(e, onChange)}
                  {...rest}
                />
              )}
            />
          </form>
        ) : (
          <Value style={{ textAlign: "center" }}>
            You don&apos;t have any bank account yet.
            <br />
            Please contact your broker to add your bank account.
          </Value>
        )}
      </Container>
      <Footer>
        {getSelectedAccount !== undefined ? (
          <>
            <Button
              style={{ margin: "0 8px 0 0 " }}
              variant="secondary"
              block
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              form="withdraw"
              style={{ margin: 0 }}
              block
              disabled={getSelectedAccount === undefined}
            >
              Withdraw
            </Button>
          </>
        ) : (
          <Button
            style={{ margin: "0 8px 0 0 " }}
            variant="primary"
            block
            onClick={onCancel}
          >
            Close
          </Button>
        )}
      </Footer>
    </StyledModal>
  );
};

export default MoneyWithdraw;
