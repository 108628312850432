import { ChangeEvent, useEffect, useMemo } from "react";
import styled from "@emotion/styled/macro";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Button, OutlineSelect, TextField, Modal, IModal } from "components";
import { CreditTransactionWithdraw } from "models/transaction";
import { ReactComponent as Info } from "assets/CarbonCredit-SVG/Info.svg";
import { useUserSocket } from "providers";
import { digitsOnlyChange, priceNumber } from "utils";

interface ICreditWithdraw extends IModal {
  onCancel: () => void;
  onWithdrawClick: (values: CreditTransactionWithdraw) => void;
  symbol: string;
  retire: boolean;
  disabledSymbolSelect?: boolean;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 400px;
    min-height: 400px;
    max-height: 80vh;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
`;

const Content = styled.div`
  overflow: auto;
`;

const InfoIcon = styled(Info)`
  path {
    fill: ${(props) => props.theme.darkgray200};
  }
  margin-right: 4px;
  cursor: pointer;
`;

const AmountContainer = styled.span`
  margin-left: auto;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.darkgray200};
`;

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.12));
  .tooltip-inner {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.darkgray200};
    text-align: left;
    max-width: 220px;
  }
  .tooltip-arrow {
    &::before {
      border-top-color: ${(props) => props.theme.componentBackgroundColor};
    }
  }
  &.show {
    opacity: 1;
  }
  font-size: 0.625rem;
  font-weight: 400;
  z-index: ${(props) => +props.theme.modalZIndex + 1};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 12px;
  margin: 4px 0;
`;

const Key = styled.div`
  font-size: 0.688rem;
  font-weight: 600;
  white-space: nowrap;
  text-align: left;
`;

const Value = styled.div`
  font-size: 0.688rem;
  font-weight: 600;
  text-align: right;
`;

const Line = styled.div`
  grid-column: span 2;
  border-bottom: 1px solid ${(props) => props.theme.lightgray100};
`;

const StyledTextField = styled(TextField)`
  border: 1px solid ${(props) => props.theme.white};
`;

const CreditWithdraw = ({
  onCancel,
  onWithdrawClick,
  symbol,
  disabledSymbolSelect,
  show,
  retire,
  ...rest
}: ICreditWithdraw) => {
  const {
    state: { wallets },
  } = useUserSocket();
  const displayText = !retire ? "withdrawal" : "retirement";
  const verbText = !retire ? "withdraw" : "retire";

  const { wallet } = useMemo(() => {
    if (wallets) {
      const wallet = wallets?.find(
        ({ symbol: walletSymbol }) => walletSymbol === symbol
      );
      return { wallet };
    }
    return { wallet: undefined };
  }, [symbol, wallets]);

  const schema = object({
    amount: string()
      .required(`Please enter ${displayText} amount`)
      .test(
        "isNotZero",
        `Please enter ${displayText} amount`,
        (value) => +(value ?? 0) > 0
      )
      .test(`Valid Value", "Not enough credit to ${verbText}`, (val) =>
        wallet ? (+(val ?? "0") as number) <= wallet.cash_balance : false
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<CreditTransactionWithdraw>({
    defaultValues: { currency_symbol: symbol, amount: "", order: "ascending" },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show, symbol]);

  // const { data: markets } = useSWR<AllMarketRes>(`/market`);

  const onSubmit = (values: CreditTransactionWithdraw) => {
    onWithdrawClick({ ...values, currency_symbol: symbol });
  };

  return (
    <StyledModal
      data-test-id="credit-withdraw__modal"
      onBackgroundClick={onCancel}
      show={show}
      {...rest}
    >
      <Title>Credit {displayText}</Title>
      <form id="withdraw" onSubmit={handleSubmit(onSubmit)}>
        <Content>
          {/* TODO: Maybe use in Future */}
          {/* <OutlineSelect label="Market" placeholder="Select Registry">
            <option value="" disabled>
              Select Market
            </option>
            {markets?.data.map((market) => (
              <option value={market.id}>{market.name}</option>
            ))}
          </OutlineSelect>
          <OutlineSelect
            label="Registry"
            value=""
            placeholder="Select Registry"
          >
            <option value="" disabled>
              Select Registry
            </option>
          </OutlineSelect> */}
          <Controller
            name="currency_symbol"
            control={control}
            render={({ field: { value, onChange }, ...rest }) => (
              <OutlineSelect
                id="currency_symbol"
                label="Symbol"
                placeholder="Select Registry"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                {...rest}
                disabled={disabledSymbolSelect}
              >
                <option value={symbol}>{symbol}</option>
              </OutlineSelect>
            )}
          />

          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <StyledTextField
                {...rest}
                value={value}
                label="Amount"
                labelSuffix={
                  <AmountContainer>
                    <OverlayTrigger
                      overlay={
                        <StyledTooltip id="tooltip">
                          <Grid>
                            <Key>Available to {verbText}</Key>
                            <Value>
                              {priceNumber(wallet?.cash_balance || 0, {
                                min: 0,
                                max: 0,
                              })}{" "}
                              Credit
                            </Value>
                            <Line />
                            <Key>Total available</Key>
                            <Value>
                              {priceNumber(wallet?.amount || 0, {
                                min: 0,
                                max: 0,
                              })}{" "}
                              Credit
                            </Value>
                          </Grid>
                          Settlement happens 2 trading days after the trade is
                          executed (T+2). For example, if your sell order
                          executes on Monday, you&apos;d have your cash
                          available to {verbText} by Wednesday.
                        </StyledTooltip>
                      }
                    >
                      <InfoIcon />
                    </OverlayTrigger>
                    Available{" "}
                    {priceNumber(wallet?.cash_balance || 0, {
                      min: 0,
                      max: 0,
                    })}{" "}
                    Credit
                  </AmountContainer>
                }
                placeholder="0"
                suffix="Credit"
                inputStyle={{ paddingRight: "56px" }}
                errorWarn={!!errors.amount}
                errorMessage={errors.amount?.message}
                onChange={(e) => digitsOnlyChange(e, onChange)}
                maxLength={6}
              />
            )}
          />
          {retire && (
            <StyledTextField
              label="Remark"
              placeholder="เช่น สำหรับงาน Re Golf 2023"
              {...register("remark")}
            />
          )}
        </Content>
      </form>
      <Footer>
        <Button
          onClick={onCancel}
          style={{ marginRight: "8px" }}
          block
          variant="secondary"
        >
          Cancel
        </Button>
        <Button type="submit" form="withdraw" block>
          {!retire ? "Withdraw" : "Retire"}
        </Button>
      </Footer>
    </StyledModal>
  );
};

export default CreditWithdraw;
