import { HTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import { Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "assets/CCXT.svg";
import { ReactComponent as LockKey } from "assets/CarbonCredit-SVG/LockKey-sidebar.svg";
import { ReactComponent as LockKeyActivated } from "assets/CarbonCredit-SVG/LockKey-Activated.svg";
import { ReactComponent as User } from "assets/CarbonCredit-SVG/User-sidebar.svg";
import { ReactComponent as UserActivated } from "assets/CarbonCredit-SVG/User-Activated.svg";
import { ReactComponent as UserPlus } from "assets/CarbonCredit-SVG/UserPlus.svg";
import { ReactComponent as UserPlusActivated } from "assets/CarbonCredit-SVG/UserPlus-Activated.svg";
import { ReactComponent as Receipt } from "assets/CarbonCredit-SVG/Receipt.svg";
import { ReactComponent as ReceiptActivated } from "assets/CarbonCredit-SVG/Receipt-Activated.svg";
import { ReactComponent as Users } from "assets/CarbonCredit-SVG/Users.svg";
import { ReactComponent as UsersActivated } from "assets/CarbonCredit-SVG/Users-Activated.svg";
import { ReactComponent as FileText } from "assets/CarbonCredit-SVG/FileText.svg";
import { ReactComponent as FileTextActivated } from "assets/CarbonCredit-SVG/FileText-Activated.svg";
import { ReactComponent as BanknoteAdmin } from "assets/CarbonCredit-SVG/BanknoteAdmin.svg";
import { ReactComponent as BanknoteAdminActivated } from "assets/CarbonCredit-SVG/BanknoteAdmin-Activated.svg";
import { IconButton } from "components";

import { mediaQuery } from "utils/mediaQueries";
import { config, isKycOnly } from "config";

const { path } = config;

const Logo = styled(IconButton)`
  justify-content: center;
  display: none;
  margin: 3rem auto 2.5rem auto;
  &.small {
    display: block;
    ${mediaQuery("desktop")} {
      display: none;
    }
  }
  ${mediaQuery("desktop")} {
    display: block;
  }
  > img {
    width: auto;
    height: auto;
  }
`;

const Sidebar = styled.div`
  z-index: ${(props) => props.theme.sidebarZIndex};
  width: fit-content;
  min-width: fit-content;
  min-height: 100vh;
  background-color: ${(props) => props.theme.sidebarBackgroundColor};
  position: sticky;
  top: 0;
  left: 0;
  ${mediaQuery("desktop")} {
    min-width: 14.5rem;
    width: 14.5rem;
  }
`;

const NavBar = styled(Nav)`
  width: 100%;
  display: block;
  text-align: center;
  border: none;
`;
const Group = styled.div`
  position: relative;
`;
const Label = styled.p`
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
`;

const StyledNavLink = styled(NavLink)`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 0.625rem;
  padding: 1rem 1rem 1rem 1.5rem;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
  color: ${(props) => props.theme.darkgray};
  &:hover {
    color: ${(props) => props.theme.textColorLight};
  }
  &.active {
    color: ${(props) => props.theme.textColorLight};
    background-color: rgba(226, 231, 240, 0.2);
  }
  & > p {
    display: none;
  }
  ${mediaQuery("desktop")} {
    & > p {
      display: block;
    }
  }
`;

const pageList = isKycOnly
  ? [
      {
        path: path.superAdminManageApp,
        label: "Manage Application",
        ActiveLogo: FileTextActivated,
        InactiveLogo: FileText,
      },
    ]
  : [
      {
        path: path.superAdminManageApp,
        label: "Manage Application",
        ActiveLogo: FileTextActivated,
        InactiveLogo: FileText,
      },
      {
        path: path.superAdminManageTrader,
        label: "Manage Trader Account",
        ActiveLogo: UsersActivated,
        InactiveLogo: Users,
      },
      {
        path: path.superAdminTransactionReq,
        label: "Transaction Request",
        ActiveLogo: BanknoteAdminActivated,
        InactiveLogo: BanknoteAdmin,
      },
      {
        path: path.superAdminManageAdmin,
        label: "Manage Admin Account",
        ActiveLogo: UserPlusActivated,
        InactiveLogo: UserPlus,
      },
      {
        path: path.superAdminReport,
        label: "Export Report",
        ActiveLogo: ReceiptActivated,
        InactiveLogo: Receipt,
      },
      {
        path: path.adminAccInfo,
        label: "Account Information",
        ActiveLogo: UserActivated,
        InactiveLogo: User,
      },
      {
        path: path.adminSecurity,
        label: "Security",
        ActiveLogo: LockKeyActivated,
        InactiveLogo: LockKey,
      },
    ];

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SideBarSuperAdmin = ({ ...rest }: Props) => {
  const navigate = useNavigate();
  return (
    <Sidebar {...rest}>
      <Logo onClick={() => navigate(path.adminBase)} iconSrc={logo} />
      <Logo
        onClick={() => navigate(path.adminBase)}
        className="small"
        iconSrc={logo}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavBar variant="tabs">
          {pageList?.map(({ path, label, ActiveLogo, InactiveLogo }) => (
            <Nav.Item>
              <Group>
                <StyledNavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={path}
                  key={path}
                >
                  {({ isActive }) => (
                    <>
                      {isActive ? <ActiveLogo /> : <InactiveLogo />}
                      <Label>{label}</Label>
                    </>
                  )}
                </StyledNavLink>
              </Group>
            </Nav.Item>
          ))}
        </NavBar>
      </div>
    </Sidebar>
  );
};

export default SideBarSuperAdmin;
