import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled/macro";
import { Spinner } from "react-bootstrap";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/CarbonCredit-SVG/ArrowRight.svg";
import { ReactComponent as ArrowBlockedLeft } from "assets/CarbonCredit-SVG/ArrowBlockedLeft.svg";
import { ReactComponent as Sort } from "assets/CarbonCredit-SVG/Sort.svg";
import { ReactComponent as SortAsc } from "assets/CarbonCredit-SVG/SortAsc.svg";
import { ReactComponent as SortDesc } from "assets/CarbonCredit-SVG/SortDesc.svg";
import { ReactComponent as ArrowDown } from "assets/CarbonCredit-SVG/ShowMenu.svg";
import { DataValue } from "models/generic";
import { IconButton } from "../Buttons";
import { OutlineSelect } from "../OutlineSelect";
import { CollapseContent } from "../Collapse/styles";
import { Skeleton } from "../Skeleton";

export interface Key<T> {
  label: string; // header label
  name: keyof T; // data name
  sortable?: boolean;
  headerStyle?: CSSProperties; // style for th
  style?: CSSProperties; // style for td
  customRenderer?: (data: DataValue<T>, rowData: T) => ReactNode; // custom td render
}

interface ITableRow<T> {
  rowData: T;
  onRowClick?: (rowData: T) => void;
  keys?: Array<Key<T>>;
  actions?: (rowData: T) => ReactNode;
  collapseable?: boolean;
  renderCollapseContent?: () => ReactNode;
  rowPrimaryKey?: string;
  rowHoverable?: boolean;
}

interface ITablePagination {
  currentPage?: number;
  totalPages?: number;
  show?: number;
  showPerPageOptions?: number[];
  onPageChange?: (page: number) => void;
  onShowChange?: (show: string) => void;
  showFirstLastButtons?: boolean;
}

export interface ITable<T>
  extends HTMLAttributes<HTMLTableElement>,
    Omit<ITableRow<T>, "rowData" | "renderCollapseContent">,
    ITablePagination {
  data?: T[];
  noDataMessage?: string;
  currentPage?: number;
  totalPages?: number;
  showPagination?: boolean;
  filters?: ReactNode;
  onHeaderClick?: (name: keyof T) => void;
  sortingBy?: Record<keyof T, "asc" | "desc">;
  paginationStyle?: CSSProperties;
  renderCollapseContent?: (rowData: T, index: number, data: T[]) => ReactNode;
  containerStyle?: CSSProperties;
  loading?: boolean;
  loadingAsSkeleton?: boolean;
  minSkeletonRows?: number;
  tbodyTestId?: string;
}

const TablePaginationContainer = styled.div`
  display: flex;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  .select-container {
    padding-bottom: 0;
  }
  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const ShowContainer = styled(Flex)`
  margin-right: 16px;
  color: ${(props) => props.theme.white4};
`;

const Paginate = styled(Flex)``;

const PageDisplay = styled.div`
  white-space: nowrap;
  color: ${(props) => props.theme.white4};
`;

const ArrowIcon = styled(IconButton)`
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s;
  svg {
    display: block;
    stroke: ${(props) => props.theme.white4};
  }
  &:hover {
    background-color: ${(props) => props.theme.gray1};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    svg {
      stroke: ${(props) => props.theme.textColorDisabled};
    }
  }
`;

const ArrowBlockedRight = styled(ArrowBlockedLeft)`
  transform: rotate(180deg);
`;

const StyledOutlineSelect = styled(OutlineSelect)`
  color: ${(props) => props.theme.white4};
  background-color: ${(props) => props.theme.baseBackgroundColor};
  &:focus,
  &:focus-within {
    border-color: transparent;
  }
`;

const TablePagination = ({
  currentPage = 1,
  totalPages = 1,
  show = 10,
  showPerPageOptions = [10, 20],
  onPageChange,
  onShowChange,
  showFirstLastButtons = true,
}: ITablePagination) => {
  const handlePageChange = (side: number) => {
    if (currentPage + side < 0 || currentPage + side > totalPages) return;
    onPageChange && onPageChange(currentPage + side);
  };

  const total = totalPages <= 0 ? 1 : totalPages;
  return (
    <TablePaginationContainer>
      <ShowContainer>
        <div>Rows per page</div>
        <StyledOutlineSelect
          value={show}
          style={{ width: "80px", minWidth: "80px" }}
          onChange={(e) => onShowChange && onShowChange(e.target.value)}
        >
          {showPerPageOptions.map((opt) => (
            <option value={opt} key={`${opt}`}>
              {opt}
            </option>
          ))}
        </StyledOutlineSelect>
      </ShowContainer>
      <Paginate>
        <PageDisplay data-test-id="page-display__text">
          {currentPage} of {total}
        </PageDisplay>
        <Flex>
          {showFirstLastButtons && (
            <ArrowIcon
              icon={<ArrowBlockedLeft />}
              disabled={currentPage <= 1}
              onClick={() => onPageChange && onPageChange(1)}
              data-test-id="first-page__button"
            />
          )}
          <ArrowIcon
            disabled={currentPage <= 1}
            icon={<ArrowLeft />}
            onClick={() => handlePageChange(-1)}
            data-test-id="previous-page__button"
          />
          <ArrowIcon
            disabled={currentPage >= total}
            icon={<ArrowRight />}
            onClick={() => handlePageChange(1)}
            data-test-id="next-page__button"
          />
          {showFirstLastButtons && (
            <ArrowIcon
              icon={<ArrowBlockedRight />}
              disabled={currentPage >= total}
              onClick={() => onPageChange && onPageChange(total)}
              data-test-id="last-page__button"
            />
          )}
        </Flex>
      </Paginate>
    </TablePaginationContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
  min-width: fit-content;
  overflow: auto;
`;

const SortHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const Footer = styled(SortHeader)`
  margin: 16px 0 0;
`;

const Left = styled.div`
  display: flex;
  /* width: 100%; */
  margin-right: 8px;
  align-items: center;
  flex: 1;
  gap: 8px;
  > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  label {
    white-space: nowrap;
    color: ${(props) => props.theme.darkgray200};
  }
`;

const Right = styled.div`
  margin-left: auto;
`;

const Tbl = styled.table`
  width: 100%;
`;

const THead = styled.thead``;

const TBody = styled.tbody``;

const Tr = styled.tr<{ hoverable?: boolean }>`
  > td {
    padding: 16px 8px;
  }
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  transition: 0.3s background-color;
  &:hover {
    background-color: ${(props) =>
      props.hoverable ? "#FFFFFF1A" : "transparent"};
  }
`;

const Th = styled.th`
  padding: 16px 8px;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
`;

const Td = styled.td`
  font-size: 0.875rem;
`;

const StyledSortIcon = styled(Sort)`
  fill: ${(props) => props.theme.darkgray};
  margin: 0 4px;
`;

const StyledSortAsc = styled(SortAsc)`
  fill: ${(props) => props.theme.textColor};
  margin: 0 4px;
`;
const StyledSortDesc = styled(SortDesc)`
  fill: ${(props) => props.theme.textColor};
  margin: 0 4px;
`;

const ArrowDownIcon = styled(ArrowDown)<{ collapsed: boolean }>`
  transform: rotate(${(props) => (props.collapsed ? 0 : 180)}deg);
  transition: transform 0.3s;
`;

const TableRow = <T,>({
  rowData,
  onRowClick,
  keys,
  actions,
  collapseable,
  renderCollapseContent,
  rowPrimaryKey,
  rowHoverable,
}: ITableRow<T>) => {
  const [collapse, setCollapse] = useState(true);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    contentRef.current?.style.setProperty(
      "--max-height",
      `${contentRef.current.scrollHeight}px`
    );
  }, [collapse]);

  const handleRowClick = () => {
    onRowClick && onRowClick(rowData);
    if (collapseable) {
      setCollapse(!collapse);
    }
  };

  return (
    <>
      <Tr
        key={
          rowPrimaryKey ? `${rowData[rowPrimaryKey as keyof T]}` : `${rowData}`
        }
        onClick={handleRowClick}
        hoverable={rowHoverable}
      >
        {keys?.map((key) => (
          <Td
            style={key.style}
            key={
              rowPrimaryKey
                ? `${rowData[rowPrimaryKey as keyof T]}-${String(key.name)}`
                : `${rowData}-${String(key.name)}`
            }
          >
            {key.customRenderer
              ? key.customRenderer(rowData[key.name], rowData)
              : rowData[key.name]}
          </Td>
        ))}
        {actions && (
          <Td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
            {actions(rowData)}
          </Td>
        )}
        {collapseable && (
          <Td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
            <IconButton icon={<ArrowDownIcon collapsed={collapse} />} />
          </Td>
        )}
      </Tr>
      {collapseable && (
        <Tr
          key={
            rowPrimaryKey
              ? `${rowData[rowPrimaryKey as keyof T]}-collapse`
              : `${rowData}-collapse`
          }
          hoverable={rowHoverable}
        >
          <Td colSpan={(keys?.length || 0) + 1} style={{ padding: 0 }}>
            <CollapseContent ref={contentRef} active={!collapse}>
              {renderCollapseContent && !collapse && renderCollapseContent()}
            </CollapseContent>
          </Td>
        </Tr>
      )}
    </>
  );
};

const SkeletonTableRow = <T,>({
  keys,
  actions,
  index,
}: Partial<ITableRow<T>> & {
  index: number;
}) => (
  <Tr key={`skeleton-${index}`}>
    {keys?.map((key) => (
      <Td style={key.style} key={`skeleton-${index}-${String(key.name)}`}>
        <Skeleton dark />
      </Td>
    ))}
    {actions && (
      <Td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
        <Skeleton
          dark
          style={{
            minWidth: "3rem",
          }}
        />
      </Td>
    )}
  </Tr>
);

const Table = <T,>({
  data,
  noDataMessage,
  keys,
  currentPage,
  totalPages,
  showPagination = true,
  filters,
  onRowClick,
  onHeaderClick,
  actions,
  sortingBy,
  paginationStyle,
  collapseable,
  renderCollapseContent,
  containerStyle,
  rowPrimaryKey = "id",
  show,
  showPerPageOptions,
  onPageChange,
  onShowChange,
  loading,
  loadingAsSkeleton = false,
  minSkeletonRows = 10,
  tbodyTestId,
  rowHoverable = false,
  ...rest
}: ITable<T>) => (
  <TableContainer style={containerStyle}>
    {filters && <SortHeader>{filters && <Left>{filters}</Left>}</SortHeader>}
    <Tbl {...rest}>
      <THead>
        <Tr>
          {keys?.map((header) => (
            <Th
              onClick={() => onHeaderClick && onHeaderClick(header.name)}
              style={{
                cursor: header.sortable ? "pointer" : "unset",
                ...header.headerStyle,
              }}
              title={header.label}
              key={header.name.toString()}
            >
              {header.label}
              {header.sortable &&
                (sortingBy && sortingBy[header.name] ? (
                  sortingBy[header.name] === "asc" ? (
                    <StyledSortAsc />
                  ) : (
                    <StyledSortDesc />
                  )
                ) : (
                  <StyledSortIcon />
                ))}
            </Th>
          ))}
        </Tr>
      </THead>
      <TBody data-test-id={tbodyTestId}>
        {loading && !loadingAsSkeleton && (
          <Tr key="loading">
            <Td colSpan={100}>
              <Spinner
                style={{ display: "flex", margin: "0 auto" }}
                animation="border"
                variant="info"
              />
            </Td>
          </Tr>
        )}
        {loading && loadingAsSkeleton && (
          <>
            {Array.from(Array(minSkeletonRows).keys()).map((idx) => (
              <SkeletonTableRow keys={keys} actions={actions} index={idx} />
            ))}
          </>
        )}
        {!loading &&
          (data?.length && data?.length > 0 ? (
            data?.map((rowData, idx) => (
              <TableRow
                rowData={rowData}
                onRowClick={onRowClick}
                keys={keys}
                actions={actions}
                collapseable={collapseable}
                renderCollapseContent={() =>
                  renderCollapseContent &&
                  renderCollapseContent(rowData, idx, data)
                }
                rowPrimaryKey={rowPrimaryKey}
                key={
                  rowPrimaryKey
                    ? `${rowData[rowPrimaryKey as keyof T]}`
                    : `${rowData}`
                }
                rowHoverable={rowHoverable}
              />
            ))
          ) : (
            <Tr key="no-message">
              <Td colSpan={100} style={{ textAlign: "center" }}>
                {noDataMessage}
              </Td>
            </Tr>
          ))}
      </TBody>
    </Tbl>
    <Footer>
      {showPagination && (
        <Right style={paginationStyle}>
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            show={show}
            showPerPageOptions={showPerPageOptions}
            onPageChange={onPageChange}
            onShowChange={onShowChange}
          />
        </Right>
      )}
    </Footer>
  </TableContainer>
);

export default Table;
