import styled from "@emotion/styled/macro";
import { Checkbox } from "components/Checkbox";
import { Popover, PopoverProps } from "react-bootstrap";

export interface IPasswordPopover extends PopoverProps {
  password?: string;
}

const StyledPopover = styled(Popover)`
  background-color: ${(props) => props.theme.gray3};
  border: none;
  z-index: ${(props) =>
    +props.theme.modalZIndex + 1}; // z-index is higher than modal
  .popover-arrow {
    &::before {
      border-right-color: transparent;
    }
  }
  &.bs-popover-auto > .popover-arrow {
    &::after {
      border-right-color: ${(props) => props.theme.gray3};
    }
  }
  .popover-arrow {
    &::after {
      border-right-color: ${(props) => props.theme.gray3};
    }
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: ${(props) => props.theme.textColor};
`;

const StyledCheckbox = styled(Checkbox)`
  > .checkmark {
    padding: 8px;
  }
`;

const upperCaseRegex = /(.*[A-Z].*)/;
const lowerCaseRegex = /(.*[a-z].*)/;
const oneNumberRegex = /(.*\d.*)/;
const specialCharRegex = /[~!@#%^&$;*_\-+=`|\\(){}\\[\]:;'"<>,.?/]+/;

const PasswordPopover = ({ password = "", ...rest }: IPasswordPopover) => (
  <StyledPopover id="popover-basic" {...rest}>
    <Popover.Body>
      <Header>
        The password must be at least 8 characters long which contains :
        <StyledCheckbox
          checked={upperCaseRegex.test(password)}
          label="At least 1 uppercase (A-Z)"
          type="box"
          containerClassname=""
        />
        <StyledCheckbox
          checked={lowerCaseRegex.test(password)}
          label="At least 1 lowercase (a-z)"
          type="box"
        />
        <StyledCheckbox
          checked={oneNumberRegex.test(password)}
          label="At least 1 number (0-9)"
          type="box"
        />
        <StyledCheckbox
          checked={specialCharRegex.test(password)}
          label="At least 1 special character (~!@#%^&amp;*_-+=`|\(){}[]:;&quot;'<>,.?/)"
          type="box"
        />
      </Header>
    </Popover.Body>
  </StyledPopover>
);

export default PasswordPopover;
