import { State } from "./types";

export const initState: State = {
  isLoading: false,
  isDirty: false,
  showConfirm: false,
  error: null,
  page: 0,
  clickedPage: 0,
  furthestPage: 0,
  pages: ["agreement", "profile", "companyInfo", "attachments"],
  registered: false,
  tnc: false,
  pin: "",
  brokerId: 2,
  profile: {
    email: "",
    password: "",
    confirm_password: "",
    mobile_number: "",
  },
  companyInfo: {
    name: "",
    juristic_number: "",
    name_of_contact_person: "",
    bank_code: "",
    bank_account_number: "",
  },
  attachments: [],
};
