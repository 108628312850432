import { useMemo, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import styled from "@emotion/styled/macro";
import dayjs from "dayjs";
import useSWR from "swr";
import toast from "react-hot-toast";
import axios from "axios";

import { Button, Card, Status, StatusVariant } from "components";
import { apiFetcher, usePin } from "providers";
import {
  AddNewAccount,
  ConfirmDeleteMembership,
  IAddNewAccountValue,
} from "features/AccountManagement";
import { ReactComponent as Union } from "assets/CarbonCredit-SVG/Union.svg";
import { ReactComponent as DotsThree } from "assets/CarbonCredit-SVG/DotsThree.svg";
import { ReactComponent as Bin } from "assets/CarbonCredit-SVG/Bin.svg";
import { ReactComponent as EmptyBox } from "assets/CarbonCredit-SVG/EmptyBox.svg";

import {
  Registry,
  RegistryListRes,
  RegistryMembership,
  RegistryMembersRes,
} from "models/registry";
import { APIError } from "models/generic";
import * as errorCodes from "models/apiErrorCodes";
import { linkRegistryMember, unlinkRegistryMember } from "api/users";

const MembershipContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const AddAccount = styled(Button)`
  flex-direction: row;
  align-items: center;
  &::after {
    display: none;
  }
`;

const PlusIcon = styled(Union)`
  display: block;
  margin-right: 12px;
  fill: ${(props) => props.theme.textColorLight};
`;

const DropdownMenu = styled(Dropdown.Menu)`
  border-radius: ${(props) => props.theme.borderRadiusBase};
  border: solid 1px #e7eaf1;
  align-items: stretch;
  left: auto;
  right: 0;
  top: 42px;
  padding: 0;
  background-color: ${(props) => props.theme.componentBackgroundColor};
  overflow: hidden;
`;

const Item = styled(Dropdown.Item)`
  padding: 1rem 1.5rem;
  transition: color 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  svg {
    margin-right: 12px;
    path {
      transition: fill 0.3s;
      fill: ${(props) => props.theme.textColor};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.dropdownItemHover};
    color: ${(props) => props.theme.textColor};
    path {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
  &:active,
  &.active {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.textColor};
  }
  &.danger {
    color: ${(props) => props.theme.red2};
    path {
      fill: ${(props) => props.theme.red2};
    }
    &:hover {
      background-color: #f8d8d8;
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin-right: auto;
`;

const Content = styled.div<{ isEmpty: boolean }>(
  `
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`,
  (props) =>
    props.isEmpty && {
      gridTemplateColumns: "1fr",
      placeItems: "center",
      flex: "1",
    }
);

const MemberDetail = styled.div`
  display: flex;
  margin-bottom: 8px;
  > .dropdown > svg {
    fill: ${(props) => props.theme.darkgray200};
    cursor: pointer;
    display: block;
  }
`;

const MemberName = styled.h3`
  font-weight: 800;
  padding-right: 8px;
  margin-right: auto;
`;

const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.lightgray50};
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.darkgray200};
`;

const EmptyContainer = styled.div`
  text-align: center;
`;

const EmptyTitle = styled.h3``;

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 0.3,
  },
};

const CustomToggle = (props: any) => {
  const { loading } = props;
  return (
    <AddAccount size="medium" {...props}>
      {!loading ? (
        <>
          <PlusIcon />
          <div>Add Account</div>
        </>
      ) : (
        <Spinner animation="border" />
      )}
    </AddAccount>
  );
};

type StatusMap = {
  variant: StatusVariant;
  label: string;
};

const statusMap: Record<string, StatusMap> = {
  PENDING: {
    variant: "warning",
    label: "Pending",
  },
  INVALID: {
    variant: "danger",
    label: "Invalid",
  },
  IN_SYNC: {
    variant: "success",
    label: "In-sync",
  },
  SUSPENDED: {
    variant: "disabled",
    label: "Suspended",
  },
  UNLINKED: {
    variant: "disabled",
    label: "Unlinked",
  },
};

const Membership = () => {
  const [showAddAccount, setShowAddAccount] = useState<Registry | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] =
    useState<RegistryMembership | null>(null);
  const [deleteError, setDeleteError] = useState("");

  const { showPin, setPinError, closePin } = usePin();

  const { data: list } = useSWR<RegistryListRes>(
    "/registry/list",
    (url) => apiFetcher({ url }) // todo remove this
  );

  const { data: my, mutate: refetchMy } = useSWR<RegistryMembersRes>(
    "/registry/my",
    (url) => apiFetcher({ url }) // todo remove this
  );

  const onConfirmDelete = async (
    account: RegistryMembership | null,
    pin: string
  ) => {
    if (account) {
      try {
        await unlinkRegistryMember(pin, account);
        setShowConfirmDelete(null);
        refetchMy();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          switch (error?.code) {
            case errorCodes.InvalidPin:
              toast.error("Incorrect PIN. Try again.");
              setShowConfirmDelete(null);
              break;
            default:
              toast.error("Something went wrong. Please try again later.");
              setShowConfirmDelete(null);
              setDeleteError(err.message);
              break;
          }
        }
      }
    }
  };

  const CustomDropdownToggle = (props: any) =>
    useMemo(
      () => <CustomToggle {...props} loading={!list?.data} />,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props, list]
    );

  const handleClosePin = () => {
    closePin();
  };

  const isRegistryError = (code: number) =>
    // check for 10xx or 11xx
    code >= 1000 && code < 1200;

  const handleConfirmPin = async (
    pin: string,
    addingAccount: IAddNewAccountValue
  ) => {
    if (addingAccount) {
      try {
        await linkRegistryMember(pin, addingAccount);
        closePin();
        refetchMy();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          // check for registry error
          if (
            error?.code >= errorCodes.RegistryError &&
            error?.code < errorCodes.RegistryMemberError + 100
          ) {
            toast.error(error.message);
            closePin();
            return;
          }
          switch (error?.code) {
            case errorCodes.InvalidPin:
              toast.error("Incorrect PIN. Try again.");
              setPinError({
                isError: true,
                errorMessage: err.message,
              });
              break;
            default:
              toast.error("Something went wrong. Please try again later.");
              closePin();
              break;
          }
        }
      }
    }
  };

  const onAddAccount = (val: IAddNewAccountValue) => {
    showPin({
      show: true,
      onClose: handleClosePin,
      onConfirm: (pin) => handleConfirmPin(pin, val),
    });
    setShowAddAccount(null);
  };

  return (
    <MembershipContainer>
      <AddNewAccount
        type={showAddAccount}
        show={!!showAddAccount}
        onCancel={() => setShowAddAccount(null)}
        onConfirm={onAddAccount} // todo change this
      />
      <ConfirmDeleteMembership
        show={!!showConfirmDelete}
        account={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(null)}
        onConfirm={onConfirmDelete} // todo change this
        isError={!!deleteError}
        errorMessage={deleteError}
        clearError={() => setDeleteError("")}
        registries={list?.data || []}
      />
      <TitleBar>
        <Title>Registry Membership</Title>
        <Dropdown>
          <Dropdown.Toggle as={CustomDropdownToggle} disabled={!list?.data} />
          <DropdownMenu>
            {list?.data &&
              list.data.map((item) => (
                <Item key={item.id} onClick={() => setShowAddAccount(item)}>
                  <div>{item.abbreviation}</div>
                </Item>
              ))}
          </DropdownMenu>
        </Dropdown>
      </TitleBar>
      <Content isEmpty={(my?.data?.memberships?.length || 0) <= 0}>
        {(my?.data?.memberships?.length || 0) > 0 ? (
          my?.data?.memberships.map((account) => (
            <Card {...animation}>
              <MemberDetail>
                <MemberName>
                  {
                    list?.data.find(
                      (registry) => registry.id === account.registry_id
                    )?.name
                  }
                </MemberName>
                <Dropdown>
                  <Dropdown.Toggle as={DotsThree} />
                  <DropdownMenu>
                    <Item
                      className="danger"
                      onClick={() => setShowConfirmDelete(account)}
                    >
                      <Bin />
                      <div>Unlink Account</div>
                    </Item>
                  </DropdownMenu>
                </Dropdown>
              </MemberDetail>
              <Line>
                <Label>Account ID</Label>
                <div>{account.account_id}</div>
              </Line>
              {account.verification_code ? (
                <Line>
                  <Label>Verification code</Label>
                  <div>{account.verification_code}</div>
                </Line>
              ) : (
                <Line>
                  <Label>Account Name</Label>
                  <div>{account.account_name}</div>
                </Line>
              )}
              <Line>
                <Label>Updated Date</Label>
                <div>
                  {dayjs(account.updated_at).format("DD MMM YYYY HH:mm")}
                </div>
              </Line>
              <Line>
                <Label>Status</Label>
                <Status variant={statusMap[account.status].variant}>
                  {statusMap[account.status].label}
                </Status>
              </Line>
            </Card>
          ))
        ) : (
          <EmptyContainer>
            {my?.data.memberships ? (
              <>
                <EmptyBox />
                <EmptyTitle>You have no any registry membership.</EmptyTitle>
                <div>Click “+ Add Account”</div>
              </>
            ) : (
              <Spinner animation="border" />
            )}
          </EmptyContainer>
        )}
      </Content>
    </MembershipContainer>
  );
};

export default Membership;
