import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { motion } from "framer-motion";
import { ITabLi, Modes } from "./interfaces";

export const TabContainer = styled.div<Modes>`
  display: flex;
  flex-direction: ${(props) => (props.mode === "vertical" ? "row" : "column")};
`;

export const TabUlContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #252525;
`;

export const TabUl = styled(motion.ul)<Modes>(
  (props) => css`
    transition: 0.3s all;
    list-style-type: none;
    margin: 0;
    padding: 300px 0;
    width: 20vw;
    min-width: 200px;
    max-width: 200px;
    height: 100vh;
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    border-right: solid ${props.theme.inputBorderColor} 0.5px;
  `,
  (props) =>
    (props.mode === "horizontal" || props.mode === "horizontal-box") &&
    css`
      padding: 0;
      width: 100%;
      max-width: 100%;
      /* margin: 0 0 1rem; */
      height: auto;
      position: relative;
      display: flex;
      border: none;
      flex-flow: row wrap;
    `,
  (props) =>
    props.mode === "horizontal-pill" &&
    css`
      padding: 4px;
      min-width: fit-content;
      width: 100%;
      max-width: 100%;
      margin: 0 0 1rem;
      height: auto;
      position: relative;
      display: flex;
      border-radius: 8px;
      flex-flow: row wrap;
      border: 1px solid ${props.theme.gray1};
    `
);

export const AfterUl = styled.div`
  margin: 0 0 1rem;
`;

export const TabLi = styled.li<ITabLi>(
  (props) => css`
    position: relative;
    display: block;
    margin: 0;
    padding: 20px 0;
    white-space: nowrap;
    cursor: ${props.disabled ? "default" : "pointer"};
    transition: color 0.3s, border-color 0.3s, background-color 0.3s,
      padding 0.15s, font-size 0.3s;
    text-align: center;
    color: ${props.theme.textColorDisabled};
    &.is-active {
      /* font-size: 1.5rem; */
      color: ${props.theme.primaryColor};
      background-color: #e6f7ff;
      &::after {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s, opacity 0.15s;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 3px solid ${props.theme.primaryColor};
      -webkit-transform: scaleY(0.0001);
      -ms-transform: scaleY(0.0001);
      transform: scaleY(0.0001);
      opacity: 0;
      transition: transform 0.15s, opacity 0.15s;
      content: "";
    }
  `,
  (props) =>
    props.mode === "horizontal" &&
    css`
      width: max-content;
      /* padding: 4px 0;
      &:not(&:last-of-type) {
        padding-right: 24px;
      } */
      padding-top: 12px;
      padding-bottom: 16px;
      &.is-active {
        color: ${props.theme.primaryColor};
        background-color: ${props.theme.baseBackgroundColor};
      }
      &::after {
        display: none;
      }
      align-self: baseline;
    `,
  (props) =>
    props.mode === "horizontal-box" &&
    css`
      width: max-content;
      padding: 12px;
      color: ${props.disabled
        ? props.theme.gray
        : props.theme.textColorDisabled};
      border: ${`1px solid ${props.theme.gray}`};
      border-radius: ${props.theme.borderRadiusBase};
      background-color: ${props.disabled
        ? props.theme.inputDisableBackground
        : props.theme.componentBackgroundColor};
      &:not(&:last-of-type) {
        margin-right: 16px;
      }
      &.is-active {
        color: ${props.theme.textColorLight};
        background-color: ${props.theme.primaryColor};
        border-color: ${props.theme.primaryColor};
      }
      &::after {
        display: none;
      }
    `,
  (props) =>
    props.mode === "horizontal-pill" &&
    css`
      font-size: 0.875rem;
      width: inherit;
      max-width: 100%;
      padding: 6px 0;
      border-radius: 4px;
      flex: 1 0;
      color: ${props.theme.textColor};
      &.is-active {
        font-weight: 600;
        color: ${props.theme.primaryColor};
        background-color: #1c1c1c;
      }
      &::after {
        display: none;
      }
    `
);

export const TabContentContainer = styled(motion.div)`
  opacity: 0;
  overflow: hidden;
`;

export const Underline = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.primaryColor};
`;
