import {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import styled from "@emotion/styled/macro";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import {
  Button,
  TextField,
  Table,
  Tab,
  TabsBar,
  Key,
  Status,
  StatusVariant,
} from "components";
import { ISymbolDetailTypes, SymbolDetail } from "features/shared/SymbolDetail";
import { mediaQuery, percentNumber, priceNumber } from "utils";
import { useOrder } from "providers";
import { APIError } from "models/generic";
import {
  MarketItem,
  MarketRes,
  BestOfferItem,
  BestOfferRes,
  TopValueRes,
  TopValueItem,
  TopVolumeItem,
  TopVolumeRes,
} from "models/WebsocketClients";
import { useDebounce, useDebounceValue, usePagination } from "hooks";

import { config } from "config";
import { GetMarketFiltersListRes } from "models/market";
import { MarketOverviewCard } from "features/Market";
import { MarketFilter } from "features/Market/Filter";

const { path } = config;

const StyledTabsBar = styled(TabsBar)`
  li.is-active {
    color: ${(props) => props.theme.textColor};
  }
`;

const MarketsContainer = styled.div`
  padding: 65px 0px 0px;
  background-color: ${(props) => props.theme.baseBackgroundColor};
  min-height: 100vh;
  th,
  label {
    white-space: nowrap;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Desc = styled.span`
  margin-left: 8px;
  color: ${(props) => props.theme.white4};
  font-size: 1rem;
  font-weight: 500;
`;

const TabHeader = styled.h2`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0 16px;
`;

const MarketsContent = styled.div`
  padding: 40px 24px 24px;
  ${mediaQuery("desktop")} {
    padding: 40px;
  }
`;

const MarketOverviewGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  ${mediaQuery("tablet")} {
    flex-direction: row;
  }
`;

const StyledStatus = styled(Status)`
  font-size: 0.875rem;
`;

const SearchField = styled(TextField)`
  padding: 12px;
`;

const getPriceSide = (change: number) => {
  if (change > 0) return "success";
  if (change < 0) return "danger";
  return "";
};

const tdStyles = {
  fontSize: "0.875rem",
  fontWeight: 400,
} as CSSProperties;

const thStyles = {
  fontSize: "0.875rem",
  color: "#FFFFFF66",
} as CSSProperties;

const keys: Key<MarketItem>[] = [
  {
    label: "Symbol",
    name: "pair_symbol",
    sortable: true,
    style: {
      ...tdStyles,
      display: "flex",
      alignItems: "center",
    },
    headerStyle: thStyles,
    customRenderer: (data, rowData) => (
      <Button
        as="link"
        variant="link-secondary"
        to={`${path.tradeBase}/${data}`}
        style={{
          padding: 0,
        }}
      >
        {/* <StarIcon className={rowData.is_favorite ? "active" : ""} /> */}
        {data.toString().split("_")[0]}
      </Button>
    ),
  },
  {
    label: "Last",
    name: "last_price",
    sortable: true,
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data, rowData) => (
      <StyledStatus
        normal
        variant={getPriceSide(rowData.percent_change) as StatusVariant}
      >
        {priceNumber(data as number, {
          currency: "THB",
        })}
      </StyledStatus>
    ),
  },
  {
    label: "Chg",
    name: "change",
    sortable: true,
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) => (
      <StyledStatus normal variant={getPriceSide(+data) as StatusVariant}>
        {priceNumber(data as number, {
          currency: "THB",
          signDisplay: "exceptZero",
        })}
      </StyledStatus>
    ),
  },
  {
    label: "% Chg",
    name: "percent_change",
    sortable: true,
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) => (
      <StyledStatus normal variant={getPriceSide(+data) as StatusVariant}>
        {percentNumber(data as number, {
          signDisplay: "exceptZero",
        })}
      </StyledStatus>
    ),
  },
  {
    label: "Buy Vol",
    name: "buy_volume",
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Buy Price",
    name: "buy_price",
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Sell Price",
    name: "sell_price",
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Sell Vol",
    name: "sell_volume",
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Close Price",
    name: "close_price",
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Total Vol",
    name: "total_volume",
    sortable: true,
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Total Val",
    name: "total_value",
    sortable: true,
    style: {
      ...tdStyles,
      textAlign: "right" as const,
    },
    headerStyle: {
      ...thStyles,
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
];

const defaultFilterState = {
  marketName: "carbon-credit",
  search: "",
  projectType: "",
  standard: "",
  isFavorite: true,
};

const Markets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showSymbolDetail, setShowSymbolDetail] = useState({
    symbol: "",
    type: "carbon",
  });
  const [filterState, setFilterState] = useState(defaultFilterState);
  const debouncedFilterState = useDebounceValue(filterState, 500);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<MarketItem[]>([]);
  const [marketTotal, setMarketTotal] = useState(0);
  const { pagination, setPage, setLimit, showPerPageOptions, totalPages } =
    usePagination(marketTotal);
  const debounceSetPage = useDebounce(setPage, 500, [
    setPage,
    debouncedFilterState.search,
  ]);

  const {
    state: { market, bestOffer, topValue, topVolume },
  } = useOrder();

  const { data: marketOptions } =
    useSWRImmutable<GetMarketFiltersListRes>("/market/filters");

  const handleData = (data: MarketRes) => {
    const tableData = data.market;
    setTableData(tableData);
    setMarketTotal(data.total);
    setLoading(false);
  };

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in market: ", error);
    },
    [navigate]
  );

  useLayoutEffect(() => {
    if (market && !subscribed) {
      const { marketName, search, projectType, standard, isFavorite } =
        debouncedFilterState;
      setSubscribed(true);
      const debug = searchParams.get("debug") === "true";
      market.subscribe({
        market_name: debug ? searchParams.get("market") : marketName,
        search,
        project_type: projectType,
        standard,
        limit: pagination.limit,
        page: pagination.page,
        is_favorite: isFavorite,
      });
      market.onData = handleData;
      market.onError = handleError;
      console.log("subscribed");
    }
    return () => {
      if (market && subscribed) {
        market.unsubscribe();
        setSubscribed(false);
        console.log("unsubscribed");
      }
    };
  }, [
    debouncedFilterState,
    handleError,
    market,
    pagination.page,
    pagination.limit,
    subscribed,
    searchParams,
  ]);

  // Best offer
  const [bestOfferData, setBestOfferData] = useState<BestOfferItem[]>([]);

  const handleBestOfferData = (data: BestOfferRes) => {
    setBestOfferData(data.best_offer);
  };

  useEffect(() => {
    if (bestOffer) {
      const { marketName } = debouncedFilterState;
      const debug = searchParams.get("debug") === "true";

      bestOffer.subscribe({
        market_name: debug ? searchParams.get("market") : marketName,
        limit: 3,
      });
      bestOffer.onData = handleBestOfferData;
      bestOffer.onError = handleError;
    }
    return () => {
      if (bestOffer) {
        bestOffer.unsubscribe();
      }
    };
  }, [bestOffer, debouncedFilterState, searchParams, handleError]);

  // Top value
  const [topValueData, setTopValueData] = useState<TopValueItem[]>([]);

  const handleTopValueData = (data: TopValueRes) => {
    setTopValueData(data.top_value);
  };

  useEffect(() => {
    if (topValue) {
      const { marketName } = debouncedFilterState;
      const debug = searchParams.get("debug") === "true";

      topValue.subscribe({
        market_name: debug ? searchParams.get("market") : marketName,
        limit: 3,
      });
      topValue.onData = handleTopValueData;
      topValue.onError = handleError;
    }
    return () => {
      if (topValue) {
        topValue.unsubscribe();
      }
    };
  }, [topValue, debouncedFilterState, searchParams, handleError]);

  // Top volume
  const [topVolumeData, setTopVolumeData] = useState<TopVolumeItem[]>([]);
  const handleTopVolumeData = (data: TopVolumeRes) => {
    setTopVolumeData(data.top_volume);
  };

  useEffect(() => {
    if (topVolume) {
      const { marketName } = debouncedFilterState;
      const debug = searchParams.get("debug") === "true";

      topVolume.subscribe({
        market_name: debug ? searchParams.get("market") : marketName,
        limit: 3,
      });
      topVolume.onData = handleTopVolumeData;
      topVolume.onError = handleError;
    }
    return () => {
      if (topVolume) {
        topVolume.unsubscribe();
      }
    };
  }, [topVolume, debouncedFilterState, searchParams, handleError]);

  const favoriteActions = useCallback(
    (data: MarketItem, type?: "carbon" | "rec" | "re") => (
      <>
        {/* <Button
          style={{ marginRight: "16px" }}
          variant="link"
          onClick={() =>
            setShowSymbolDetail({
              symbol: data.pair_symbol,
              type: type || "carbon",
            })
          }
        >
          Detail
        </Button> */}
        <Button
          variant="link"
          as="link"
          to={`${path.tradeBase}/${data.pair_symbol}`}
        >
          Trade
        </Button>
      </>
    ),
    []
  );

  const filter = useMemo(
    () => (
      <>
        <SearchField
          containerStyle={{
            maxWidth: "240px",
            paddingBottom: 0,
          }}
          style={{ height: "28px" }}
          placeholder="Search Name"
          onChange={(e) => {
            setFilterState((state) => ({
              ...state,
              search: e.target.value,
            }));
            debounceSetPage(1);
          }}
        />
        <MarketFilter
          marketOptions={marketOptions}
          defaultValues={{
            standard: filterState.standard,
            projectType: filterState.projectType,
          }}
          onApply={(values) => {
            setFilterState((state) => ({
              ...state,
              standard: values.standard,
              projectType: values.projectType,
            }));
            debounceSetPage(1);
          }}
        />
      </>
    ),
    [
      debounceSetPage,
      filterState.projectType,
      filterState.standard,
      marketOptions,
    ]
  );

  const tableProps = useCallback(
    (type?: "carbon" | "rec" | "re") => ({
      filters: filter,
      actions: (data: MarketItem) => favoriteActions(data, type),
      keys,
      data: tableData,
      noDataMessage: "No data",
      rowPrimaryKey: "pair_id",
      loading,
      tbodyTestId: "carbon-symbol__table",
    }),
    [favoriteActions, filter, loading, tableData]
  );

  return (
    <MarketsContainer>
      <SymbolDetail
        showSymbol={showSymbolDetail.symbol}
        onCancel={() =>
          setShowSymbolDetail({
            symbol: "",
            type: "carbon",
          })
        }
        type={showSymbolDetail.type as ISymbolDetailTypes}
      />
      <MarketsContent>
        <Title>
          Markets Overview
          <Desc>
            Renewable Energy Certificate & Carbon Credit Exchange Platform
          </Desc>
        </Title>
        <MarketOverviewGroup>
          <MarketOverviewCard
            title="Best offer"
            data={bestOfferData?.map((data) => ({
              pair_symbol: data.pair_symbol,
              last_price: data.lowest_sell_price,
              percent_change: data.percent_change,
            }))}
          />
          <MarketOverviewCard
            title="Top Volume"
            data={topVolumeData?.map((data) => ({
              pair_symbol: data.pair_symbol,
              last_price: data.volume,
              percent_change: data.percent_change,
            }))}
          />
          <MarketOverviewCard
            title="Top Value"
            data={topValueData?.map((data) => ({
              pair_symbol: data.pair_symbol,
              last_price: data.top_value,
              percent_change: data.percent_change,
            }))}
          />
        </MarketOverviewGroup>
        <StyledTabsBar mode="horizontal" activeKey="">
          <Tab
            key="carbon credits"
            label={<TabHeader>Carbon Credit</TabHeader>}
            isActiveTab
          >
            <Table
              {...tableProps("carbon")}
              onPageChange={setPage}
              onShowChange={setLimit}
              currentPage={pagination.page}
              show={pagination.limit}
              showPerPageOptions={showPerPageOptions}
              totalPages={totalPages}
              rowHoverable
              loadingAsSkeleton
              containerStyle={{ marginTop: "20px" }}
            />
          </Tab>
          <Tab key="rec" label={<TabHeader>REC</TabHeader>} disabled>
            REC
          </Tab>
        </StyledTabsBar>
      </MarketsContent>
    </MarketsContainer>
  );
};

export default Markets;
