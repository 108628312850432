/* eslint-disable react/destructuring-assignment */
import { PureComponent, ReactElement } from "react";
import { widget as Widget } from "lib/charting_library";
import type {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
  LanguageCode,
} from "lib/charting_library";
import "./index.css";
import { OrderContext } from "providers";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  containerId: string;
}

export interface ChartContainerState {
  connected: boolean;
  currentSymbol: string;
}

/**
 * Config of the TradingView chart to load.
 */
export interface ChartConfig {
  url: string; // the WebSocket server backend
  currencyPair: string; // the current currency pair
}

export class TVChartContainer extends PureComponent<
  Partial<ChartContainerProps>,
  ChartContainerState
> {
  public static defaultProps: ChartContainerProps = {
    symbol: "CAR_THB",
    interval: "1" as ResolutionString,
    containerId: "tv_chart_container",
    datafeedUrl: "", // 'https://demo_feed.tradingview.com',
    libraryPath: "/lib/charting_library/",

    // TODO implement backend to store JSON https://github.com/tradingview/charting_library/wiki/Saving-and-Loading-Charts#developing-your-own-backend
    chartsStorageUrl: "https://saveload.tradingview.com",

    chartsStorageApiVersion: "1.1",
    clientId: "blockfint.com",
    userId: "1234", // this ID is used by the server to store + load the user chart settings // TODO Max use real user ID (or Hash of ID) down in constructor
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  private tvWidget: IChartingLibraryWidget | null = null;

  constructor(props: ChartContainerProps) {
    super(props);
    this.state = {
      connected: false,
      currentSymbol: "",
    };
  }

  public componentDidMount(): void {
    this.connectTradingView();
  }

  componentDidUpdate() {
    if (!this.state.connected) {
      this.connectTradingView();
    } else if (
      this.state.connected &&
      this.state.currentSymbol !== this.context.state.config.currencyPair
    ) {
      this.setState({ currentSymbol: this.context.state.config.currencyPair });
      this.tvWidget?.setSymbol(
        this.context.state.config.currencyPair,
        "1" as ResolutionString,
        () => null
      );
    }
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  connectTradingView = () => {
    if (
      this.context.state.feed &&
      this.context.state.config &&
      this.context.state.config.currencyPair
    ) {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        // symbol: this.props.symbol as string,
        symbol: this.context.state.config.currencyPair,
        // BEWARE: no trailing slash is expected in feed URL
        // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
        datafeed: this.context.state.feed,
        interval: this.props
          .interval as ChartingLibraryWidgetOptions["interval"],
        // container_id: this.props.containerId as ChartingLibraryWidgetOptions['container_id'],
        container: this.props.containerId as string,
        library_path: this.props.libraryPath as string,

        locale: "en" as LanguageCode, // TODO check if lang is actually supported
        // timezone: this.getTimezoneName() as any,
        timezone: "Asia/Bangkok",
        // timezone: new Date().getTimezoneOffset() as any,

        // disabled_features: ['use_localstorage_for_settings'],
        disabled_features: [
          "header_symbol_search",
          "compare_symbol",
          "header_compare",
          "volume_force_overlay",
          "left_toolbar",
          "header_saveload",
          "header_screenshot",
        ],
        enabled_features: [],
        charts_storage_url: this.props.chartsStorageUrl,
        charts_storage_api_version: this.props.chartsStorageApiVersion,
        client_id: this.props.clientId,
        user_id: this.props.userId,
        fullscreen: this.props.fullscreen,
        autosize: this.props.autosize,
        studies_overrides: this.props.studiesOverrides,
        time_frames: [
          { text: "4m", resolution: "1440" as ResolutionString },
          { text: "15d", resolution: "180" as ResolutionString },
          { text: "5d", resolution: "60" as ResolutionString },
          { text: "1d", resolution: "15" as ResolutionString },
          { text: "6h", resolution: "3" as ResolutionString },
          { text: "2h", resolution: "1" as ResolutionString },
        ],
        overrides: {
          "paneProperties.background": "#1C1C1C",
          "paneProperties.backgroundType": "solid",
        },
        theme: "Dark",
        custom_css_url: "/css/style.css",
      };

      // connect to WS
      const tvWidget = new Widget(widgetOptions);

      this.tvWidget = tvWidget;
      tvWidget.onChartReady(() => {
        this.setState({
          connected: true,
          currentSymbol: this.context.state.config.currencyPair,
        });
      });
    }
  };

  public render(): ReactElement {
    return <div id="tv_chart_container" className="TVChartContainer" />;
  }
}

TVChartContainer.contextType = OrderContext;
