import {
  CSSProperties,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

import { ReactComponent as Paperclip } from "assets/CarbonCredit-SVG/Paperclip.svg";
import { IconButton } from "../Buttons";

export const TextFieldContainer = styled.div<{ block?: boolean }>`
  position: relative;
  width: ${(props) => (props.block ? "100%" : "200px")};
  display: ${(props) => (props.block ? "block" : "inline-block")};
  padding-bottom: calc(1rem + 4px);
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: ${(props) => props.theme.textColor};
`;

export const LabelRequired = styled.span`
  color: ${(props) => props.theme.red5};
`;

export const Field = styled.input<{
  shouldAddRPad?: boolean;
  disabled?: boolean;
}>(
  (props) => css`
    width: 100%;
    height: 100%;
    padding: ${props.theme.inputPadding};
    padding-right: ${props.shouldAddRPad ? "40px" : props.theme.inputPadding};
    border-radius: ${props.theme.inputBorderRadius};
    border: none;
    outline: none;
    background-color: ${props.theme.inputBackgroundColor};
    color: ${props.theme.secondaryColor};
    ::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
    ::placeholder {
      color: ${props.theme.inputPlaceholderColor};
    }
  `,
  (props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.inputDisableBackground};
      ::placeholder {
        color: ${props.theme.textColorDisabled};
      }
    `
);

export const ErrorText = styled.div<{ errorWarn?: boolean }>`
  color: ${(props) => props.theme.errorColor};
  font-weight: 600;
  font-size: 0.75rem;
  position: absolute;
  bottom: 2px;
  opacity: ${(props) => (props.errorWarn ? 1 : 0)};
  transition: opacity 0.3s;
  line-height: 16px;
`;

const InputContainer = styled.div<{
  errorWarn?: boolean;
  disabled?: boolean;
}>(
  (props) => css`
    height: ${props.theme.inputBaseHeight};
    width: 100%;
    position: relative;
    border-radius: ${props.theme.inputBorderRadius};
    border: ${props.errorWarn
      ? `solid 1px ${props.theme.errorColor}`
      : `solid 1px ${props.theme.inputBorderColor}`};
    transition-duration: 0.3s;
    transition-property: color, border-color, background-color;
    outline: none;
    font-size: ${props.theme.inputFontSize};
  `,
  (props) =>
    props.disabled &&
    css`
      color: ${props.theme.textColorDisabled};
      border-color: ${props.theme.inputDisableBorderColor};
    `
);

export const Icon = styled(IconButton)`
  position: absolute;
  bottom: 1em;
  right: 1em;
  margin: 0px auto;
  > img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
`;

const Suffix = styled.div`
  position: absolute;
  top: calc(${(props) => props.theme.inputBaseHeight} - 16px - 16px);
  right: 16px;
  font-size: ${(props) => props.theme.inputFontSize};
  color: ${(props) => props.theme.gray};
`;

export interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorWarn?: boolean;
  errorMessage?: string;
  icon?: string;
  suffix?: ReactNode;
  onIconClick?: () => void;
  containerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  block?: boolean;
  required?: boolean;
  labelSuffix?: ReactNode;
}

export const TextField = forwardRef<HTMLInputElement, ITextField>(
  (
    {
      label = "",
      labelSuffix = "",
      errorWarn = false,
      errorMessage = "",
      id,
      icon,
      suffix,
      onIconClick,
      children,
      style,
      containerStyle,
      inputStyle,
      disabled,
      block = true,
      required = false,
      ...rest
    },
    ref
  ) => (
    <TextFieldContainer style={containerStyle} block={block}>
      {label && (
        <LabelContainer>
          <Label htmlFor={id}>
            {label}
            {required && <LabelRequired>*</LabelRequired>}
          </Label>
          {labelSuffix}
        </LabelContainer>
      )}
      <InputContainer errorWarn={errorWarn} disabled={disabled} style={style}>
        <Field
          ref={ref}
          disabled={disabled}
          id={id}
          shouldAddRPad={!!suffix || !!icon}
          style={inputStyle}
          {...rest}
        >
          {children}
        </Field>
        {icon && <Icon type="button" onClick={onIconClick} iconSrc={icon} />}
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputContainer>
      {errorMessage && (
        <ErrorText errorWarn={errorWarn}>{errorMessage}</ErrorText>
      )}
    </TextFieldContainer>
  )
);

const PaperclipIcon = Icon.withComponent(Paperclip);

const StyledPaperclipIcon = styled(PaperclipIcon)`
  top: calc(${(props) => props.theme.inputBaseHeight} - 16px - 16px);
  stroke: ${(props) => props.theme.lightgray200};
`;

const LabelAsTextField = Field.withComponent("label");

const StyledLabelAsTextField = styled(LabelAsTextField)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.inputPlaceholderColor};
`;

export const UploadInput = forwardRef<
  HTMLInputElement,
  Omit<ITextField, "icon" | "containerStyle">
>(
  (
    {
      label,
      labelSuffix,
      required,
      placeholder,
      id,
      errorWarn,
      style,
      inputStyle,
      disabled,
      ...rest
    },
    ref
  ) => (
    <>
      {label && (
        <LabelContainer>
          <Label htmlFor={id}>
            {label}
            {required && <LabelRequired>*</LabelRequired>}
          </Label>
          {labelSuffix}
        </LabelContainer>
      )}
      <InputContainer errorWarn={errorWarn} disabled={disabled} style={style}>
        <StyledLabelAsTextField
          htmlFor={id}
          style={{ ...inputStyle, cursor: "pointer" }}
        >
          {placeholder}
        </StyledLabelAsTextField>
        <StyledPaperclipIcon />
      </InputContainer>
      <TextField
        ref={ref}
        style={{ display: "none" }}
        id={id}
        errorWarn={errorWarn}
        disabled={disabled}
        {...rest}
        type="file"
      />
    </>
  )
);
