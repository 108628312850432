import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Spinner } from "react-bootstrap";

import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";
import { Button, TextField, TextTitle } from "components";
import { emailSchema, recaptchaInitErrorToast } from "utils";
import { useAuth } from "providers";
import { config } from "config";
import * as errorCodes from "models/apiErrorCodes";

interface ILoginData {
  email: string;
  password: string;
}

const { path } = config;

const TextDiv = styled.div`
  position: relative;
  display: flex;
  color: white;
`;

const ErrorText = styled.p`
  color: #d02828;
  font-size: 0.9rem;
`;

const Title = styled(TextTitle)`
  color: ${({ theme }) => theme.primaryColor};
  max-width: 18.75rem;
  margin: 0 auto 24px;
  text-align: center;
  font-size: 30px;
`;

const Container = styled.div``;

const Link = styled(Button)`
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  color: white;
  text-decoration: underline;
  :hover {
    color: white;
  }
`;

const SignupSchema = yup.object({
  email: emailSchema,
  password: yup.string().required("Password is required"),
});

const LoginLoadingText = styled.span`
  margin-left: 1em;
`;

const StyledButton = styled(Button)`
  padding: 10px 24px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 140px;
`;

const Login = () => {
  const { t } = useTranslation();
  const {
    login,
    state: { error, isLoading },
  } = useAuth();
  const [errorForm, setErrorForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<ILoginData>({
    resolver: yupResolver(SignupSchema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onError = (data: any) => {
    if (data) {
      setErrorForm(true);
    } else return setErrorForm(false);
  };

  // todo rework this into map {code: error message}
  const isLoginError =
    error?.code === errorCodes.Unauthorized ||
    error?.code === errorCodes.DatabaseError;
  const isError = isLoginError || errorForm;

  const [password, setPasswords] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswords(!password);
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("login");

    await login({
      email: data.email,
      password: data.password,
      recaptcha_token: token,
    });
    setLoading(false);
  };

  const errorMsg = useMemo(() => {
    if (isError) {
      return (
        <div style={{ margin: " 0 0", alignSelf: "center" }}>
          <ErrorText>Email does not exist or password invalid</ErrorText>
        </div>
      );
    }
    return null;
  }, [isError]);

  return (
    <Container>
      <StyledButton block as="link" to={path.register} variant="secondary">
        Register
      </StyledButton>
      <Title>{t("Login")}</Title>
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <TextDiv>
          <TextField
            label={t("Email")}
            errorWarn={isError}
            placeholder="Email"
            disabled={isLoading}
            {...register("email")}
          />
        </TextDiv>
        <TextDiv>
          <TextField
            label={t("Password")}
            errorWarn={isError}
            placeholder="Password"
            type={password ? "text" : "password"}
            disabled={isLoading}
            {...register("password")}
            icon={eye}
            onIconClick={togglePasswordVisiblity}
          />
        </TextDiv>
        {errorMsg}
        <div style={{ textAlign: "right" }}>
          <Link type="button" as="link" variant="link" to={path.forgetPw}>
            {t("ForgotPassword")}
          </Link>
        </div>
        <Button
          block
          type="submit"
          disabled={loading || isLoading}
          flexDirection="row"
        >
          {!isLoading && !loading ? (
            t("Login")
          ) : (
            <>
              <Spinner animation="border" as="span" size="sm" />
              <LoginLoadingText>Logging in...</LoginLoadingText>
            </>
          )}
        </Button>
      </form>
    </Container>
  );
};

export default Login;
