import { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import ReactMarkdown from "react-markdown";

import { Button, Checkbox } from "components";
import { useRegisterContext } from "providers/RegisterProvider";
import markdown from "assets/agreements/Agreement.md";
import { Spinner } from "react-bootstrap";

const AgreementContainer = styled.div`
  .container-check .checkmark {
    padding: 8px;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin: 40px 0;
  color: ${(props) => props.theme.primaryColor};
`;

const InfoBox = styled.div`
  padding: 32px;
  background-color: ${(props) => props.theme.componentBackgroundColor};
  width: 100%;
  height: 30vh;
  overflow: auto;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  margin-bottom: 40px;
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Agreement = () => {
  const {
    state: { page, tnc },
    dispatch,
  } = useRegisterContext();
  const [markdownText, setMarkdownText] = useState("");

  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    fetch(markdown)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownText(text);
      });
  }, []);

  const next = () => {
    dispatch({
      type: "SET_PAGE",
      payload: page + 1,
    });
  };

  const toggleTnC = () => {
    dispatch({
      type: "SET_TNC",
      payload: !tnc,
    });
  };

  return (
    <AgreementContainer>
      <Header>Agreement Acknowledgement</Header>
      <InfoBox>
        {markdownText ? (
          <ReactMarkdown>{markdownText}</ReactMarkdown>
        ) : (
          <SpinnerContainer>
            <Spinner
              style={{ display: "flex", margin: "0 auto" }}
              animation="border"
              variant="info"
            />
          </SpinnerContainer>
        )}
      </InfoBox>
      <Checkbox
        checked={tnc}
        onClick={toggleTnC}
        type="box"
        label="I agree with terms and condition"
        containerClassname="container-check"
      />
      <Button block disabled={!tnc} onClick={next}>
        Next
      </Button>
    </AgreementContainer>
  );
};

export default Agreement;
