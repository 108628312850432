import styled from "@emotion/styled/macro";
import { forwardRef, MouseEventHandler, ReactNode } from "react";

interface ICheckboxContainer {
  checkmarkColor?: string;
  checkedColor?: string;
  checkedBorderColor?: string;
  borderColor?: string;
  type?: "circle" | "box";
  block?: boolean;
}

export interface ICheckbox extends ICheckboxContainer {
  containerClassname?: string;
  label?: ReactNode;
  checked: boolean;
  className?: string;
  onClick?: MouseEventHandler;
  id?: string;
  name?: string;
  readOnly?: boolean;
}

export const StyledBoxCheckboxContainer = styled.div<ICheckboxContainer>`
  position: relative;
  padding: 10px 0 10px 10px;
  display: ${(props) => (props.block ? "flex" : "inline-flex")};
  align-items: center;
  label {
    margin-left: 24px;
    color: #eaecef;
    display: inline-block;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked {
    ~ .checkmark {
      background: ${(props) => props.checkedColor};
      border: 1px solid ${(props) => props.checkedBorderColor};
    }
    ~ .checkmark:after {
      display: block;
    }
  }
  input:focus,
  input:active {
    ~ .checkmark {
    }
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: ${(props) => `1px solid ${props.borderColor}`};
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s all;
    border-radius: ${(props) => (props.type === "circle" ? "50%" : "4px")};
    padding: ${(props) => (props.type === "circle" ? "8px" : "10px")};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 5px;
    height: 8px;
    border: solid ${(props) => props.checkmarkColor};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: translate(-55%, -65%) rotate(45deg);
  }
`;

export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  (
    {
      label,
      checked,
      className,
      containerClassname,
      checkmarkColor = "#007DF7",
      checkedColor = "#007DF74D",
      checkedBorderColor = "#007DF7",
      borderColor = "white",
      onClick,
      type = "circle",
      id,
      name,
      block = true,
      ...rest
    },
    ref
  ) => (
    <StyledBoxCheckboxContainer
      className={containerClassname}
      type={type}
      checkmarkColor={checkmarkColor}
      checkedColor={checkedColor}
      borderColor={borderColor}
      checkedBorderColor={checkedBorderColor}
      block={block}
    >
      <label
        className={checked ? "checked" : ""}
        onClick={onClick}
        htmlFor={name || id}
      >
        {label}
      </label>
      <input
        ref={ref}
        className={className}
        type="checkbox"
        id={name || id}
        name={name}
        checked={checked}
        onClick={onClick}
        readOnly
        {...rest}
      />
      <span className="checkmark" onClick={onClick} />
    </StyledBoxCheckboxContainer>
  )
);
