import { APIError } from "models/generic";
import { HashEverything } from "utils";
import { ClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { BestOfferItem, BestOfferReq, BestOfferRes } from "./BestOfferData";

/**
 * Market is a websocket client receiving live trades from the server.
 */
export class BestOffer extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.MYORDER;

  private uniqueReqId: string = "";

  constructor(socket: ClientSocket) {
    super(socket);
    this.uniqueReqId = "";
  }

  public onData(data: BestOfferRes): void {
    let bestOffer: BestOfferItem[] = [];
    if (data.best_offer !== undefined) {
      bestOffer = data.best_offer;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe(
    req: Omit<BestOfferReq, "client_id" | "unsubscribe_client_id">
  ) {
    const uniqueReqId = HashEverything(JSON.stringify(req));
    this.socket.subscribe(this);
    const reqData: BestOfferReq = {
      ...req,
      client_id: this.subsciberID + uniqueReqId,
    };
    this.uniqueReqId = uniqueReqId;
    this.send(reqData);
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID + this.uniqueReqId });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: BestOfferReq) {
    return super.sendInternal({
      best_offer: data,
      channel_id: this.subsciberID,
    });
  }
}
