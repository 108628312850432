import styled from "@emotion/styled";
import { Button, Modal } from "components";
import { ReactNode } from "react";
import { mediaQuery } from "utils";

const ModalContent = styled.div`
  /* padding: 0 36px; */
  text-align: center;
`;

const ModalTitle = styled.h1`
  text-align: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.textColor};
`;

const ModalDesc = styled.p`
  color: ${({ theme }) => theme.textColor};
`;

const GroupButton = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-gap: 8px;
  ${mediaQuery("mobile")} {
    flex-direction: row;
  }
`;

export interface IConfirmModal {
  show: boolean;
  title: ReactNode;
  desc: ReactNode;
  textConfirm?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal = ({
  show,
  title,
  desc,
  textConfirm = "Confirm",
  onConfirm,
  onClose,
}: IConfirmModal) => {
  const titleIsString = typeof title === "string";
  return (
    <Modal show={show}>
      <ModalContent>
        {title && titleIsString ? <ModalTitle>{title}</ModalTitle> : title}
        <ModalDesc>{desc}</ModalDesc>
        <GroupButton>
          <Button
            type="button"
            block
            size="small"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="button" block size="small" onClick={onConfirm}>
            {textConfirm}
          </Button>
        </GroupButton>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
