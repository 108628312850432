import styled from "@emotion/styled/macro";

import { LoginForm } from "features/Login";
import { Card } from "components";
import { mediaQuery } from "utils/mediaQueries";

const Container = styled.div`
  width: 100vw;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.baseBackgroundColor};
  ${mediaQuery("desktop")} {
    height: 100vh;
  }
`;

const CardView = styled(Card)`
  margin: auto;
  width: 100%;
  max-width: 540px;
  padding: 40px;
  background: transparent;
  box-shadow: none;
  position: initial;
  ${mediaQuery("mobile")} {
    padding: 80px 100px 50px;
  }
`;

const Login = () => (
  <Container>
    <CardView>
      <LoginForm />
    </CardView>
  </Container>
);

export default Login;
