import { Navbar, NavDropdown, Dropdown, NavbarProps } from "react-bootstrap";
import styled from "@emotion/styled/macro";

import { ReactComponent as IconSignOut } from "assets/CarbonCredit-SVG/SignOut.svg";
import showMenu from "assets/CarbonCredit-SVG/ShowMenu.svg";
import { useAuth } from "providers";
import { Avatar } from "components";

interface PropsItem {
  Icon?: string;
}

const NavBar = styled(Navbar)`
  box-shadow: 0 0 14px 4px rgba(65, 66, 66, 0.1);
  background-color: ${(props) => props.theme.navbarBackgroundColor};
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.navbarZIndex};
`;

const Item = styled(Dropdown.Item)<PropsItem>`
  align-items: center !important;
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${(props) => props.theme.textColor};
  gap: 8px;
  padding: 12px 24px 12px 16px;
  background-color: transparent;
  ::before {
    vertical-align: middle;
    font-size: 1px;
    border: none;
    content: url(${(props) => (props.Icon ? props.Icon : "")});
  }
  svg:hover path {
    stroke: #fce57e;
  }
`;

const DropdownToggle = styled(NavDropdown)`
  .dropdown-toggle {
    ::after {
      vertical-align: middle;
      font-size: 1px;
      border: none;
      content: url(${showMenu});
    }
  }
  .dropdown-menu {
    border-radius: ${(props) => props.theme.borderRadiusBase};
    border: solid 1px #e7eaf1;
    align-items: stretch;
    left: auto;
    right: 0;
    top: 42px;
    padding: 0;
    background-color: ${(props) => props.theme.componentBackgroundColor};
    .dropdown-item:hover {
      background-color: ${(props) => props.theme.dropdownItemHover};
      color: ${(props) => props.theme.textColor};
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #fff;
      color: #1f2c57;
      path {
        stroke: #1f2c57;
      }
    }
  }
`;

const AdminText = styled.div`
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${(props) => props.theme.textColor};
`;

const Title = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.textColor};
`;

interface Props extends NavbarProps {}

const NavbarAdmin = ({ className, ...rest }: Props) => {
  const {
    logout,
    state: { user },
  } = useAuth();

  const handleSignOut = () => {
    logout();
  };

  return (
    <NavBar className={`py-2 px-4 ${className}`} {...rest}>
      <Navbar.Brand href="/">
        <AdminText data-test-id="nav-admin_text">Admin</AdminText>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <DropdownToggle
          title={
            <>
              <Avatar
                className="d-inline-block"
                name={user?.name || ""}
                size="36px"
              />
              <Title>{user?.name}</Title>
            </>
          }
          id="basic-nav-dropdown"
          data-test-id="profile__button"
        >
          <Item onClick={handleSignOut}>
            <IconSignOut />
            Sign out
          </Item>
        </DropdownToggle>
      </Navbar.Collapse>
    </NavBar>
  );
};

export default NavbarAdmin;
