import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ErrorText } from "components";

import PhoneInput, { PhoneInputProps } from "react-phone-input-2";

export const PhoneInputContainer = styled.div<{ block?: boolean }>`
  position: relative;
  width: ${(props) => (props.block ? "100%" : "200px")};
  display: ${(props) => (props.block ? "block" : "inline-block")};
  padding-bottom: calc(1rem + 4px);
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: ${(props) => props.theme.inputBackgroundColor};
  }
`;

interface Props extends PhoneInputProps {
  value: string;
  onChange: (...event: any[]) => void;
  errorWarn?: boolean;
  errorMessage?: string;
  block?: boolean;
}
const CustomPhoneInput = ({
  value,
  onChange,
  errorWarn = false,
  errorMessage = "",
  block = true,
  ...rest
}: Props) => {
  const theme = useTheme();
  return (
    <PhoneInputContainer block={block}>
      <PhoneInput
        value={value}
        placeholder="Phone Number"
        country="th"
        countryCodeEditable={false}
        disableDropdown
        enableSearch
        masks={{ th: "..-...-...." }}
        onChange={onChange}
        inputProps={{
          name: "phone",
          id: "phone",
          required: true,
          autoFocus: true,
        }}
        containerStyle={{
          display: "flex",
          columnGap: "8px",
          flexDirection: "row-reverse",
        }}
        inputStyle={{
          height: "3rem",
          paddingLeft: "10px",
          width: "100%",
          border: errorWarn
            ? `solid 1px ${theme.errorColor}`
            : `solid 1px ${theme.inputBorderColor}`,
          boxShadow: "none",
          backgroundColor: theme.inputBackgroundColor,
          color: theme.textColor,
        }}
        buttonStyle={{
          borderRadius: "6px",
          backgroundColor: theme.inputBackgroundColor,
          position: "relative",
          border: errorWarn
            ? `solid 1px ${theme.errorColor}`
            : `solid 1px ${theme.inputBorderColor}`,
        }}
        {...rest}
      />
      {errorMessage && (
        <ErrorText errorWarn={errorWarn}>{errorMessage}</ErrorText>
      )}
    </PhoneInputContainer>
  );
};

export default CustomPhoneInput;
