import styled from "@emotion/styled";
import { useState } from "react";
import { colorMix, mediaQuery, setAlpha } from "utils";

import { Button, Modal, OutlineSelect } from "components";
import { GetMarketFiltersListRes } from "models/market";
import { ReactComponent as FilterIcon } from "assets/CarbonCredit-SVG/Filter.svg";

const FilterButton = styled(Button)`
  flex-direction: row;
  column-gap: 8px;
`;

const StyledModal = styled(Modal)`
  .content-container {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.textColor};
    ${mediaQuery("mobile")} {
      min-width: 580px;
    }
  }
`;

const FilterText = styled.p`
  margin-bottom: 0;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 20px;
  row-gap: 8px;
  ${mediaQuery("mobile")} {
    grid-template-columns: 200px auto;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => setAlpha(props.theme.black, 0.4)};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  padding: 0 20px;
`;

const CancelButton = styled(StyledButton)`
  color: ${(props) => props.theme.gray2};
  :hover {
    background-color: ${(props) => colorMix(props.theme.gray1, 5)};
    color: ${(props) => props.theme.gray2};
  }
`;

export interface IFilter {
  defaultValues: { standard: string; projectType: string };
  marketOptions?: GetMarketFiltersListRes;
  onApply?: (values: { standard: string; projectType: string }) => void;
  onCancel?: () => void;
}

export const Filter = ({
  defaultValues,
  marketOptions,
  onApply,
  onCancel,
}: IFilter) => {
  const [openedFilter, setOpenedFilter] = useState(false);
  const [filterModalState, setFilterModalState] = useState(defaultValues);

  return (
    <>
      <FilterButton
        variant="link"
        onClick={() => {
          setOpenedFilter((state) => !state);
        }}
      >
        <FilterIcon />
        Filter
      </FilterButton>
      <StyledModal
        show={openedFilter}
        onBackgroundClick={() => {
          setOpenedFilter(false);
          setFilterModalState(defaultValues);
        }}
      >
        <p>Filter</p>
        <Grid>
          <FilterText>Standards</FilterText>
          <OutlineSelect
            style={{ paddingBottom: 0 }}
            onChange={(e) =>
              setFilterModalState((state) => ({
                ...state,
                standard: e.target.value,
              }))
            }
            value={filterModalState.standard}
          >
            <option value="">All standards</option>
            {marketOptions?.data.standards.map((standard) => (
              <option key={standard} value={standard}>
                {standard}
              </option>
            ))}
          </OutlineSelect>
        </Grid>
        <Grid>
          <FilterText>Project Types</FilterText>
          <OutlineSelect
            style={{ paddingBottom: 0 }}
            onChange={(e) =>
              setFilterModalState((state) => ({
                ...state,
                projectType: e.target.value,
              }))
            }
            value={filterModalState.projectType}
          >
            <option value="">All Project Types</option>
            {marketOptions?.data.projects
              .filter((val) => val !== "TEST")
              .map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
          </OutlineSelect>
        </Grid>
        <Divider />
        <ButtonGroup>
          <Button
            variant="link"
            onClick={() => {
              setFilterModalState({ standard: "", projectType: "" });
            }}
          >
            Clear All Filters
          </Button>
          <div>
            <CancelButton
              onClick={() => {
                onCancel && onCancel();
                setFilterModalState(defaultValues);
                setOpenedFilter(false);
              }}
              style={{ height: 40 }}
              variant="link"
            >
              Cancel
            </CancelButton>
            <StyledButton
              style={{ height: 40 }}
              onClick={() => {
                onApply && onApply(filterModalState);
                setOpenedFilter(false);
              }}
            >
              Apply
            </StyledButton>
          </div>
        </ButtonGroup>
      </StyledModal>
    </>
  );
};
