import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";

import logobackground from "assets/CarbonCredit-SVG/Gideon-BG-Logo.svg";
import background from "assets/Log-in.svg";
import tver from "assets/TVER.svg";
import vcs from "assets/VCS.svg";
import irec from "assets/IREC.svg";
import gs from "assets/GS.svg";
import { Button } from "components";
import { mediaQuery } from "utils/mediaQueries";

const Container = styled.div`
  padding: 64px 28px 42px 28px;
  background-color: #f3f6fb;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  font-family: Nunito Sans;
  display: flex;
`;

const Background = styled.img`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.defaultZIndex};
`;

const LOGOBackground = styled.img`
  position: fixed;
  z-index: ${(props) => +props.theme.defaultZIndex - 1};
  left: -20%;
  top: -20%;
  pointer-events: none;
`;

const MenuContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 24px 0;
  text-align: center;
  position: relative;
  z-index: ${(props) => +props.theme.defaultZIndex + 1};
  height: auto;
  min-height: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  ${mediaQuery("tablet")} {
    padding: 0;
    justify-content: center;
  }
`;

const Header = styled.h2`
  font-weight: 900;
  margin-bottom: 48px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  width: 100%;
  min-width: 264px;
  max-width: min(70vw, 80vh, 1000px);
  margin: 0 auto;
  ${mediaQuery("tablet")} {
    grid-row-gap: 32px;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;

const MenuCard = styled.div`
  background: #ffffff;
  border: 1px solid #e7eaf1;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 4px rgba(217, 225, 233, 0.1);
  border-radius: ${(props) => props.theme.borderRadiusBase};
  width: 100%;
  height: 100%;
  padding: 12%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TradeLogo = styled.img`
  width: 100%;
  max-width: 25%;
`;

const TradeName = styled.h2`
  margin: 10% 0;
`;

const Desc = styled.div`
  font-weight: 600;
  color: #9daac6;
  margin-bottom: 10%;
`;

const TradeButton = styled(Button)`
  margin-top: auto;
`;

const Menu = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <LOGOBackground src={logobackground} />
      <Background src={background} />
      <MenuContainer>
        <Header>Green Credit Trade</Header>
        <Grid>
          <MenuCard>
            <TradeLogo src={tver} />
            <TradeName>T-VER</TradeName>
            <Desc>Thailand Voluntary Emission Reduction Program</Desc>
            <TradeButton
              variant="secondary"
              block
              onClick={() => navigate("/market/TVER")}
            >
              Trade
            </TradeButton>
          </MenuCard>
          <MenuCard>
            <TradeLogo src={vcs} />
            <TradeName>Verra VCS</TradeName>
            <Desc>Verified Carbon Standard</Desc>
            <TradeButton variant="secondary" block disabled>
              Trade
            </TradeButton>
          </MenuCard>
          <MenuCard>
            <TradeLogo src={irec} />
            <TradeName>I-REC</TradeName>
            <Desc>The International REC Standard</Desc>
            <TradeButton
              variant="secondary"
              block
              onClick={() => navigate("/market/IREC")}
            >
              Trade
            </TradeButton>
          </MenuCard>
          <MenuCard>
            <TradeLogo src={gs} />
            <TradeName>Gold Standard</TradeName>
            <Desc>Gold Standard for the Global Goals</Desc>
            <TradeButton
              variant="secondary"
              block
              onClick={() => navigate("/market/GS")}
            >
              Trade
            </TradeButton>
          </MenuCard>
        </Grid>
      </MenuContainer>
    </Container>
  );
};

export default Menu;
