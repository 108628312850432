import { useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { unionBy } from "lodash-es";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useTheme } from "@emotion/react";

import { Button, ConfirmModal, UploadInput } from "components";
import {
  AttachmentForm,
  attachmentFormSchema,
  AttachmentList,
} from "models/user";
import { APIError } from "models/generic";
import { FileTypes } from "models/file";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  apiErrorToast,
  captureErrorSentry,
  recaptchaInitErrorToast,
  calculateMd5,
} from "utils";

import { Form, RegisterComponentGrid, Title } from "../components";

type uploadedFiles = {
  originalFileHash: string;
  minioName: string;
};

type fileType =
  | "proof_of_company_registration"
  | "proof_of_vat_registration"
  | "bank_book"
  | "director_id_card"
  | "proof_of_delegation"
  | "assignee_id_card";

const listFileType: fileType[] = [
  "proof_of_company_registration",
  "proof_of_vat_registration",
  "bank_book",
  "director_id_card",
  "proof_of_delegation",
  "assignee_id_card",
];

const Header = styled.div`
  margin: 32px auto;
`;

const placeHolderText = "Please upload the file in jpg, jpeg, or png format.";

const ModalTitle = styled.h1`
  font-weight: 900;
  font-size: 22px;
  width: 400px;
  margin-bottom: 8px;
`;

const ModalDesc = styled.p`
  width: 400px;
  font-size: 16px;
  text-align: start;
`;

export const Attachment = () => {
  const theme = useTheme();
  const [uploadedFiles, setUploadedFiles] = useState<uploadedFiles[]>([]);
  const { state, dispatch, signUpUser } = useRegisterContext();
  const { attachments, isLoading } = state;

  const [confirmRegisterOpened, setConfirmRegisterOpened] = useState(false);

  const localStorageValues = attachments.map((data) => ({
    [data.file_type.toLowerCase() as fileType]: data.file_name,
  }));

  const defaultValues = Object.assign({}, ...localStorageValues);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setError,
  } = useForm<AttachmentForm>({
    resolver: yupResolver(attachmentFormSchema),
    // eslint-disable-next-line camelcase
    defaultValues,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const fileName = (name: fileType) => {
    // check value is localStorage or inputFile
    if (typeof watch(name) === "string") {
      return watch(name) as string;
    }
    const data = watch(name) as FileList;
    return data?.item(0)?.name;
  };

  const onSubmit = async (data: AttachmentForm) => {
    try {
      dispatch({ type: "LOADING", payload: true }); // get every file in data
      const checkFileLocal = listFileType.map((type) => {
        if (typeof data?.[type] !== "string" && data?.[type]?.length !== 0) {
          return {
            name: type,
            file: data?.[type] as FileList,
          };
        }
        return undefined;
      });

      const uploadedFileHashes = uploadedFiles.map(
        (file) => file.originalFileHash
      );

      const files: {
        name: fileType;
        file: FileList;
        hash: string;
      }[] = [];

      // create array of file name and url
      const fileUrls: AttachmentList = [];

      // filter data from localStorage out
      await Promise.all(
        checkFileLocal.map(async (data) => {
          const fileInput = data?.file?.item(0);
          const fileBlob = new Blob([fileInput as Blob]);
          const fileHash = await calculateMd5(fileBlob);
          if (data?.file !== undefined) {
            if (!uploadedFileHashes.includes(fileHash ?? "")) {
              files.push({
                ...data,
                hash: fileHash,
              });
            } else {
              const file = uploadedFiles.find(
                (file) => file.originalFileHash === fileHash
              );
              // if file is already uploaded, just add use minioName
              if (file) {
                fileUrls.push({
                  file_name: file.minioName,
                  file_type: data.name.toUpperCase() as FileTypes,
                });
              }
            }
          }
        })
      );

      // if vat registered, add proof of vat registration
      if (
        data?.proof_of_vat_registration &&
        typeof data?.proof_of_vat_registration !== "string"
      ) {
        const fileInput = data.proof_of_vat_registration?.item(0);
        const fileBlob = new Blob([fileInput as Blob]);
        const fileHash = await calculateMd5(fileBlob);

        files.push({
          name: "proof_of_vat_registration",
          file: data?.proof_of_vat_registration,
          hash: fileHash,
        });
      }

      const newUploadedFiles: uploadedFiles[] = [];

      // upload every file
      const uploadFiles = files.map((fileObj) => {
        const fileInput = fileObj.file?.item(0);

        const blob = new Blob([fileInput as Blob]);
        const contentType = fileInput?.type;
        const formdata = new FormData();

        formdata.append("file_name", fileInput?.name || "");
        formdata.append("upload_file", blob);
        formdata.append("content_type", contentType || "");

        // use then to return promise
        return axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/user/upload-file`,
            formdata
          )
          .then((res) => {
            newUploadedFiles.push({
              originalFileHash: fileObj.hash,
              minioName: res.data.file_name,
            });
            return res;
          })
          .catch((err) => {
            // do not throw error, just show error message
            setError(fileObj.name, {
              type: "custom",
              message: err.response.data.message,
            });
            return err;
          });
      });
      const uploadUrlResponses = await Promise.all(uploadFiles);

      dispatch({ type: "LOADING", payload: false });

      setUploadedFiles((oldFiles) => [...oldFiles, ...newUploadedFiles]);

      uploadUrlResponses.forEach((response, index) => {
        if (response.data) {
          fileUrls.push({
            file_type: files[index].name.toUpperCase() as FileTypes,
            file_name: response.data.data.file_name,
          });
        } else {
          throw new Error(response.message);
        }
      });

      // update data to localStorage
      const updatedData = unionBy(fileUrls, attachments, "file_type");

      onSubmitted();
      dispatch({
        type: "SET_ATTACHMENTS",
        payload: updatedData,
      });
      setConfirmRegisterOpened(true);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        dispatch({ type: "ERROR", payload: error });
      }
      dispatch({ type: "LOADING", payload: false });
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSignup = async () => {
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    const recaptchaToken = await executeRecaptcha("register");
    try {
      await signUpUser(state, recaptchaToken);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
        captureErrorSentry(error, err, { message: "Register error" });
      }
    }
  };

  return (
    <>
      <ConfirmModal
        show={confirmRegisterOpened}
        title={
          <ModalTitle>Would you like to confirm your information?</ModalTitle>
        }
        desc={
          <ModalDesc>
            The information provided cannot be changed after registration. Do
            you confirm the information you have provided?
          </ModalDesc>
        }
        onConfirm={() => {
          handleSignup();
        }}
        onClose={() => {
          setConfirmRegisterOpened(false);
        }}
      />
      <RegisterComponentGrid>
        <Header>
          <Title>Register</Title>
        </Header>
        <Form id="attachment" onSubmit={handleSubmit(onSubmit)}>
          <UploadInput
            id="proof_of_company_registration"
            type="file"
            label="Business Registration Certificate (หนังสือรับรองบริษัท)"
            required
            errorWarn={!!errors.proof_of_company_registration}
            errorMessage={errors.proof_of_company_registration?.message}
            placeholder={
              fileName("proof_of_company_registration") || placeHolderText
            }
            inputStyle={{
              color:
                fileName("proof_of_company_registration") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("proof_of_company_registration")}
          />
          <UploadInput
            id="proof_of_vat_registration"
            type="file"
            label="Por.Por. 20: Business Registration Certificate (ภพ.20)"
            required
            errorWarn={!!errors.proof_of_vat_registration}
            errorMessage={errors.proof_of_vat_registration?.message}
            placeholder={
              fileName("proof_of_vat_registration") || placeHolderText
            }
            inputStyle={{
              color: fileName("proof_of_vat_registration") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("proof_of_vat_registration")}
          />
          <UploadInput
            id="bank_book"
            type="file"
            label="สมุดบัญชีบริษัท"
            required
            placeholder={fileName("bank_book") || placeHolderText}
            inputStyle={{
              color: fileName("bank_book") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("bank_book")}
            errorWarn={!!errors.bank_book}
            errorMessage={errors.bank_book?.message}
          />
          <UploadInput
            id="director_id_card"
            type="file"
            label="บัตรประชาชนผู้มีอำนาจลงนาม"
            required
            errorWarn={!!errors.director_id_card}
            errorMessage={errors.director_id_card?.message}
            placeholder={fileName("director_id_card") || placeHolderText}
            inputStyle={{
              color: fileName("director_id_card") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("director_id_card")}
          />
          <UploadInput
            id="proof_of_delegation"
            type="file"
            label="หนังสือมอบอำนาจ (optional)"
            errorWarn={!!errors.proof_of_delegation}
            errorMessage={errors.proof_of_delegation?.message}
            placeholder={fileName("proof_of_delegation") || placeHolderText}
            inputStyle={{
              color: fileName("proof_of_delegation") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("proof_of_delegation")}
          />

          <UploadInput
            id="assignee_id_card"
            type="file"
            label="บัตรประชาชนผู้รับมอบอำนาจ/ผู้ประสานงาน (optional)"
            errorWarn={!!errors.assignee_id_card}
            errorMessage={errors.assignee_id_card?.message}
            placeholder={fileName("assignee_id_card") || placeHolderText}
            inputStyle={{
              color: fileName("assignee_id_card") && theme.textColor,
            }}
            accept=".pdf, .jpg, .jpeg, .png, .gif"
            {...register("assignee_id_card")}
          />
        </Form>
        <Button form="attachment" type="submit" block disabled={isLoading}>
          {isLoading ? (
            <Spinner
              style={{ display: "block" }}
              animation="border"
              variant="light"
            />
          ) : (
            "Next"
          )}
        </Button>
      </RegisterComponentGrid>
    </>
  );
};
