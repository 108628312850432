import styled from "@emotion/styled/macro";
import { setAlpha } from "utils";

export const Container = styled.div`
  margin: 0 0 24px;
  width: 100%;
`;

export const Table = styled.table`
  border-spacing: 0px 1px;
  border-collapse: separate;
  width: 100%;
`;

export const Thead = styled.thead`
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid ${(props) => setAlpha(props.theme.white, 0.2)};
  }
`;

export const Tbody = styled.tbody`
  display: block;
  overflow-y: auto;
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`;

export const Td = styled.td`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.white4};
  text-align: center;
  height: 26px;
`;

export const Tr = styled.tr`
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.white4};
  text-align: center;
`;

export const TdLeft = styled(Td)`
  text-align: Left;
`;

export const TdRight = styled(Td)`
  text-align: Right;
`;

export const Th = styled.th`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.white4};
  text-align: center;
  height: 26px;
`;

export const ThLeft = styled(Th)`
  text-align: Left;
`;

export const ThRight = styled(Th)`
  text-align: Right;
`;

export const Row = styled(Tr)`
  td {
    color: ${(props) => props.theme.textColor};
  }
`;

export const Type = styled(TdLeft)`
  text-transform: capitalize;
`;

export const Sell = styled(TdRight)<{ side: string }>`
  color: ${(props) =>
    props.side === "sell" ? props.theme.red2 : props.theme.green} !important;
  text-transform: capitalize;
  text-align: center;
`;

export const TdStatus = styled(Td)<{ status?: string }>`
  text-align: center;
  opacity: ${({ status = "" }) => (status === "Cancel" ? "0.5" : "100")};
`;
