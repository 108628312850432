import { AnimatePresence } from "framer-motion";
import {
  useState,
  ReactElement,
  cloneElement,
  useMemo,
  useEffect,
} from "react";

import { ITab, ITabContent, ITabsBar } from "./interfaces";
import {
  AfterUl,
  TabContainer,
  TabContentContainer,
  TabLi,
  TabUl,
  TabUlContainer,
  Underline,
} from "./styles";

const TabContent = ({
  uniqueKey,
  children,
  // onNext,
  // onPrev,
  // activeTab,
  // tabLength,
  // header,
  // loading,
  overflow,
}: ITabContent): ReactElement => (
  <TabContentContainer
    key={uniqueKey}
    className="tab-content is-active"
    initial={{ opacity: 0 }}
    animate={{
      opacity: 1,
      overflow: overflow || "auto",
    }}
    exit={{ opacity: 0 }}
    transition={{
      duration: 0.1,
    }}
    layout
  >
    {children}
  </TabContentContainer>
);

export const TabsBar = ({
  children,
  loading,
  mode = "vertical",
  className,
  activeKey,
  onSelect,
  overflow,
  afterUl,
  ...rest
}: ITabsBar): ReactElement => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [activeTab, setActive] = useState(-1);
  const tabLength = children.length;

  useEffect(() => {
    if (activeKey) {
      const idx = children.findIndex(
        (child) => child.key === activeKey && child.props.disabled !== true
      );
      if (idx >= 0) {
        setActive(idx);
      }
    } else if (isFirstTime) {
      setIsFirstTime(false);
      // use first non-disabled tab
      const idx = children.findIndex((child) => child.props.disabled !== true);
      if (idx >= 0) {
        setActive(idx);
      }
    }
  }, [activeKey, children, isFirstTime]);

  const activeChild = useMemo(
    () =>
      children.find((child) => child.key === activeKey) || children[activeTab],
    [activeKey, activeTab, children]
  );

  const renderTabContent = useMemo(() => {
    if (activeChild) {
      // const isActiveTab = activeTab === index;
      const { children: child, header } = activeChild.props;
      const onNext = () =>
        activeTab + 1 >= tabLength ? null : setActive(activeTab + 1);
      const onPrev = () =>
        activeTab - 1 < 0 ? null : setActive(activeTab - 1);
      const key = activeChild.key || "";
      const newProps = {
        uniqueKey: key,
        onNext,
        onPrev,
        activeTab,
        tabLength,
        header,
        loading,
        overflow,
      };
      return (
        <TabContent key={key} {...newProps}>
          {child}
        </TabContent>
      );
    }
    return "error";
  }, [activeChild, activeTab, loading, overflow, tabLength]);

  return (
    <TabContainer
      className={`tab-container ${className}`}
      {...rest}
      mode={mode}
    >
      <TabUlContainer className="tab-ul-container">
        <TabUl className="tab-ul" mode={mode}>
          {children.map((child, index) => {
            const onTabClick = () => {
              setActive(index);
              onSelect && onSelect(child.key as string);
            };
            const isActiveTab = activeTab === index;
            return cloneElement(child, { onTabClick, isActiveTab, mode });
          })}
        </TabUl>
        {afterUl && <AfterUl className="after-ul">{afterUl}</AfterUl>}
      </TabUlContainer>
      <div className="tab-content-container">
        <div className="tab-content-content">
          <AnimatePresence mode="wait">{renderTabContent}</AnimatePresence>
        </div>
      </div>
    </TabContainer>
  );
};

export const Tab = ({
  isActiveTab,
  onTabClick,
  label,
  mode = "vertical",
  disabled,
  layoutId = "id",
  ...rest
}: ITab): ReactElement => (
  <TabLi
    mode={mode}
    className={`tab ${isActiveTab ? "is-active bold" : ""}`}
    disabled={disabled}
    onClick={!disabled ? onTabClick : undefined}
    {...rest}
  >
    {isActiveTab && mode === "horizontal" && (
      <Underline layoutId={`tab-underline-${layoutId}`} />
    )}
    {label}
  </TabLi>
);
