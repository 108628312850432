import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Button, Card } from "components";
import { config } from "config";

const Container = styled.div`
  background-color: ${(props) => props.theme.baseBackgroundColor};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const CardView = styled(Card)`
  margin: auto;
  padding: 64px 48px;
  width: 400px;
  box-shadow: ${(props) => props.theme.boxShadowLogin};
`;
const ContentContainer = styled.div`
  max-width: 350px;
  margin: 40px auto;
`;
const Title = styled.h2`
  text-align: center;
  margin-bottom: 1em;
`;
const Desc = styled.div`
  text-align: center;
  span {
    color: #02acd8;
  }
`;

const { path } = config;

const AccountSuspended = () => {
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate(path.login);
  };
  return (
    <Container>
      <CardView>
        <ContentContainer>
          <Title>AccountSuspended</Title>
          <Desc>
            We&apos;re sorry, this account has been suspended. Please contact
            <span> admin@email.com</span> for assistance.
          </Desc>
          <Button block onClick={onClickBack}>
            Back
          </Button>
        </ContentContainer>
      </CardView>
    </Container>
  );
};

export default AccountSuspended;
