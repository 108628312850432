import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import toast from "react-hot-toast";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Spinner } from "react-bootstrap";

import {
  BackButton,
  Button,
  Card,
  ConfirmNewEmail,
  ConfirmNewPhone,
  TextField,
  PhoneInput,
} from "components";
import { useAuth } from "providers";
import {
  validateEmail,
  validatePhone,
  verifyOTP,
  changeEmail,
  requestMobileNumberOTP,
  requestEmailOTP,
  changeMobileNumber,
} from "api/users";
import { APIError } from "models/generic";
import { UserInfo } from "models/user";
import {
  apiErrorToast,
  emailSchema,
  formatMobileNumberPrefix,
  mediaQuery,
  mobileSchema,
  recaptchaInitErrorToast,
} from "utils";

const AccountInfoContainer = styled.div`
  height: 100%;
  padding-bottom: 32px;
  ${mediaQuery("tablet")} {
    padding-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
`;

const MainContainer = styled.div`
  display: grid;
  padding-top: 3rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2.75rem 1px 2.75rem 1px 2.75rem 1px 2.75rem 2.75rem 1px 2.75rem 2.75rem;
  grid-row-gap: 12px;
  grid-template-areas:
    "AcctId DataAcct"
    "line line"
    "AcctOwner DataOwner"
    "line1 line1"
    "AcctName DataName"
    "line2 line2"
    "Email DataEmail"
    ". editEmail"
    "line4 line4"
    "Mobile DataMobile"
    ". editPhone";

  ${mediaQuery("tablet")} {
    padding-top: 0;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2.75rem 1px 2.75rem 1px 2.75rem 1px 2.75rem 1px 2.75rem 1px 2.75rem 1px;
    grid-row-gap: 24px;
    grid-template-areas:
      "AcctId DataAcct ."
      "line line line"
      "AcctOwner DataOwner ."
      "line1 line1 line1"
      "AcctName DataName ."
      "line2 line2 line2"
      /* "AcctDisplayName DataDisplayName editName" */
      /* "line3 line3 line3" */
      "Email DataEmail editEmail"
      "line4 line4 line4"
      "Mobile DataMobile editPhone";
  }
`;

const Header = styled.h2`
  color: inherit;
  line-height: 30px;
  ${mediaQuery("tablet")} {
    display: none;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.darkgray900};
  align-self: center;
  font-weight: 600;
`;

const Data = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  align-self: center;
  font-weight: 600;
`;

const Line = styled.div`
  background-color: #f3f6fb;
  width: 100%;
`;

const AccountInformation = () => {
  const {
    state: { user },
    dispatch,
    logout,
  } = useAuth();
  const [_, setCookie] = useCookies(["token"]);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { userId } = useParams(); // todo use this to fetch user info case super-admin

  const [phone, setPhone] = useState({
    phone: user?.mobile_number || "",
    newPhone: user?.mobile_number || "",
  });
  const [email, setEmail] = useState({
    email: user?.email || "",
    newEmail: user?.email || "",
  });
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [showConfirmNewEmail, setShowConfirmNewEmail] = useState(false);
  const [showConfirmNewPhone, setShowConfirmNewPhone] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [isError, setError] = useState(false);
  const [editMailCountdown, setEditMailCountdown] = useState(0);
  const [editPhoneCountdown, setEditPhoneCountdown] = useState(0);
  const [editMailStage, setEditMailStage] = useState(1);
  const [editPhoneStage, setEditPhoneStage] = useState(1);
  const [editMailLoading, setEditMailLoading] = useState(false);
  const [editPhoneLoading, setEditPhoneLoading] = useState(false);

  const userRole = user?.role; // check if super-admin
  const isSuperAdminDetailPage = userId && userRole === "admin"; // todo change this to super admin or change checking logic

  // countdown for resend email
  useEffect(() => {
    if (editMailCountdown > 0) {
      const timer = setTimeout(() => {
        setEditMailCountdown(editMailCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [editMailCountdown]);

  // countdown for resend phone
  useEffect(() => {
    if (editPhoneCountdown > 0) {
      const timer = setTimeout(() => {
        setEditPhoneCountdown(editPhoneCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [editPhoneCountdown]);

  const sendEmailOTP = useCallback(
    async (email?: string) => {
      setRefCode("");
      if (!executeRecaptcha) {
        recaptchaInitErrorToast();
        return;
      }
      const recaptchaToken = await executeRecaptcha("request_email_otp");
      const requestOTPRes = await requestEmailOTP({
        email,
        recaptcha_token: recaptchaToken,
      });
      setRefCode(requestOTPRes.data.reference_code);
    },
    [executeRecaptcha]
  );

  const sendMobileNumberOTP = useCallback(
    async (phone?: string) => {
      setRefCode("");
      if (!executeRecaptcha) {
        recaptchaInitErrorToast();
        return;
      }
      const recaptchaToken = await executeRecaptcha(
        `request_mobile_number_otp`
      );
      const res = await requestMobileNumberOTP({
        recaptcha_token: recaptchaToken,
        mobile_number: phone,
      });
      setRefCode(res.data.reference_code);
      setEditMailCountdown(60);
      return res;
    },
    [executeRecaptcha]
  );

  const handleCheckOldEmail = async (
    otp: string,
    successCallback: () => void
  ) => {
    try {
      const res = await verifyOTP({
        reference_code: refCode,
        otp,
      });
      const { success, session_id: sessionId } = res.data;
      if (success && sessionId) {
        setCookie("token", sessionId, {
          path: "/",
          secure: true,
          sameSite: "strict",
        });
        dispatch({
          type: "SET_SESSION",
          payload: sessionId,
        });
        successCallback();
        setEditMailStage(2);
        await sendEmailOTP(email.newEmail);
      } else {
        throw new Error("Invalid OTP");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
      }
      setError(true);
    }
  };

  const handleCheckEmail = async (otp: string, successCallback: () => void) => {
    try {
      const res = await verifyOTP({
        reference_code: refCode,
        otp,
      });
      const { success, session_id: sessionId } = res.data;
      if (success && sessionId) {
        setCookie("token", sessionId, {
          path: "/",
          secure: true,
          sameSite: "strict",
        });
        dispatch({
          type: "SET_SESSION",
          payload: sessionId,
        });
        successCallback();
        setEditPhoneStage(2);
        await sendMobileNumberOTP(phone.newPhone);
      } else {
        throw new Error("Invalid OTP");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
      }
      setError(true);
    }
  };

  const handleCancelNewEmail = () => {
    setEmail((mail) => ({
      ...mail,
      newEmail: mail.email,
    }));
    setEditEmail(false);
    setShowConfirmNewEmail(false);
  };

  const handleConfirmNewEmail = async (pin: string) => {
    setShowConfirmNewEmail(false);
    const toastId = toast.loading("Changing email...");
    try {
      const res = await changeEmail({
        otp: pin,
        new_email: email.newEmail,
        reference_code: refCode,
      });
      if (res.data.success) {
        setEmail((mail) => ({
          ...mail,
          email: mail.newEmail,
        }));
        logout();
        toast.success(
          "Email changed successfully.\n Please login with your new email.",
          {
            id: toastId,
          }
        );
      } else {
        throw new Error("Failed to change email");
      }
    } catch (err) {
      setEditMailStage(1);
      setEmail((mail) => ({
        ...mail,
        newEmail: mail.email,
      }));
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Failed to change email", {
          id: toastId,
        });
      }
    }
  };

  const handleCancelNewPhone = () => {
    setPhone((phone) => ({
      ...phone,
      newPhone: phone.phone,
    }));
    setEditPhone(false);
    setShowConfirmNewPhone(false);
  };

  const handleConfirmNewPhone = async (pin: string) => {
    setShowConfirmNewPhone(false);
    const toastId = toast.loading("Changing Mobile Number...");
    try {
      const res = await changeMobileNumber({
        otp: pin,
        new_mobile_number: phone.newPhone,
        reference_code: refCode,
      });
      if (res.data.success) {
        setPhone((phone) => ({
          ...phone,
          phone: phone.newPhone,
        }));
        dispatch({
          type: "SET_USER",
          payload: {
            ...user,
            mobile_number: phone.newPhone,
          } as UserInfo,
        });
        toast.success("Mobile Number changed successfully", {
          id: toastId,
        });
      } else {
        throw new Error("Failed to change Mobile Number");
      }
    } catch (err) {
      setEditPhoneStage(1);
      setPhone((phone) => ({
        ...phone,
        newPhone: phone.phone,
      }));
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Failed to change Mobile Number", {
          id: toastId,
        });
      }
    }
  };

  const onEditEmail = () => {
    setEditEmail(true);
  };

  const onSaveEmail = useCallback(async () => {
    if (email.newEmail && emailSchema.isValidSync(email.newEmail)) {
      setEditMailLoading(true);
      try {
        const res = await validateEmail(email.newEmail);
        if (res.data.is_unique) {
          await sendEmailOTP();
          setEditMailLoading(false);
          setEditEmail(false);
          setEditMailStage(0);
          setEditMailCountdown(60);
          setShowConfirmNewEmail(true);
        } else {
          throw new Error("Invalid Email");
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        } else {
          toast.error("Invalid Email");
        }
        setEmail((mail) => ({
          ...mail,
          newEmail: mail.email,
        }));
        setEditMailLoading(false);
      }
    } else {
      toast.error("Invalid Email");
    }
  }, [email.newEmail, sendEmailOTP]);

  const onEditPhone = () => {
    setEditPhone(!editPhone);
  };

  const onSavePhone = useCallback(async () => {
    if (
      phone.newPhone &&
      mobileSchema.isValidSync(phone.newPhone.substring(1)) // remove + sign
    ) {
      setEditPhoneLoading(true);
      try {
        const res = await validatePhone(phone.newPhone);
        if (res.data.is_unique) {
          await sendEmailOTP();
          setEditPhoneLoading(false);
          setEditPhone(false);
          setShowConfirmNewPhone(true);
        } else {
          throw new Error("Invalid Mobile Number");
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        } else {
          toast.error("Invalid Mobile Number");
        }
        setPhone((phone) => ({
          ...phone,
          newPhone: phone.phone,
        }));
        setEditPhoneLoading(false);
      }
    } else {
      toast.error("Invalid Mobile Number");
    }
  }, [phone.newPhone, sendEmailOTP]);

  const handleResendNewEmailOTP = () => {
    setEditMailCountdown(60);
    if (editMailStage === 2) {
      sendEmailOTP(email.newEmail);
    } else {
      sendEmailOTP();
    }
  };

  const handleResendNewPhoneOTP = () => {
    setEditPhoneCountdown(60);
    if (editPhoneStage === 2) {
      sendMobileNumberOTP(phone.newPhone);
    } else {
      sendEmailOTP();
    }
  };

  return (
    <AccountInfoContainer>
      <ConfirmNewEmail
        email={email.email}
        newEmail={email.newEmail}
        show={showConfirmNewEmail}
        onClose={handleCancelNewEmail}
        onConfirm={handleConfirmNewEmail}
        refCode={refCode}
        resendFn={handleResendNewEmailOTP}
        isError={isError}
        setError={setError}
        onConfirmOldMail={handleCheckOldEmail}
      />
      <ConfirmNewPhone
        email={email.email}
        phone={formatMobileNumberPrefix(phone.newPhone)}
        show={showConfirmNewPhone}
        onClose={handleCancelNewPhone}
        onConfirm={handleConfirmNewPhone}
        refCode={refCode}
        resendFn={handleResendNewPhoneOTP}
        isError={isError}
        setError={setError}
        onConfirmEmail={handleCheckEmail}
      />
      <StyledCard
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        {isSuperAdminDetailPage ? (
          <BackButton label={<Header>{user?.name}</Header>} />
        ) : (
          <Header>Account</Header>
        )}
        <MainContainer>
          <Title style={{ gridArea: "AcctId" }}>Account ID</Title>
          <Data style={{ gridArea: "DataAcct" }}>{user?.id}</Data>
          <Line style={{ gridArea: "line" }} />
          <Title style={{ gridArea: "AcctOwner" }}>Account ownership</Title>
          <Data style={{ gridArea: "DataOwner" }}>
            {user?.account_ownership}
          </Data>
          <Line style={{ gridArea: "line1" }} />
          <Title style={{ gridArea: "AcctName" }}>Account name</Title>
          <Data style={{ gridArea: "DataName" }}>{user?.name}</Data>
          <Line style={{ gridArea: "line2" }} />
          <Line style={{ gridArea: "line3" }} />
          <Title style={{ gridArea: "Email" }}>Email</Title>
          <Data style={{ gridArea: "DataEmail" }}>
            {editEmail ? (
              <TextField
                value={email.newEmail}
                onChange={(e) =>
                  setEmail((mail) => ({ ...mail, newEmail: e.target.value }))
                }
                containerStyle={{
                  paddingBottom: 0,
                }}
              />
            ) : (
              <Data>{email.email}</Data>
            )}
          </Data>

          <div
            style={{
              gridArea: "editEmail",
              textAlign: "end",
              marginRight: "3rem",
              alignSelf: "center",
            }}
          >
            {editEmail ? (
              <div>
                {editMailLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button
                      variant="link"
                      style={{
                        marginRight: "0.625rem",
                      }}
                      onClick={handleCancelNewEmail}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="link"
                      onClick={onSaveEmail}
                      disabled={!email.newEmail}
                    >
                      save
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <Button
                variant="link"
                onClick={onEditEmail}
                disabled={editMailCountdown > 0}
              >
                {editMailCountdown > 0 ? editMailCountdown : "Edit"}
              </Button>
            )}
          </div>
          <Line style={{ gridArea: "line4" }} />
          <Title style={{ gridArea: "Mobile" }}>Mobile phone number</Title>
          <div style={{ gridArea: "DataMobile", alignSelf: "center" }}>
            {editPhone ? (
              <PhoneInput
                disabled={!editPhone}
                value={phone.newPhone}
                onChange={(val) =>
                  setPhone((phone) => ({
                    ...phone,
                    newPhone: formatMobileNumberPrefix(val),
                  }))
                }
              />
            ) : (
              <Data>{phone.phone}</Data>
            )}
          </div>
          <div
            style={{
              gridArea: "editPhone",
              textAlign: "end",
              marginRight: "3rem",
              alignSelf: "center",
            }}
          >
            {editPhone ? (
              <div>
                {editPhoneLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button
                      variant="link"
                      style={{
                        marginRight: "0.625rem",
                      }}
                      onClick={handleCancelNewPhone}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="link"
                      onClick={onSavePhone}
                      disabled={!phone.newPhone}
                    >
                      save
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <Button
                variant="link"
                onClick={onEditPhone}
                disabled={editPhoneCountdown > 0}
              >
                {editPhoneCountdown > 0 ? editPhoneCountdown : "Edit"}
              </Button>
            )}
          </div>
          {isSuperAdminDetailPage && (
            <>
              <Line style={{ gridArea: "line5" }} />
              <div style={{ gridArea: "delete" }}>
                <Button variant="danger">Delete Account</Button>
              </div>
            </>
          )}
        </MainContainer>
      </StyledCard>
    </AccountInfoContainer>
  );
};

export default AccountInformation;
