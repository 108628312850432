import { CSSProperties, useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { Theme } from "themes/default";
import useSWR, { useSWRConfig } from "swr";
import dayjs from "dayjs";

import {
  Button,
  Card,
  DatePicker,
  OutlineSelect,
  Tab,
  Table,
  TabsBar,
  Badge,
  IconButton,
  Key,
} from "components";
import { ReactComponent as Download } from "assets/CarbonCredit-SVG/Download.svg";
import { NewOrder, NewOrderHistoryRes, orderStatusVariant } from "models/order";
import { NewTrade, NewTradeHistoryRes, SideTrade } from "models/trades";
import { convertObjToUrl, mediaQuery, priceNumber } from "utils";
import { useDebounceValue, usePagination, useSelectedDate } from "hooks";
import { AllMarketRes } from "models/market";
import { AllCurrencySymbolPairRes } from "models/currencyPair";

enum ActiveTab {
  order = "order-history",
  trade = "trade-history",
}

const OrdersContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  ${mediaQuery("tablet")} {
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;
  ${mediaQuery("tablet")} {
    display: none;
  }
`;

const Content = styled(Card)`
  flex: 1;
  height: fit-content;
`;

const TabsStyle = styled(TabsBar)`
  .tab-ul-container {
    justify-content: space-between;
    .tab-ul {
      width: fit-content;
    }
  }
  li.is-active {
    background-color: ${(props) => props.theme.black3};
  }
`;

const TabHeader = styled.h2`
  color: inherit;
  padding: 0 16px;
`;

const Side = styled.div<{ side: SideTrade }>`
  color: ${(props) =>
    props.side === SideTrade.buy
      ? props.theme.successColor
      : props.theme.errorColor};

  text-transform: capitalize;
`;

const OrderSubTable = styled(Table)`
  th {
    font-size: 0.75rem;
    font-weight: 600;
    color: ${(props) => props.theme.darkgray200};
    border-bottom: 1px solid ${(props) => props.theme.lightgray};
  }
  tr {
    font-size: 0.75rem;
    border-bottom: none;
    font-weight: 600;
  }
` as typeof Table;

const DownloadIcon = styled(Download)`
  stroke: ${(props) => props.theme.darkgray};
`;

const CustomOption = styled.option`
  text-transform: capitalize;
`;

const feeCalculate = (rawData: NewTrade) => {
  const { commission, commission_vat: comVat, withholding_tax: wh } = rawData;
  return +commission + +comVat - +wh;
};

const totalCalculate = (rawData: NewTrade) => {
  const { side, total, vat } = rawData;
  if (side === SideTrade.buy) {
    return +total + +vat + feeCalculate(rawData);
  }
  return +total + +vat - feeCalculate(rawData);
};

const ordersKeys: Key<NewOrder>[] = [
  {
    label: "Order ID",
    name: "id",
  },
  {
    label: "Date",
    name: "submit_time",
    customRenderer: (data) => dayjs(data).format("DD MMM YYYY HH:mm"),
  },
  {
    label: "Currency Pair",
    name: "pair_name",
  },
  {
    label: "Type",
    name: "type",
    style: {
      textAlign: "center" as const,
      textTransform: "capitalize",
    },
    headerStyle: {
      textAlign: "center" as const,
    },
  },
  {
    label: "Side",
    name: "side",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => <Side side={data as SideTrade}>{data}</Side>,
  },
  {
    label: "Avg price",
    name: "avg_price",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Price",
    name: "price",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Filled",
    name: "executed",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data, raw) =>
      `${parseFloat(data as string)}/${parseFloat(raw.amount)}`,
  },
  {
    label: "Total",
    name: "total",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Status",
    name: "status",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => (
      <Badge variant={orderStatusVariant(data as string)}>{data}</Badge>
    ),
  },
];

const ordersSubTableKeys: Key<NewTrade>[] = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "Date",
    name: "when",
    customRenderer: (data) => dayjs(data).format("DD-MMM HH:mm:ss"),
  },

  {
    label: "Price",
    name: "price",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Amount",
    name: "executed",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Vat",
    name: "vat",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Fee",
    name: "commission",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (_, rowData) => priceNumber(feeCalculate(rowData)),
  },
  {
    label: "Total",
    name: "total",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (_, rowData) => priceNumber(totalCalculate(rowData)),
  },
];

const historyKeys: Key<NewTrade>[] = [
  {
    label: "Trade ID",
    name: "id",
  },
  {
    label: "Date",
    name: "when",
    customRenderer: (data) => dayjs(data).format("DD MMM YYYY HH:mm"),
  },
  {
    label: "Currency Pair",
    name: "pair_name",
  },
  {
    label: "Side",
    name: "side",
    customRenderer: (data) => <Side side={data as SideTrade}>{data}</Side>,
  },
  {
    label: "Price",
    name: "price",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Amount",
    name: "executed",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
  },
  {
    label: "Vat",
    name: "vat",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => priceNumber(parseFloat(data as string)),
  },
  {
    label: "Fee",
    name: "commission",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (_, rowData) => priceNumber(feeCalculate(rowData)),
  },
  {
    label: "Total",
    name: "total",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (_, rowData) => priceNumber(totalCalculate(rowData)),
  },
];

const defaultFilterVals = {
  market: "",
  symbol: "",
  side: "",
};

const labelCss: CSSProperties = {
  minWidth: "120px",
  maxWidth: "180px",
};

const Orders = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.order);
  const [filterVals, setfilterVals] = useState(defaultFilterVals);
  const { market, side, symbol } = filterVals;

  const [totalTransaction, setTotalTransaction] = useState(0);
  const {
    pagination,
    setPage,
    setLimit,
    showPerPageOptions,
    totalPages,
    reset,
  } = usePagination(totalTransaction);
  const { selectedDate, selectingDate, onChangeDate, resetDate } =
    useSelectedDate();

  const [orderId, setOrderId] = useState(0);

  const resetFilter = useCallback(() => {
    setfilterVals(defaultFilterVals);
    resetDate();
  }, [resetDate]);

  // fetch
  const params = useMemo(
    () => ({
      ...pagination,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
      market_name: filterVals.market,
      pair_name: filterVals.symbol,
      side: filterVals.side,
    }),
    [
      filterVals.market,
      filterVals.side,
      filterVals.symbol,
      pagination,
      selectedDate,
    ]
  );

  const debouncedActiveTab = useDebounceValue(activeTab, 300);
  const debouncedParam = useDebounceValue(params, 300);

  const { data: allMarketRes } = useSWR<AllMarketRes>("/market");

  const { data: allSymbolPairRes } = useSWR<AllCurrencySymbolPairRes>(
    "/currency/symbol-pairs/"
  );

  const { data: orderDataRes } = useSWR<NewOrderHistoryRes>(
    debouncedActiveTab === ActiveTab.order
      ? `/order/history?${convertObjToUrl(debouncedParam)}`
      : null,
    {
      onSuccess: (res) => {
        setTotalTransaction(res.data.total);
      },
    }
  );

  const { data: tradeDataRes } = useSWR<NewTradeHistoryRes>(
    debouncedActiveTab === ActiveTab.trade
      ? `/trade/history?${convertObjToUrl(debouncedParam)}`
      : null,
    {
      onSuccess: (res) => {
        setTotalTransaction(res.data.total);
      },
    }
  );

  const { cache } = useSWRConfig();
  const { data: tradeByOrderData } = useSWR<NewTradeHistoryRes>(
    activeTab === ActiveTab.order ? `/trade/history?order_id=${orderId}` : null
  );

  const orderData = orderDataRes?.data.orders ?? [];
  const tradeData = tradeDataRes?.data.trades ?? [];

  const allMarket = useMemo(
    () => allMarketRes?.data ?? [],
    [allMarketRes?.data]
  );
  const allSymbolPair = useMemo(
    () => allSymbolPairRes?.data.symbol_pairs ?? [],
    [allSymbolPairRes?.data.symbol_pairs]
  );

  const filters = useMemo(
    () => (
      <>
        <DatePicker
          style={{
            minWidth: "240px",
          }}
          startDate={selectingDate[0]}
          endDate={selectingDate[1]}
          maxDate={new Date()}
          onChange={(_, date) => onChangeDate(date as (Date | null)[])}
          selectsRange
          dateFormat="dd MMM yyyy"
        />
        <OutlineSelect
          style={labelCss}
          value={market}
          onChange={(e) =>
            setfilterVals((old) => ({
              ...old,
              market: e.target.value as string,
            }))
          }
        >
          <option value="">All Markets</option>
          {allMarket.map(({ name }) => (
            <CustomOption key={name} value={name}>
              {name.replace("-", " ")}
            </CustomOption>
          ))}
        </OutlineSelect>
        <OutlineSelect
          style={labelCss}
          value={symbol}
          onChange={(e) =>
            setfilterVals((old) => ({
              ...old,
              symbol: e.target.value,
            }))
          }
        >
          <option value="">All Symbols</option>
          {allSymbolPair.map((pair) => (
            <option key={pair} value={pair}>
              {pair}
            </option>
          ))}
        </OutlineSelect>
        <OutlineSelect
          style={labelCss}
          value={side}
          onChange={(e) =>
            setfilterVals((old) => ({
              ...old,
              side: e.target.value,
            }))
          }
        >
          <option value="">All Sides</option>
          <option value={SideTrade.buy}>Buy</option>
          <option value={SideTrade.sell}>Sell</option>
        </OutlineSelect>
        <Button
          style={{ paddingBottom: "calc(1rem + 4px)" }}
          variant="link"
          onClick={resetFilter}
        >
          Reset
        </Button>
      </>
    ),
    [
      allMarket,
      allSymbolPair,
      market,
      onChangeDate,
      resetFilter,
      selectingDate,
      side,
      symbol,
    ]
  );

  const renderCollapseContent = (data: NewOrder) => {
    const tradeCache: NewTradeHistoryRes = cache.get(
      `/trade/history?order_id=${data.id}`
    );
    let tradeTableData: NewTrade[];
    if (tradeCache) {
      tradeTableData = tradeCache?.data.trades ?? [];
    } else {
      setOrderId(data.id);
      tradeTableData = tradeByOrderData?.data.trades ?? [];
    }
    return (
      <OrderSubTable
        keys={ordersSubTableKeys}
        data={tradeTableData}
        noDataMessage="No data"
        showPagination={false}
        containerStyle={{
          padding: "16px 32px",
          background: Theme.lightgray50,
          borderRadius: "4px",
        }}
      />
    );
  };

  return (
    <OrdersContainer>
      <Content
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <Title>Orders</Title>
        <TabsStyle
          mode="horizontal"
          loading={false}
          onSelect={(key) => {
            setActiveTab(key as ActiveTab);
            reset();
          }}
        >
          <Tab
            key={ActiveTab.order}
            label={<TabHeader>Order History</TabHeader>}
          >
            <Table
              collapseable
              filters={filters}
              keys={ordersKeys}
              data={orderData}
              noDataMessage="No data"
              currentPage={pagination.page}
              show={pagination.limit}
              onPageChange={setPage}
              onShowChange={setLimit}
              renderCollapseContent={renderCollapseContent}
              totalPages={totalPages}
              showPerPageOptions={showPerPageOptions}
              loading={!orderData}
            />
          </Tab>
          <Tab
            key={ActiveTab.trade}
            label={<TabHeader>Trade History</TabHeader>}
          >
            <Table
              filters={filters}
              keys={historyKeys}
              data={tradeData}
              noDataMessage="No data"
              currentPage={pagination.page}
              show={pagination.limit}
              onPageChange={setPage}
              onShowChange={setLimit}
              totalPages={totalPages}
              showPerPageOptions={showPerPageOptions}
              loading={!tradeData}
            />
          </Tab>
        </TabsStyle>
      </Content>
    </OrdersContainer>
  );
};

export default Orders;
