import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";

import { TextField, Button, Modal, IModal } from "components";
import { Registry } from "models/registry";

export interface IAddNewAccountValue {
  accountId: string;
  code: string;
  token: string;
  registryName: string;
}

interface IAddNewAccount extends IModal {
  type: Registry | null;
  onCancel: () => void;
  onConfirm: (val: IAddNewAccountValue) => void;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 400px;
    max-width: 540px;
  }
`;

const Title = styled.h2`
  margin-bottom: 8px;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  border: 1px solid ${(props) => props.theme.white};
`;

export const AddNewAccount = ({
  type,
  onCancel,
  onConfirm,
  show,
  ...rest
}: IAddNewAccount) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IAddNewAccountValue>();

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  useEffect(() => {
    if (type) {
      setValue("registryName", type.abbreviation);
    }
  }, [setValue, type]);

  const renderForm = () => {
    switch (type?.abbreviation) {
      case "TVER":
        return (
          <>
            <StyledTextField
              containerStyle={{ display: "none" }}
              type="hidden"
              {...register("registryName")}
            />
            <StyledTextField
              label="Account ID"
              placeholder="Account ID"
              {...register("accountId")}
              errorWarn={!!errors.accountId}
              errorMessage={errors.accountId?.message}
            />
            <StyledTextField
              label="Account Name"
              placeholder="Account Name"
              {...register("code")}
              errorWarn={!!errors.code}
              errorMessage={errors.code?.message}
            />
            <StyledTextField
              label="Token"
              placeholder="Token"
              {...register("token")}
              errorWarn={!!errors.token}
              errorMessage={errors.token?.message}
            />
          </>
        );
      case "IREC":
        return (
          <>
            <StyledTextField
              containerStyle={{ display: "none" }}
              type="hidden"
              {...register("registryName")}
            />
            <StyledTextField
              label="I-REC Registrant Code / ID"
              placeholder="Account ID"
              {...register("accountId")}
              errorWarn={!!errors.accountId}
              errorMessage={errors.accountId?.message}
            />
            <StyledTextField
              label="I-REC Participant Code / ID"
              placeholder="Verification Code"
              {...register("code")}
              errorWarn={!!errors.code}
              errorMessage={errors.code?.message}
            />
            <StyledTextField
              label="Token"
              placeholder="Token"
              {...register("token")}
              errorWarn={!!errors.token}
              errorMessage={errors.token?.message}
            />
          </>
        );
      case "GS":
      case "VERRA":
      default:
        return (
          <>
            <StyledTextField
              containerStyle={{ display: "none" }}
              type="hidden"
              {...register("registryName")}
            />
            <StyledTextField
              label="Account ID"
              placeholder="Account ID"
              {...register("accountId")}
              errorWarn={!!errors.accountId}
              errorMessage={errors.accountId?.message}
            />
            <StyledTextField
              label="Verification Code"
              placeholder="Verification Code"
              {...register("code")}
              errorWarn={!!errors.code}
              errorMessage={errors.code?.message}
            />
            <StyledTextField
              label="Token"
              placeholder="Token"
              {...register("token")}
              errorWarn={!!errors.token}
              errorMessage={errors.token?.message}
            />
          </>
        );
    }
  };

  return (
    <StyledModal show={show} {...rest}>
      <Title>{type?.name} Account</Title>
      <form onSubmit={handleSubmit(onConfirm)}>
        {renderForm()}
        <Footer>
          <Button
            style={{ marginRight: "8px" }}
            onClick={onCancel}
            variant="secondary"
            type="button"
            block
          >
            Cancel
          </Button>
          <Button type="submit" block>
            Confirm
          </Button>
        </Footer>
      </form>
    </StyledModal>
  );
};
