import {
  CSSProperties,
  forwardRef,
  ReactNode,
  SelectHTMLAttributes,
} from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

import ArrowDownUrl from "assets/CarbonCredit-SVG/ShowMenu.svg";
import { ErrorText, LabelContainer } from "../Field";

const SelectContainer = styled.div<{ block?: boolean }>`
  position: relative;
  width: ${(props) => (props.block ? "100%" : "auto")};
  padding-bottom: calc(1rem + 4px);
  display: ${(props) => (props.block ? "block" : "inline-block")};
  min-width: 200px;
`;

const Label = styled.label<{ block?: boolean }>`
  margin-right: ${(props) => (props.block ? 0 : "1rem")};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: ${(props) => props.theme.textColor};
`;

export const LabelRequired = styled.span`
  color: ${(props) => props.theme.red5};
`;

interface IBaseSelect {
  svg?: string;
  block?: boolean;
  errorWarn?: boolean;
}

const BaseSelect = styled.select<IBaseSelect>(
  (props) => css`
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    outline: none;

    border: solid 1px
      ${props.errorWarn
        ? props.theme.errorColor
        : props.theme.selectBorderColor};
    border-radius: ${props.theme.inputBorderRadius};
    width: 100%;
    display: block;
    height: 42px;
    padding: 8px;
    font-size: ${props.theme.inputFontSize};
    color: ${props.theme.textColor};
    background-image: ${`url(${props.svg})`};
    background-position: center right 8px;
    background-repeat: no-repeat;
    background-size: 20px;
    transition: border-color 0.3s;
    background-color: ${props.theme.inputBackgroundColor};
    &:focus,
    &:focus-within {
      border-color: ${props.theme.secondaryColor};
    }
  `,
  (props) =>
    props.disabled &&
    css`
      color: ${props.theme.white4};
      border-color: ${props.theme.selectDisabledBorderColor};
      background-color: ${props.theme.inputDisableBackground};
      cursor: not-allowed;
    `
);
export interface IOutlineSelect
  extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  labelSuffix?: ReactNode;
  svg?: string;
  block?: boolean;
  errorWarn?: boolean;
  errorMessage?: string;
  selectStyle?: CSSProperties;
  required?: boolean;
}

export const OutlineSelect = forwardRef<HTMLSelectElement, IOutlineSelect>(
  (
    {
      svg = ArrowDownUrl,
      label = "",
      labelSuffix,
      id,
      children,
      block = true,
      errorWarn,
      errorMessage,
      style,
      selectStyle,
      required = false,
      ...rest
    },
    ref
  ) => (
    <SelectContainer style={style} className="select-container" block={block}>
      {label && (
        <LabelContainer>
          <Label block={block} htmlFor={id}>
            {label}
            {required && <LabelRequired>*</LabelRequired>}
          </Label>
          {labelSuffix}
        </LabelContainer>
      )}
      <BaseSelect
        ref={ref}
        id={id}
        block={block}
        svg={svg}
        errorWarn={errorWarn}
        style={selectStyle}
        {...rest}
      >
        {children}
      </BaseSelect>
      {errorMessage && (
        <ErrorText errorWarn={errorWarn}>{errorMessage}</ErrorText>
      )}
    </SelectContainer>
  )
);
