import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { Button } from "components";
import { config } from "config";
import { Title, Desc } from "../components";

const StyledDesc = styled(Desc)`
  text-align: start;
  margin-bottom: 40px;
`;

const BoldDesc = styled(StyledDesc)`
  font-weight: bold;
  margin: 32px 0 12px 0;
`;

const { path } = config;

const Success = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Title>Success</Title>
      <BoldDesc>Thank you for registration.</BoldDesc>
      <StyledDesc>
        Your request to register on the &quot;Carbon Credit Exchange of
        Thailand&quot; is being reviewed. You will receive an email notification
        when the review process is successful.
      </StyledDesc>
      <Button block onClick={() => navigate(path.login)}>
        Done
      </Button>
    </div>
  );
};

export default Success;
