import styled from "@emotion/styled/macro";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Controller, useForm } from "react-hook-form";

import {
  Label,
  TextField,
  ErrorText,
  TextFieldContainer,
  Modal,
  IModal,
  BackButton,
  Button,
  OutlineSelect,
  IOTPInputValue,
  PinSetup,
} from "components";
import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";
import { ReactComponent as Success } from "assets/CarbonCredit-SVG/Success.svg";

export interface IAddNewAdminFormValues {
  accountName: string;
  displayName: string;
  email: string;
  password: string;
  phone: string;
}

export interface IAddNewAdmin extends IModal {
  onStepOneSubmit: (values: IAddNewAdminFormValues) => void;
  onStepTwoSubmit: (hashCode: string, successCallback: () => void) => void;
  onStepThreeClose: () => void;
  setError?: (isError: boolean) => void;
  isError?: boolean;
}

const StyledModal = styled(Modal)<{ isForm: boolean }>`
  .content-container {
    display: ${(props) => (props.isForm ? "grid" : "block")};
    grid-template-rows: max-content minmax(200px, 1fr) max-content; //Header Form SubmitButton
    max-height: 80vh;
    min-height: ${(props) => (props.isForm ? "450px" : "unset")};
    min-width: 400px;
    > form {
      overflow-y: auto;
      padding: 16px 0;
    }
  }
`;

const StyledBackBtn = styled(BackButton)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 8px;
`;

const Desc = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledSuccessIcon = styled(Success)`
  display: block;
  margin: 0 auto 16px;
  stroke: ${(props) => props.theme.primaryColor};
`;

const StyledTextField = styled(TextField)`
  border: 1px solid ${(props) => props.theme.white};
`;

export const AddNewAdmin = ({
  onBackgroundClick,
  onStepOneSubmit,
  onStepTwoSubmit,
  onStepThreeClose,
  setError,
  isError,
  ...rest
}: IAddNewAdmin) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IAddNewAdminFormValues>();
  const [isPwVisible, setIsPwVisible] = useState(false);
  const togglePasswordVisiblity = () => {
    setIsPwVisible(!isPwVisible);
  };
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);

  const goBack = () => {
    if (step === 1) {
      onBackgroundClick && onBackgroundClick();
    } else {
      setStep((step) => step - 1);
    }
  };

  const handleStepOneSubmit = (values: IAddNewAdminFormValues) => {
    onStepOneSubmit(values);
    setStep((step) => step + 1);
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCode(val);
    setCompleted(isFilled);
    if (setError && isError) {
      // change error back to false on first change
      setError(false);
    }
  };

  const handleStepTwoSubmit = () => {
    if (completed) {
      onStepTwoSubmit(code, () => setStep((step) => step + 1));
    }
  };

  const renderModalContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Title>Create Admin Account</Title>
            <form
              id="addnew-admin"
              onSubmit={handleSubmit(handleStepOneSubmit)}
              autoComplete="off"
            >
              <StyledTextField
                id="accountName"
                label="Account Name"
                placeholder="Account Name"
                {...register("accountName")}
              />
              <StyledTextField
                id="displayName"
                label="Display Name"
                placeholder="Display Name"
                {...register("displayName")}
              />
              <StyledTextField
                id="email"
                label="Email"
                placeholder="Email"
                {...register("email")}
              />
              <StyledTextField
                id="password"
                label="Password"
                placeholder="Password"
                type="password"
                {...register("password")}
                icon={eye}
                onIconClick={togglePasswordVisiblity}
              />
              <TextFieldContainer>
                <Label htmlFor="phone">Mobile Number</Label>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value, onChange } }) => (
                    <PhoneInput
                      value={value}
                      placeholder="Mobile Number"
                      inputClass="InputPhone"
                      disableCountryCode
                      enableTerritories
                      enableSearch
                      country="th"
                      containerStyle={{
                        display: "flex",
                      }}
                      inputStyle={{
                        height: "3rem",
                        marginLeft: "50px",
                        paddingLeft: "10px",
                        width: "100%",
                        borderColor: "#02acd8",
                      }}
                      buttonStyle={{
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                      }}
                      masks={{ th: "..-...-...." }}
                      onChange={onChange}
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        required: true,
                        autoFocus: false,
                      }}
                    />
                  )}
                />
                {errors.phone ? (
                  <div>
                    <ErrorText>{errors.phone?.message}</ErrorText>
                  </div>
                ) : null}
              </TextFieldContainer>
              <OutlineSelect label="Account Ownership" disabled>
                <option value="individual">Individual</option>
                <option value="company">Company</option>
              </OutlineSelect>
            </form>
            <Button block form="addnew-admin" type="submit">
              Next
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <PinSetup onChange={handleChange} />
            <Button disabled={!completed} onClick={handleStepTwoSubmit} block>
              Next
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <StyledSuccessIcon />
            <Title>Account Created</Title>
            <Desc>New admin account has been created</Desc>
            <Button block onClick={onStepThreeClose}>
              Close
            </Button>
          </>
        );
      default:
        break;
    }
  };

  return (
    <StyledModal
      isForm={step === 1}
      onBackgroundClick={onBackgroundClick}
      {...rest}
    >
      {step !== 3 && (
        <StyledBackBtn label={null} redirect={false} goBack={goBack} />
      )}
      {renderModalContent()}
    </StyledModal>
  );
};
