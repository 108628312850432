import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";

import { Card, IconButton, TextTitle } from "components";
import { priceNumber, percentNumber, mediaQuery } from "utils";
import { useOrder } from "providers";
import { TickerRes } from "models/WebsocketClients";
import { useLocation, useNavigate } from "react-router-dom";
import { APIError } from "models/generic";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";
import { ReactComponent as ArrowRiseIcon } from "assets/CarbonCredit-SVG/ArrowRiseIcon.svg";
import { ReactComponent as ArrowDescendingIcon } from "assets/CarbonCredit-SVG/ArrowDescendingIcon.svg";

import { config } from "config";
import { css } from "@emotion/react";

const { path } = config;

type Side = "positive" | "negative" | "neutral";

const CardView = styled(Card)`
  padding: 8px 16px;
  display: flex;
  width: 100vw;
  height: 100%;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
  background-color: #1e2026;
  margin-left: -28px;
  margin-right: -28px;
  border-radius: 0;
  gap: 8px 32px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;

const Price = styled(Title)<{ side?: Side }>(
  `
  text-align: center;
  margin: auto 0px auto 0px;
`,
  ({ side, theme }) => {
    switch (side) {
      case "positive":
        return `
        color: ${theme.successColor};
      `;
      case "negative":
        return `
        color: ${theme.errorColor};
      `;
      case "neutral":
      default:
        return `
        color: ${theme.textColor};
      `;
    }
  }
);

const Top = styled.div`
  font-size: 10px;
  margin-bottom: 4px;
  color: #ffffff66; // white with 40% opacity
`;

const Bottom = styled(TextTitle)<{ side?: Side }>(
  css`
    font-size: 12px;
    font-weight: 400;
    color: #1f2c57;
    svg {
      margin-right: 0.25rem;
    }
  `,
  ({ side, theme }) => {
    switch (side) {
      case "positive":
        return `
    color: ${theme.successColor};
  `;
      case "negative":
        return `
    color: ${theme.errorColor};
  `;
      case "neutral":
      default:
        return `
    color: ${theme.textColor};
  `;
    }
  }
);

const InfoDiv = styled.div``;

const BackButton = styled(IconButton)`
  margin: 0 1rem 0 0.5rem;
  svg {
    stroke: ${(props) => props.theme.darkgray};
    transition: stroke 0.3s;
  }

  &:hover svg {
    stroke: ${(props) => props.theme.textColor};
  }
`;

const MarketStatusBadge = styled.label<{ isOpen: boolean }>(
  (props) => css`
    background-color: #5c5c5c80;
    border-radius: 10px;
    width: 65px;
    font-size: 0.75rem;
    padding: 2px 8px;
    text-align: center;
  `,
  (props) =>
    props.isOpen
      ? css`
          color: #009864;
        `
      : css`
          color: ${props.theme.errorColor};
        `
);

const Divider = styled.div`
  background-color: white;
  width: 100%;
  height: 1px;
  margin: 8px 0;
  ${mediaQuery("tablet")} {
    height: 100%;
    width: 1px;
  }
`;

const getLeftIcon = (side: Side | undefined) => {
  switch (side) {
    case "positive":
      return <ArrowRiseIcon />;
    case "negative":
      return <ArrowDescendingIcon />;
    default:
      return null;
  }
};

const Box = ({
  Value,
  Title,
  style,
  side,
  withIcon,
}: {
  Title: string;
  Value: string;
  style?: any;
  side?: Side;
  withIcon?: boolean;
}) => (
  <InfoDiv style={style}>
    <Top>{Title}</Top>
    <Bottom side={side}>
      {withIcon && getLeftIcon(side)}
      {Value}
    </Bottom>
  </InfoDiv>
);

const Info = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<TickerRes | null>(null);

  const {
    state: { ticker, isOpen },
    dispatch,
  } = useOrder();

  const handleData = (data: TickerRes) => {
    setData(data);
  };

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in info: ", error);
    },
    [navigate]
  );

  useEffect(() => {
    if (ticker) {
      setData(null);
      ticker.subscribe();
      ticker.onData = handleData;
      ticker.onError = handleError;
    }
    return () => {
      if (ticker) {
        ticker.unsubscribe();
      }
    };
  }, [handleError, ticker]);

  useEffect(() => {
    if (data?.is_open !== isOpen) {
      dispatch({
        type: "SET_IS_OPEN",
        payload: data?.is_open,
      });
    }
  }, [data, dispatch, isOpen]);

  const dayChange = useMemo(() => {
    if (!data) return "";
    const percentChange = data?.percent_change || 0;
    return `${priceNumber(data?.change || 0, {
      signDisplay: "exceptZero",
    })} (${percentNumber(percentChange, {
      signDisplay: "exceptZero",
    })})
    `;
  }, [data]);

  const getPriceSide = (percentChange: number) => {
    if (percentChange > 0) return "positive";
    if (percentChange < 0) return "negative";
    return "neutral";
  };

  return (
    <CardView>
      <BackButton icon={<ArrowLeft />} onClick={() => navigate(-1)} />
      <Title>{location.pathname.split("/")[2].split("_")[0] || "-"}</Title>
      <Divider />
      <Box
        withIcon
        side={getPriceSide(data?.last_price || 0)}
        Title="Last"
        Value={priceNumber(data?.last_price || 0)}
      />
      <Box
        side={getPriceSide(data?.percent_change || 0)}
        Title="Change"
        Value={dayChange}
      />
      <Box Title="High" Value={priceNumber(data?.high || 0)} />
      <Box Title="Low" Value={priceNumber(data?.low || 0)} />
      <Box Title="Volume" Value={priceNumber(data?.volume || 0)} />
      <Box Title="Value" Value={priceNumber(data?.value || 0)} />
      <MarketStatusBadge isOpen={data?.is_open || false}>
        {data?.is_open ? "Open" : "Closed"}{" "}
      </MarketStatusBadge>
    </CardView>
  );
};

export default Info;
