/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
// import * as WebSocket from "ws"; // only works on server Side, Browsers have a native WebSocket object

import { APIError } from "models/generic";
import { getRandomString } from "utils/random";
import { WebSocketOpcode } from "./Opcode";
import {
  WebsocketDataRes,
  WebsocketDataReq,
  PrivateWebsocketDataRes,
  PrivateWebsocketDataReq,
} from "./WebSocketData";
import { assertUnreachableCode } from "./utils";

export interface ClientSocketCallback {
  (data: any): void;
}

export abstract class ClientSocketReceiver /* extends AbstractWidget */ {
  public readonly opcode: WebSocketOpcode = 1; // needed to subscribe to multuple WS feeds on single connection using array: [opcode, payloadObject] // TODO remove?

  protected socket: ClientSocket | PrivateClientSocket;

  protected persistent = false; // don't unsubscribe if we navigate to another page

  protected readonly subsciberID: string; // a unique Client ID to identify this stream (there can be multiple streams for the same data. example 2 orderbooks for different pairs)

  protected readonly className: string;

  constructor(socket: ClientSocket | PrivateClientSocket) {
    // super()
    this.socket = socket;
    this.subsciberID = getRandomString(20);
    this.className = this.constructor.name;
  }

  /**
   * OnData() is called from the server when there is data available on the socket.
   * @param data
   */
  public abstract onData(data: /* WebsocketDataRes sub prop */ any): void;

  public abstract onError(error?: APIError): void;

  public setPersistent(persistent: boolean) {
    this.persistent = persistent;
  }

  public isPersistent() {
    return this.persistent;
  }

  public getSubscriberID(): string {
    return this.subsciberID;
  }

  public getClassName(): string {
    return this.className;
  }

  /**
   * Send a request via HTTP instead of WebSocket
   * @param {string} url The url to send it to. Can be a
   * @param data any JavaScript object to be sent as json in the "data" parameter
   * @param {ClientSocketCallback} callback (optional). If not supplied the this.onData() will be called
   *          In case of HTTP errors no callback will be called (just like we get nothing when our websocket connection aborts).
   */
  public sendHTTP(
    url: string,
    data: any,
    callback: ClientSocketCallback | null = null
  ): void {
    if (!url)
      return console.error(
        "ERROR: url parameter for sendHTTP() must be a string"
      );
    const http = new XMLHttpRequest();
    if (/^https?:\/\//i.test(url) === false) {
      if (url[0] !== "/") url = `/${url}`;
      url = this.getOrigin() + url;
      if (url[url.length - 1] !== "/") url += "/";
    }
    const params = new FormData();
    params.append("data", JSON.stringify(data));
    http.onreadystatechange = (ev) => {
      if (http.readyState === 4) {
        if (http.status === 200) {
          const jsonRes = JSON.parse(http.responseText);
          if (typeof callback === "function") callback(jsonRes);
          else this.onData(jsonRes);
        } else
          console.error(
            `ERROR: Invalid HTTP response in sendHTTP() with code: ${http.status}`
          );
      }
    };
    http.open("POST", url, true);
    http.send(params);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected sendInternal(data: WebsocketDataReq | PrivateWebsocketDataReq) {
    // this.socket.send(ClientSocket.stringify(this.opcode, data));
    this.socket.send(JSON.stringify(data));
  }

  // eslint-disable-next-line class-methods-use-this
  protected getOrigin(): string {
    if (typeof document.location.origin === "string")
      return document.location.origin;
    if (typeof window.origin === "string") return window.origin;
    return (document as any).origin; // legacy
  }
}

/**
 * A WebSocket implementation for HTML5 browsers.
 * Don't extend the native WebSocket object: https://stackoverflow.com/questions/35282843/how-should-i-extend-the-websocket-type-in-typescript
 * @events connect, disconnect
 */
export class ClientSocket /* extends EventEmitter2 */ {
  protected className: string;

  protected socket: WebSocket | null = null;

  protected url: string;

  protected protocols?: string | string[];
  /*
    protected t: TranslationFunction = (key: string) => {
        console.warn("No translation function provided in %s", this.className);
        return key; // dummy
    };
    */

  // a map of all receivers listening for WebSocket data
  protected receivers = new Map<string, ClientSocketReceiver>(); // (client UUID, client)

  protected disconnectSent = false;

  constructor(url: string, protocols?: string | string[]) {
    // super()
    this.className = this.constructor.name;
    this.url = url;
    this.protocols = protocols;
  }

  public connect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.socket = new WebSocket(this.url, this.protocols);

      this.socket.onerror = (error) => {
        console.error("WebSocket error", error);
        // Hlp.showMsg(AppF.tr('connectionError'), 'danger');
        reject(error); // will not reject if already resolved/connected
      };
      this.socket.onopen = (event) => {
        // setTimeout(function() { // delay needed since new websocket module
        // }, HelpersClass.cfg.websocketConnectDelayMs);
        // this.socket.send(JSON.stringify({a: 1}));
        // that.startedInspector = new Date();
        // this.emit("connect");
        resolve();
      };
      this.socket.onmessage = (event) => {
        if (typeof event.data !== "string")
          return console.error(
            "Received WebSocket data with unexpected type",
            typeof event.data
          );
        this.parseWebsocketMessage(JSON.parse(event.data));
      };
      this.socket.onclose = () => {
        this.socket = null;
        this.emitDisconnect();
        // don't resolve anything
      };
    });
  }

  public getConnectionState(): number {
    return this.socket ? this.socket.readyState : -1;
  }

  protected parseWebsocketMessage(data: WebsocketDataRes) {
    // skip sending custom errors for simplicity
    // const message = AppJSONFormat === "JSON" ? JSON.parse(event.data) : EJSON.parse(event.data);
    /*
        const message = [WebSocketOpcode.TRADINGVIEW, data];

        if (message[0] === WebSocketOpcode.FATAL_ERROR) {
            this.emitDisconnect(message[1].err);
            return console.error("Received WebSocket fatal error message:", message[1]);
        }
        if (message[0] === WebSocketOpcode.CLOSE) {
            this.emitDisconnect(message[1].txt);
            return console.error("Received WebSocket close message:", message[1]);
        }
        if (message[0] === WebSocketOpcode.ERROR || message[0] === WebSocketOpcode.WARNING) {
            let type = message[0] === WebSocketOpcode.ERROR ? "error" : "warning";
            return console.error("Received WebSocket %s message:", type, message[1]);
        }
        if (message[0] === WebSocketOpcode.PING)
            return;

        let receiver = this.receivers.get(message[0]);
        if (receiver !== undefined)
            receiver.onData(message[1]);
        else
            console.error("Received WebSocket data without corresponding receiver. Opcode ", message[0]);
         */

    if (data.chanID === undefined) {
      console.error("missing required channel ID in WebSocket response:", data);
      return;
    }
    const receiver = this.receivers.get(data.chanID);
    if (receiver === undefined) {
      console.error("unknown channel ID in WebSocket response:", data.chanID);
      return;
    }

    // this code can also be used in a simpler version if we only have 1 Subscribe (1x candles, 1x ticker, ...) without chanID
    // we can check which property is defined: data.tradinViewRes !== undefined
    const channel = data.chan;
    switch (channel) {
      case "trading_view":
        receiver.onData(data.trading_view);
        break;
      case "market_trade":
        receiver.onData(data.market_trade);
        break;
      case "order_book":
        receiver.onData(data.order_book);
        break;
      case "ticker":
        receiver.onData(data.ticker);
        break;
      case "market":
        receiver.onData(data.market);
        break;
      case "best_offer":
        receiver.onData(data.best_offer);
        break;
      case "top_value":
        receiver.onData(data.top_value);
        break;
      case "top_volume":
        receiver.onData(data.top_volume);
        break;
      case "error":
        receiver.onError(data.error);
        break;
      default:
        assertUnreachableCode(channel);
    }
  }

  public static stringify(opcode: WebSocketOpcode, data: any) {
    /*
        if (AppJSONFormat === "JSON")
            return JSON.stringify([opcode, data])
        return EJSON.stringify([opcode, data])
         */
    return JSON.stringify([opcode, data]);
  }

  public subscribe(receiver: ClientSocketReceiver, persistent = false) {
    receiver.setPersistent(persistent);
    const subscriberID = receiver.getSubscriberID();
    if (this.receivers.has(subscriberID) === true) return;
    // this.send(ClientSocket.stringify(receiver.opcode, {action: "sub"}));
    this.receivers.set(subscriberID, receiver);
  }

  public unsubscribe(receiver: ClientSocketReceiver) {
    if (receiver.isPersistent() === true) return;
    // this.send(ClientSocket.stringify(receiver.opcode, {action: "unsub"}));
    this.receivers.delete(receiver.getSubscriberID());
  }

  public setAllowReSubscribe(receiver: ClientSocketReceiver) {
    receiver.setPersistent(false); // allow subscribing again to load the view
    this.receivers.delete(receiver.getSubscriberID());
  }

  public send(data: any): void {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN)
      return console.error("No WebSocket connection available to send data");
    this.socket.send(data);
  }

  public close() {
    const tryClose = () => {
      if (this.socket?.bufferedAmount === 0) this.socket?.close();
      else setTimeout(tryClose.bind(this), 1000);
    };
    tryClose();
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected emitDisconnect(reason?: string) {
    if (this.disconnectSent === true) return;
    // this.emit("disconnect", reason); // TODO needed? react has their own event system (redux)
    this.disconnectSent = true;
  }
}

export class PrivateClientSocket /* extends EventEmitter2 */ {
  protected className: string;

  protected socket: WebSocket | null = null;

  protected url: string;

  protected protocols?: string | string[];

  // a map of all receivers listening for WebSocket data
  protected receivers = new Map<string, ClientSocketReceiver>(); // (client UUID, client)

  protected disconnectSent = false;

  constructor(url: string, protocols?: string | string[]) {
    // super()
    this.className = this.constructor.name;
    this.url = url;
    this.protocols = protocols;
  }

  public connect(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.socket = new WebSocket(this.url, this.protocols);

      this.socket.onerror = (error) => {
        console.error("WebSocket error", error);
        // Hlp.showMsg(AppF.tr('connectionError'), 'danger');
        reject(error); // will not reject if already resolved/connected
      };
      this.socket.onopen = (event) => {
        resolve();
      };
      this.socket.onmessage = (event) => {
        if (typeof event.data !== "string")
          return console.error(
            "Received WebSocket data with unexpected type",
            typeof event.data
          );
        this.parseWebsocketMessage(JSON.parse(event.data));
      };
      this.socket.onclose = () => {
        this.socket = null;
        this.emitDisconnect();
        // don't resolve anything
      };
    });
  }

  public getConnectionState(): number {
    return this.socket ? this.socket.readyState : -1;
  }

  protected parseWebsocketMessage(data: PrivateWebsocketDataRes) {
    if (data.chanID === undefined) {
      console.error("missing required channel ID in WebSocket response:", data);
      return;
    }
    const receiver = this.receivers.get(data.chanID);
    if (receiver === undefined) {
      console.error("unknown channel ID in WebSocket response:", data.chanID);
      return;
    }

    // this code can also be used in a simpler version if we only have 1 Subscribe (1x candles, 1x ticker, ...) without chanID
    // we can check which property is defined: data.tradinViewRes !== undefined
    const channel = data.chan;
    switch (channel) {
      case "auth":
        receiver.onData(data.auth);
        break;
      case "my_order":
        receiver.onData(data.my_order);
        break;
      case "my_trade":
        receiver.onData(data.my_trade);
        break;
      case "wallet":
        receiver.onData(data.wallet);
        break;
      case "error":
        receiver.onError(data.error);
        break;
      default:
        assertUnreachableCode(channel);
    }
  }

  public static stringify(opcode: WebSocketOpcode, data: any) {
    /*
        if (AppJSONFormat === "JSON")
            return JSON.stringify([opcode, data])
        return EJSON.stringify([opcode, data])
         */
    return JSON.stringify([opcode, data]);
  }

  public subscribe(receiver: ClientSocketReceiver, persistent = false) {
    receiver.setPersistent(persistent);
    const subscriberID = receiver.getSubscriberID();
    if (this.receivers.has(subscriberID) === true) return;
    // this.send(ClientSocket.stringify(receiver.opcode, {action: "sub"}));
    this.receivers.set(subscriberID, receiver);
  }

  public unsubscribe(receiver: ClientSocketReceiver) {
    if (receiver.isPersistent() === true) return;
    // this.send(ClientSocket.stringify(receiver.opcode, {action: "unsub"}));
    this.receivers.delete(receiver.getSubscriberID());
  }

  public setAllowReSubscribe(receiver: ClientSocketReceiver) {
    receiver.setPersistent(false); // allow subscribing again to load the view
    this.receivers.delete(receiver.getSubscriberID());
  }

  public send(data: any): void {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN)
      return console.error("No WebSocket connection available to send data");
    this.socket.send(data);
  }

  public close() {
    const tryClose = () => {
      if (this.socket?.bufferedAmount === 0) this.socket?.close();
      else setTimeout(tryClose.bind(this), 1000);
    };
    tryClose();
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected emitDisconnect(reason?: string) {
    if (this.disconnectSent === true) return;
    // this.emit("disconnect", reason); // TODO needed? react has their own event system (redux)
    this.disconnectSent = true;
  }
}
