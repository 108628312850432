import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";

import { Card, TextTitle } from "components";
import { useOrder } from "providers";
import { TradesRes, WsTrade } from "models/WebsocketClients";
import { mediaQuery, priceNumber } from "utils";
import { APIError } from "models/generic";
import { config } from "config";

const { path } = config;

const CardView = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: ${(props) => props.theme.baseBackgroundColor};
`;

const Title = styled(TextTitle)`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 8px 0;
  color: ${(props) => props.theme.textColor};
  ${mediaQuery("mobile")} {
    padding: 8px 16px;
  }
`;

const Table = styled.table`
  border-spacing: 0px 1px;
  border-collapse: separate;
  width: 100%;
`;

const Tbody = styled.tbody``;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 60px minmax(400px, 60vh);
  gap: 12px 0px;
  grid-template-areas:
    "Order Order Order"
    "Table1 Table1 Table1";
`;

const Order = styled.div`
  grid-area: Order;
`;

const Table1 = styled.div`
  grid-area: Table1;
  overflow: auto;
`;

interface ItemProps {
  Side: string;
  Price: string;
  Amount: string;
  Time: string;
}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7eaf1;
`;

const Row = styled.div`
  padding: 4px 12px;
  /* display: flex;
  position: relative;
  align-items: center; */
  display: grid;
  grid-template-columns: 1fr 1fr 40%;
  border: 1px solid transparent;
  .Row-order {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
  }
  .Row-title {
    width: 100%;
    color: #9daac6;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
  ${mediaQuery("mobile")} {
    padding: 4px 18px;
  }
`;

const Item = ({ Side, Price, Amount, Time }: ItemProps) => (
  <Row>
    <div
      className="Row-order"
      style={{ color: Side === "sell" ? "#d02828" : "#1fa377" }}
    >
      {priceNumber(parseFloat(Price))}
    </div>
    <div className="Row-order" style={{ textAlign: "right" }}>
      {Amount}
    </div>
    <div className="Row-order" style={{ textAlign: "right" }}>
      {Time}
    </div>
  </Row>
);

const MarketTrade = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState<WsTrade[]>([]);
  const {
    state: { trades, isOpen },
  } = useOrder();

  // todo redo add limit to this somehow
  const handleData = (data: TradesRes) => {
    if (data.init && data.init.length > 0) {
      setValues(data.init);
    } else if (data.update && data.update.length > 0) {
      setValues((oldVal) => [...data.update, ...oldVal]);
    }
  };

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in market trade: ", error);
    },
    [navigate]
  );

  useEffect(() => {
    if (trades) {
      setValues([]);
      trades.subscribe();
      trades.onData = handleData;
      trades.onError = handleError;
    }
    return () => {
      if (trades) {
        trades.unsubscribe();
      }
    };
  }, [handleError, trades]);

  const memoedData = useMemo(() => {
    // filter only today data
    const filteredVal = values.filter((value) =>
      dayjs(value.when).isSame(dayjs(), "day")
    );
    // limit 30
    const limitedData = filteredVal.slice(0, 30);
    return limitedData;
  }, [values]);

  return (
    <CardView>
      <Container>
        <Order>
          <Title>Public Market Trade</Title>
          <Row>
            <div className="Row-title">Price(THB)</div>
            <div className="Row-title" style={{ textAlign: "right" }}>
              Amount
            </div>
            <div className="Row-title" style={{ textAlign: "right" }}>
              Time
            </div>
          </Row>
          {/* <Line /> */}
        </Order>
        <Table1>
          <Table>
            <Tbody id="market-trade-table" className="market-trade-table">
              {memoedData.map((value) => (
                <Item
                  key={`${value.price}-${value.when}`}
                  Time={dayjs(value.when).format("HH:mm:ss")}
                  Side={value.side}
                  Price={value.price}
                  Amount={value.amount}
                />
              ))}
            </Tbody>
          </Table>
        </Table1>
      </Container>
    </CardView>
  );
};

export default MarketTrade;
