import { useCallback, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import BG from "assets/CarbonCredit-SVG/Gideon-BG-Logo.svg";
import { config } from "config";
import { Card } from "components";
import { ResetPass } from "features/Login";
import { mediaQuery } from "utils/mediaQueries";
import { APIError } from "models/generic";
import { apiErrorToast, captureErrorSentry } from "utils";
import { verifyResetPasswordToken } from "api/users";

const { path } = config;

const Container = styled.div`
  background-color: ${(props) => props.theme.baseBackgroundColor};
  width: 100vw;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${mediaQuery("desktop")} {
    height: 100vh;
  }
`;

const CardView = styled(Card)`
  margin: auto;
  width: 100%;
  max-width: 540px;
  padding: 40px;
  background: transparent;
  box-shadow: none;
  ${mediaQuery("mobile")} {
    padding: 80px 100px;
    background: ${(props) => props.theme.componentBackgroundColor};
    box-shadow: ${(props) => props.theme.boxShadowLogin};
  }
`;

type Params = {
  userData: string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userData } = useParams<Params>();

  const token = userData;

  const verifyToken = useCallback(
    async (token?: string) => {
      try {
        if (!token) {
          throw new Error("Token is empty");
        }
        await verifyResetPasswordToken({
          token,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
          captureErrorSentry(error, err, {
            message: "Verify reset password token error",
          });
        }
        navigate(path.login, { replace: true });
      }
    },
    [navigate]
  );

  useEffect(() => {
    verifyToken(token);
  }, [token, verifyToken]);

  return (
    <Container>
      <CardView>
        <ResetPass token={userData || ""} />
      </CardView>
    </Container>
  );
};

export default ResetPassword;
