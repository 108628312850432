import styled from "@emotion/styled/macro";
import { motion } from "framer-motion";

export const Card = styled(motion.div)`
  padding: 24px;
  background: ${(props) => props.theme.cardBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadiusBase};
  position: relative;
  box-shadow: ${(props) => props.theme.boxShadowBase};
`;
