import React, { CSSProperties, useMemo, useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import numeral from "numeral";
import { client } from "api/client";
import useSWR from "swr";
import axios from "axios";

import { backendURL } from "config";
import {
  Button,
  Badge,
  OutlineSelect,
  Table,
  Tab,
  TabsBar,
  Card,
  DatePicker,
  Key,
} from "components";
import { ReactComponent as Vector } from "assets/CarbonCredit-SVG/Vector.svg";
import { useDebounceValue, usePagination, useSelectedDate } from "hooks";
import {
  TransactionReqRes,
  Transaction,
  TransactionById,
} from "models/transactionRequest";
import { convertObjToUrl, apiErrorToast, captureErrorSentry } from "utils";
import { APIError } from "models/generic";
import {
  EntryType,
  TransactionStatus,
  transactionStatusVariant,
} from "models/transaction";
import * as errorCodes from "models/apiErrorCodes";
import { MoneyTransactionModal } from "features/Admin";
import { MoneyDepositModal } from "features/Admin/TransactionRequest/DepositModal/DepositModal";

const Container = styled(Card)`
  .modal div {
    backdrop-filter: initial;
  }
  margin-bottom: 32px;
`;
const TabHeader = styled.h2`
  color: inherit;
  line-height: 30px;
  padding: 0 16px;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const IconButton = styled.button`
  width: fit-content;
  margin: auto;
  background: transparent;
  border: none;
`;
const DepositButton = styled(Button)`
  width: 120px;
  height: 40px;
  position: absolute;
  top: 28px;
  right: 32px;
  z-index: ${(props) => props.theme.defaultZIndex};
`;

const labelCss: CSSProperties = {
  width: "240px",
};

const keys: Key<Transaction>[] = [
  {
    label: "Created Date",
    name: "created_at",
    customRenderer: (data) => <>{dayjs(data).format("DD MMM YYYY HH:mm")}</>,
  },
  {
    label: "Account ID",
    name: "user_id",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Transaction Id",
    name: "transaction_id",
  },
  {
    label: "Entry Type",
    name: "entry_type",
  },
  {
    label: "Amount (THB)",
    name: "amount",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => {
      const amount = parseFloat(data as string);
      return <>{numeral(amount).format("0,0.00")}</>;
    },
  },
  {
    label: "Status",
    name: "status",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => (
      <Badge variant={transactionStatusVariant(data as TransactionStatus)}>
        {data.toString().replace("_", " ").toLowerCase()}
      </Badge>
    ),
  },
];

const initialFilterData = {
  currency_type: "fiat",
  entry_type: "",
  status: "",
};

const initBankData = {
  status: TransactionStatus.Pending,
  user_id: 0,
  transaction_id: 0,
  entry_type: "",
  email: "",
  created_at: "",
  updated_at: "",
  updated_by: "",
  payment_method_code: "",
  payment_method_name: "",
  account_number: "",
  account_name: "",
  amount: "",
  proof_of_payment_file_url: "",
};

const debounceTime = 300;

export const TransactionRequest: React.FC = () => {
  const [filterData, setFilterData] = useState(initialFilterData);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [inventoryTotal, setInventoryTotal] = useState(0);
  const {
    pagination,
    setPage,
    setLimit,
    showPerPageOptions,
    totalPages,
    reset,
  } = usePagination(inventoryTotal);
  const { selectedDate, selectingDate, onChangeDate, resetDate } =
    useSelectedDate();

  const [modalRowData, setModalRowData] = useState<Transaction | null>(null);
  const [bankData, setBankData] = useState<TransactionById>(initBankData);

  const queryParams = useMemo(
    () => ({
      ...pagination,
      ...filterData,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
    }),
    [filterData, pagination, selectedDate]
  );

  const debouncedParam = useDebounceValue(queryParams, debounceTime);

  const debouncedSelectedTab = useDebounceValue(selectedTab, debounceTime);

  const { data: transactionRequestData, mutate } = useSWR<TransactionReqRes>(
    debouncedSelectedTab === "Pending"
      ? `/transaction-request/pending?${convertObjToUrl(debouncedParam)}`
      : null,
    {
      onSuccess: (res) => {
        setInventoryTotal(res.data.total);
      },
    }
  );

  const { data: transactionRequestHistoryData } = useSWR<TransactionReqRes>(
    debouncedSelectedTab === "Pending"
      ? null
      : `/transaction-request/history?${convertObjToUrl(debouncedParam)}`,
    {
      onSuccess: (res) => {
        setInventoryTotal(res.data.total);
      },
    }
  );

  const handleCloseModal = () => {
    setModalRowData(null);
    setBankData(initBankData);
    mutate();
  };

  const onCloseDepositModal = () => {
    setOpenDeposit(false);
    mutate();
  };

  const transactionTableData: Transaction[] = useMemo(() => {
    if (!transactionRequestData) return [];
    return transactionRequestData?.data.requests;
  }, [transactionRequestData]);

  const transactionHistoryTableData = useMemo(() => {
    if (!transactionRequestHistoryData) return [];
    return transactionRequestHistoryData?.data.requests;
  }, [transactionRequestHistoryData]);

  const tabList = [
    {
      name: "Pending",
      data: transactionTableData,
      total: transactionRequestData?.data.total,
    },
    {
      name: "History",
      data: transactionHistoryTableData,
      total: transactionRequestHistoryData?.data.total,
    },
  ];

  const handleModalClickAndQuery = async (rowData: Transaction) => {
    setModalRowData(rowData);
    client
      .get(`${backendURL}/transaction-request/${rowData.transaction_id}`)
      .then((data) => {
        setBankData(data.data.data);
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
          if (error.code !== errorCodes.InputValidationError) {
            captureErrorSentry(error, err, {
              message: "transaction request by id error",
            });
          }
        }
      });
  };

  const onReset = () => {
    setFilterData(initialFilterData);
    resetDate();
    reset();
  };

  const tableFilter = () => (
    <>
      <DatePicker
        label="Date"
        style={{ width: "240px", minWidth: "200px" }}
        startDate={selectingDate[0]}
        endDate={selectingDate[1]}
        onChange={(_, update) => onChangeDate(update as [Date, Date])}
        selectsRange
        maxDate={new Date()}
      />
      <SelectorContainer>
        <OutlineSelect
          onChange={(e) =>
            setFilterData({ ...filterData, entry_type: e.target.value })
          }
          value={filterData.entry_type}
          style={labelCss}
          label="Entry Type"
        >
          <option value="">All Entry Type</option>
          <option value={EntryType.Deposit}>Deposit</option>
          <option value={EntryType.Withdraw}>Withdraw</option>
        </OutlineSelect>
      </SelectorContainer>
      <SelectorContainer>
        <OutlineSelect
          onChange={(e) =>
            setFilterData({ ...filterData, status: e.target.value })
          }
          value={filterData.status}
          style={labelCss}
          label="Status"
        >
          <option value="">All Status</option>
          {selectedTab === "Pending" ? (
            <>
              <option value={TransactionStatus.Pending}>Pending</option>
              <option value={TransactionStatus.InProcess}>In Process</option>
            </>
          ) : (
            <>
              <option value={TransactionStatus.Success}>Success</option>
              <option value={TransactionStatus.Rejected}>Rejected</option>
              <option value={TransactionStatus.Cancelled}>Cancelled</option>
            </>
          )}
        </OutlineSelect>
      </SelectorContainer>
      <Button variant="link" onClick={onReset}>
        Reset
      </Button>
    </>
  );

  const tableActions = (rowData: Transaction) => {
    // render when status is Pending or InProcess Only
    const renderAction =
      rowData.status === TransactionStatus.Pending ||
      rowData.status === TransactionStatus.InProcess;
    if (renderAction)
      return (
        <IconButton onClick={() => handleModalClickAndQuery(rowData)}>
          <Vector />
        </IconButton>
      );
  };

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <MoneyDepositModal
        show={openDeposit}
        mutate={mutate}
        onClose={onCloseDepositModal}
      />
      <MoneyTransactionModal
        show={!!modalRowData}
        closable
        onCloseClick={() => setModalRowData(null)}
        onBackgroundClick={() => setModalRowData(null)}
        data={modalRowData}
        bankData={bankData}
        handleCloseModalApprove={handleCloseModal}
      />
      <DepositButton size="small" onClick={() => setOpenDeposit(true)}>
        + Deposit
      </DepositButton>
      <TabsBar
        loading={false}
        mode="horizontal"
        onSelect={(key) => {
          setFilterData(initialFilterData);
          setSelectedTab(key);
          reset();
        }}
      >
        {tabList.map(({ name, data }) => (
          <Tab label={<TabHeader>{name}</TabHeader>} key={name}>
            <Table
              tbodyTestId={`money-${name.toLowerCase()}__table`}
              actions={tableActions}
              noDataMessage="No data"
              data={data}
              keys={keys}
              rowPrimaryKey="transaction_id"
              currentPage={pagination.page}
              show={pagination.limit}
              totalPages={totalPages}
              onShowChange={setLimit}
              onPageChange={setPage}
              filters={tableFilter()}
              showPerPageOptions={showPerPageOptions}
              loading={
                name === "Pending"
                  ? !transactionRequestData
                  : !transactionRequestHistoryData
              }
            />
          </Tab>
        ))}
      </TabsBar>
    </Container>
  );
};
