import styled from "@emotion/styled";
import { Card, Status, StatusVariant } from "components";
import { MarketItem } from "models/WebsocketClients";
import { percentNumber, priceNumber } from "utils";
import { ReactComponent as LineChartUp } from "assets/CarbonCredit-SVG/LineChartUp.svg";

const getPriceSide = (change: number) => {
  if (change > 0) return "success";
  if (change < 0) return "danger";
  return "";
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  padding: 1.25rem;
  width: max-content;
`;

const TitleIcon = styled.span`
  margin-right: 0.5rem;
`;

const Title = styled.h2`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const CardContainer = styled.div``;

const RowData = styled.div`
  display: grid;
  grid-template-columns: 200px 100px 80px;
  border-bottom: 1px solid #ffffff1a;
  padding: 8px 0;
`;

const SymbolText = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;
`;

type OverviewItem = {
  pair_symbol: string;
  last_price: number;
  percent_change: number;
};

interface MarketOverviewCardProps {
  title: string;
  data: OverviewItem[];
}

const MarketOverviewCard = ({ title, data }: MarketOverviewCardProps) => (
  <Container>
    <Title>
      <TitleIcon>
        <LineChartUp />
      </TitleIcon>
      {title}
    </Title>
    <CardContainer>
      {data?.map((item) => (
        <RowData key={item.pair_symbol}>
          <SymbolText>{item.pair_symbol}</SymbolText>
          <Status
            variant={getPriceSide(item?.last_price || 0) as StatusVariant}
          >
            {priceNumber(item.last_price as number, {
              currency: "THB",
            })}
          </Status>
          <Status
            variant={
              getPriceSide(+(item?.percent_change || 0)) as StatusVariant
            }
          >
            {percentNumber((item.percent_change || 0) as number, {
              signDisplay: "exceptZero",
              min: 2,
              max: 2,
            })}
          </Status>
        </RowData>
      ))}
    </CardContainer>
  </Container>
);

export default MarketOverviewCard;
