import { ChangeEvent } from "react";
import styled from "@emotion/styled/macro";
import { IRadio, Radio } from "components";

export const RegisterContainer = styled.div`
  max-width: ${(props) =>
    `calc(${props.theme.loginMaxWidth} + 40px)`}; //540 + 40 padding: ;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
`;

export const Form = styled.form``;

export const Flex = styled.div``;

export const RegisterComponentGrid = styled.div`
  display: grid;
  grid-template-rows: max-content minmax(200px, 1fr) max-content; //Header Form SubmitButton
  max-height: 80vh;
  min-height: 450px;
  > ${Form}, ${Flex} {
    overflow-y: auto;
    padding: 32px 0;
  }
`;

export const TextDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Title = styled.div`
  width: max-content;
  height: auto;
  margin: 0 auto;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
`;

export const SmallTitle = styled(Title)`
  margin: 32px auto 16px;
  font-size: 0.875rem;
  font-weight: 700;
`;

export const Desc = styled.div`
  text-align: center;
`;

export const Icon = styled.img`
  position: absolute;
  cursor: pointer;
  top: 17px;
  right: 16px;
  width: 18px;
  height: 13px;
  margin: 0px auto;
  object-fit: contain;
`;

export const ErrorText = styled.p`
  color: #d02828;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const ColoredRadio = (props: IRadio) => (
  <Radio
    checkmarkColor="#02ACD8"
    borderColor="#9DAAC6"
    borderColorChecked="#02ACD8"
    backgroundColorChecked="#ffffff"
    {...props}
  />
);

export const NormalRadio = styled(ColoredRadio)`
  justify-content: flex-start;
  label {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
  }
  margin-bottom: 16px;
`;
const digitRegex = /^[0-9]*$/;

export const digitsOnlyChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange: (val: any) => void
) => {
  const { value } = e.target;
  return digitRegex.test(value) ? onChange(e.target.value) : null;
};
