import { HTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

import { mediaQuery } from "utils/mediaQueries";
import { config } from "config";

const { path } = config;

const Sidebar = styled.div`
  width: 100%;
  min-width: unset;
  padding: 40px;
  background-color: #252525;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${(props) => props.theme.sidebarZIndex};
  ${mediaQuery("mobile")} {
    flex-direction: row;
  }
  ${mediaQuery("desktop")} {
    width: 390px;
    min-width: 390px;
    height: 100vh;
    padding: 56px 60px;
    flex-direction: column;
  }
`;

const AppLogo = styled.img`
  width: 120px;
  margin: 16px;

  ${mediaQuery("desktop")} {
    width: 150px;
    margin: 16px 0;
    object-fit: contain;
  }
`;

const Descriptions = styled(Link)`
  color: ${(props) => props.theme.textColorLight};
  font-family: ${(props) => `Kanit,${props.theme.fontFamily}`};
  margin: 0;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.textColorLight};
  }
  ${mediaQuery("mobile")} {
    margin-right: 40px;
  }
  ${mediaQuery("desktop")} {
    margin: 0;
  }
`;

const Heading = styled.div`
  font-size: 2.25rem;
  font-weight: 200;
  color: ${(props) => props.theme.textColorLight};
`;

const LogoGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const Logo = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 140px;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SideBar = ({ ...rest }: Props) => (
  <Sidebar {...rest}>
    <Descriptions to={path.default}>
      <AppLogo src="/CCXT.svg" />
      <Heading>
        <span>Carbon Credit</span> <span>Exchange of</span>{" "}
        <span>Thailand</span>
      </Heading>
    </Descriptions>
    <LogoGroup>
      <Logo src="/EMI.svg" alt="" style={{ padding: 20 }} />
      <Logo src="/TCEB.svg" alt="" style={{ padding: 14, paddingTop: 20 }} />
      <Logo src="/UTCC.svg" alt="" style={{ padding: 12 }} />
      <Logo src="/CentralGroup.svg" alt="" />
    </LogoGroup>
  </Sidebar>
);

export default SideBar;
